import React from "react";

type TableColumn<T> = {
  id: string | number;
  displayName: string | JSX.Element;
  accessor: (data: T) => string | JSX.Element;
};

type TableProps<T> = {
  columns: TableColumn<T>[];
  data: T[];
};

export const ColumnHeader: React.FC<{ title: string }> = (props) => (
  <th
    scope="col"
    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  >
    {props.title}
  </th>
);

const Table = <T extends { id: string }>(props: TableProps<T>) => {
  const { columns, data } = props;
  return (
    <div className="flex flex-col mt-8">
      <div className="-my-2 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="rounded-lg border border-gray-200 overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  {columns.map((col) =>
                    typeof col.displayName === "string" ? (
                      <ColumnHeader key={col.id} title={col.displayName} />
                    ) : (
                      col.displayName
                    )
                  )}
                </tr>
              </thead>
              <tbody className="bg-white">
                {data.map((item, idx) => (
                  <tr
                    key={item.id}
                    className={idx % 2 === 0 ? "bg-white" : "bg-gray-50"}
                  >
                    {columns.map((col) => {
                      const el = col.accessor(item);
                      return (
                        <td
                          key={col.id}
                          className="px-6 py-4 whitespace-nowrap"
                        >
                          {typeof el === "string" ? (
                            <div className="text-sm text-gray-900">{el}</div>
                          ) : (
                            el
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
