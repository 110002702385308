import { gql } from "@apollo/client";
import { client } from "../apollo";

import { CANDIDATE_FRAGMENT } from "fragments/candidate";
import { FRAGMENT_DECORATION } from "fragments/html";

import {
  ParseDomItem,
  ParseDomItemVariables,
  ParseDomItem_parseDomItem,
} from "../__generated__/ParseDomItem";

export type ParseDomItemResponse = ParseDomItem_parseDomItem;

export const PARSE_DOM_ITEM = gql`
  ${CANDIDATE_FRAGMENT}
  ${FRAGMENT_DECORATION}
  mutation ParseDomItem($input: ParseDomItemInput!) {
    parseDomItem(input: $input) {
      candidate {
        ...CandidateFragment
      }
      decorations {
        ...DecorationFragment
      }
    }
  }
`;

export const parseDomItem = (variables: ParseDomItemVariables) => {
  return client.mutate<ParseDomItem>({
    mutation: PARSE_DOM_ITEM,
    variables,
    fetchPolicy: "network-only",
  });
};
