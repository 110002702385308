import { EventName } from "solostar-graphql";
import { Event } from "../types/events";

export const create = (message: Event) => JSON.stringify(message);

export const emit = (message: Event, parent?: "top" | MessageEventSource) => {
  const data = create(message);
  const emitter = parent === "top" ? window.top : parent ?? window;
  emitter?.postMessage(data, { targetOrigin: "*" });
};

export const parse = <Name extends EventName>(
  data: string
): Extract<Event, { name: Name }> | null => {
  try {
    const parsed = JSON.parse(data);
    if (parsed?.name && Object.values(EventName).includes(parsed.name)) {
      return parsed;
    }
    return null;
  } catch {
    return null;
  }
};
