import React from "react";
import SidebarTimelineItem from "./SidebarTimelineItem";
import CandidateHeader from "./SidebarCandidateHeader";
import { Candidate } from "solostar-graphql";

type Props = {
  candidate: Candidate;
  onClickItem: (domId?: string | null) => void;
  activeItemId?: string;
};

export const SIDEBAR_TIMELINE_ID = "ssTimeline";

const CandidateTimeline: React.FC<Props> = (props) => {
  const { candidate, onClickItem, activeItemId } = props;

  return (
    <React.Fragment>
      <CandidateHeader candidate={candidate} />
      <div
        id={SIDEBAR_TIMELINE_ID}
        className="pr-1 bg-gray-50 flex-auto overflow-scroll"
      >
        {candidate.positions.map((p) => (
          <SidebarTimelineItem
            key={p.domId}
            candidateDomId={candidate.domId}
            candidateExternalId={candidate.externalId}
            position={p}
            type="Company"
            isActive={activeItemId === p.domId}
            isInactive={activeItemId !== undefined && activeItemId !== p.domId}
            onClick={() => onClickItem(p.domId)}
          />
        ))}
        {candidate.education.map((p) => (
          <SidebarTimelineItem
            key={p.domId}
            candidateDomId={candidate.domId}
            candidateExternalId={candidate.externalId}
            position={p}
            type="Institution"
            isActive={activeItemId === p.domId}
            isInactive={activeItemId !== undefined && activeItemId !== p.domId}
            onClick={() => onClickItem(p.domId)}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default CandidateTimeline;
