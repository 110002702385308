import { TagTarget, Scope } from "solostar-graphql";

export const SCOPE_PRIORITY = [Scope.USER, Scope.TENANT, Scope.APP];

export const DEFAULT_TAGS = {
  [`${TagTarget.COMPANY}:ELITE`]: { displayName: "Elite" },
  [`${TagTarget.COMPANY}:TOP_TIER`]: { displayName: "Top Tier" },
  [`${TagTarget.COMPANY}:MIDDLE_TIER`]: { displayName: "Middle Tier" },
  [`${TagTarget.INSTITUTION}:ELITE`]: { displayName: "Elite" },
  [`${TagTarget.INSTITUTION}:TOP_TIER`]: { displayName: "Top Tier" },
  [`${TagTarget.INSTITUTION}:MIDDLE_TIER`]: { displayName: "Middle Tier" },
};

export const DEFAULT_TAGS_ARRAY = Object.keys(DEFAULT_TAGS);

export const URM_TAGS: string[] = ["HBCU", "URM", "WOMENS_COLLEGE"];

export const KEYBOARD_EVENTS = {
  DOWN: {
    id: "keydown",
    bubbles: true,
    cancelable: true,
    keyCode: 40,
    key: "ArrowDown",
  },
  ENTER: {
    id: "keydown",
    bubbles: true,
    cancelable: true,
    keyCode: 30,
    key: "Enter",
  },
};

export const DEFAULT_SS_DECORATION_CLASS_NAME = "ss__decoration";
export const DEFAULT_SS_LOADING_CLASS_NAME = "ss__loading";
