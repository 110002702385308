import { gql, useQuery } from "@apollo/client";
import { GetPlans } from "../__generated__/GetPlans";

export const GET_PLANS = gql`
  query GetPlans {
    plans {
      id
      displayName
      price
      priceId
      description
      features
    }
  }
`;

export const usePlans = () => {
  const res = useQuery<GetPlans>(GET_PLANS);
  return { ...res, data: res.data?.plans };
};
