import Loader from "components/Loader";
import React from "react";
import { useParams } from "react-router-dom";
import { useCompany } from "solostar-graphql";
import { isExtensionOrPopup } from "utils/env";
import SidebarHeader from "../../Sidebar/SidebarHeader";
import { useNavigationHook } from "utils/navigationHook";
import { getSearchId, useFakeLoading } from "pages/Sidebar/CompanyCompetitors";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { format } from "date-fns";
import GoBackButton, { EmptyStateGoBack } from "components/AIGoBackHeader";

export const Layoffs: React.FC<{
  companyId?: string;
}> = (props) => {
  const { companyId } = props;
  useNavigationHook();
  const fakeLoading = useFakeLoading();

  const id = getSearchId(companyId);

  const { data, loading } = useCompany(
    { id: id?.id ?? "" },
    { skip: !id || id.type !== "lookup" }
  );

  const layoffs = data?.layoffs;

  if (loading || fakeLoading) {
    return (
      <div>
        {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
        <div className="w-screen h-screen flex flex-col items-center justify-center">
          <Loader />
          <div className="mb-2 mt-8 animate-pulse flex items-center">
            <img
              className="w-8 mr-1 object-contain animate-pulse"
              src="/logo192.png"
              alt="SoloStar Logo"
            />
            <span className="text-xl font-medium">SoloStar</span>
          </div>
          <p className="p-8 text-center">
            SoloStar AI is looking up recent layoffs done by this company. 🤔
          </p>
        </div>
      </div>
    );
  }

  if (!layoffs?.length) {
    return (
      <EmptyStateGoBack
        id={companyId}
        title="No Layoffs Found"
        description="Sorry, we couldn't find any layoffs by this company."
      />
    );
  }

  return (
    <div className="">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}

      <div className="p-8">
        <GoBackButton id={companyId} />

        {data?.url && (
          <img
            alt="clearbit logo"
            src={`https://logo.clearbit.com/${data.url}`}
            className="w-24 h-24 bg-gray-100 mb-4 border border-gray-300 object-contain"
          />
        )}
        {data?.url && (
          <a
            href={data.url}
            className="text-sm underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h1 className="text-xl mb-4">{data?.displayName}</h1>
          </a>
        )}

        <ul className="mt-12">
          {layoffs.map((a, idx) => (
            <li key={a.id}>
              <div className="relative pb-8">
                {idx !== layoffs.length - 1 ? (
                  <span
                    className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-purple-600"
                      )}
                    >
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p className="text-sm text-gray-500">
                        {a.amount
                          ? `${a.amount.toLocaleString()} Employees`
                          : "Employees"}{" "}
                        laid off.{" "}
                        {!!a.source && (
                          <a
                            href={a.source}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="font-medium underline"
                          >
                            Learn more.
                          </a>
                        )}
                      </p>
                    </div>
                    {a.createdAt && (
                      <div className="whitespace-nowrap text-right text-sm text-gray-500">
                        <time dateTime={a.createdAt}>
                          {format(new Date(a.createdAt), "MMM d, yyyy")}
                        </time>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const CompanyLayoffs: React.FC = () => {
  const { companyId } = useParams<{ companyId?: string }>();
  return <Layoffs companyId={companyId} />;
};

export default CompanyLayoffs;
