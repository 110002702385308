import { gql, useMutation } from "@apollo/client";
import { DeleteTag, DeleteTagVariables } from "../__generated__/DeleteTag";

export const DELETE_TAG = gql`
  mutation DeleteTag($input: DeleteTagInput!) {
    deleteTag(input: $input)
  }
`;

export const useDeleteTag = (variables?: DeleteTagVariables) =>
  useMutation<DeleteTag, DeleteTagVariables>(DELETE_TAG, {
    variables,
    refetchQueries: ["GetTags"],
    awaitRefetchQueries: true,
  });
