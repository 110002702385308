import React from "react";
import Login from "pages/Login";

import { useMe } from "solostar-graphql";

const Profile: React.FC = () => {
  const { data, error, loading } = useMe();

  /** If user is not logged in, show the Login component. */
  if (!loading && error) {
    return (
      <div>
        <Login />
      </div>
    );
  }

  if (!data) {
    /** TO DO: Replace with a <Loader /> */
    return null;
  }

  return (
    <div className="">
      <div className="flex items-center space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <img
              className="h-16 w-16 rounded-full"
              src={data.photoUrl || undefined}
              alt={data.email || undefined}
            />
            <span
              className="absolute inset-0 shadow-inner rounded-full"
              aria-hidden="true"
            />
          </div>
        </div>
        <div>
          <h1 className="text-xl font-bold text-gray-900">
            {data.displayName}
          </h1>
          <p className="text-sm font-medium text-gray-500">
            <span className="text-gray-900">{data.email}</span>, member of the{" "}
            <span className="text-gray-900">{data.tenant?.displayName}</span>{" "}
            team.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
