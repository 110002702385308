import Loader from "components/Loader";
import React from "react";
import { useParams } from "react-router-dom";
import { isExtensionOrPopup } from "utils/env";
import { useNavigationHook } from "utils/navigationHook";
import GoBackButton, { EmptyStateGoBack } from "components/AIGoBackHeader";
import SidebarHeader from "pages/Sidebar/SidebarHeader";
import { useFakeLoading } from "pages/Sidebar/CompanyCompetitors";
import { useInstitutionWithSummary } from "./InstitutionSummary";

export const TopPrograms: React.FC<{
  id?: string;
}> = (props) => {
  const { id } = props;

  useNavigationHook();

  const fakeLoading = useFakeLoading();

  const { institution, loading } = useInstitutionWithSummary(id);

  if (loading || fakeLoading) {
    return (
      <div>
        {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
        <div className="w-screen h-screen flex flex-col items-center justify-center">
          <Loader />
          <div className="mb-2 mt-8 animate-pulse flex items-center">
            <img
              className="w-8 mr-1 object-contain animate-pulse"
              src="/logo192.png"
              alt="SoloStar Logo"
            />
            <span className="text-xl font-medium">SoloStar</span>
          </div>
          <p className="p-8 text-center">
            SoloStar AI is thinking 🤔...we're figuring out the top study
            programs at <b>{institution?.displayName}</b>.
          </p>
        </div>
      </div>
    );
  }

  if (!institution.summary?.programs) {
    return (
      <EmptyStateGoBack
        id={id}
        type="institutions"
        title="Top Programs Not Found"
        description="Sorry, we couldn't find the top programs for this institution."
      />
    );
  }

  return (
    <div className="">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
      <div className="p-8">
        <GoBackButton type="institutions" id={id} />

        <p className="">
          Top study programs at <b>{institution.displayName}</b> include:
        </p>
        <ul className="flex flex-col gap-4 my-8">
          {institution.summary.programs.map((c, idx) => (
            <li key={c.title} className="">
              <h3 className="font-bold">
                {idx + 1}. {c.title}
              </h3>
              <p className="text-gray-600">{c.whyImportant}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const InstitutionTopPrograms: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  return <TopPrograms id={id} />;
};

export default InstitutionTopPrograms;
