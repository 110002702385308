import { ApolloClient, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
export { ApolloProvider } from "@apollo/client";

const cache = new InMemoryCache({
  typePolicies: {
    Tag: {
      /** For tags, IDs are not unique, but a combination of `id` + `type` + `scope` are. */
      keyFields: ["id", "scope"],
    },
    SearchGroup: {
      /** For tags, IDs are not unique, but a combination of `id` + `type` + `scope` are. */
      keyFields: ["id", "scope"],
    },
  },
});

const httpLink = new BatchHttpLink({
  credentials: "include",
  uri: process.env.API_URL,
  batchMax: 10,
  batchInterval: 20,
});

export const client = new ApolloClient({
  link: httpLink,
  cache,
});
