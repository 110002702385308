import { gql, useQuery, QueryHookOptions, QueryOptions } from "@apollo/client";
import { client } from "../apollo";

import {
  GetCompanySummary,
  GetCompanySummaryVariables,
} from "../__generated__/GetCompanySummary";

import {
  GetCompanyArticles,
  GetCompanyArticlesVariables,
} from "../__generated__/GetCompanyArticles";

export const GET_COMPANY_SUMMARY = gql`
  query GetCompanySummary($query: String!) {
    getCompanySummary(query: $query) {
      summary
      why
      website
    }
  }
`;

export const GET_COMPANY_ARTICLES = gql`
  query GetCompanyArticles($id: String!) {
    getCompanyArticles(id: $id) {
      title
      image
      source
      href
    }
  }
`;

export const useCompanySummary = (
  variables: GetCompanySummaryVariables,
  options: QueryHookOptions<GetCompanySummary, GetCompanySummaryVariables>
) => {
  const res = useQuery<GetCompanySummary, GetCompanySummaryVariables>(
    GET_COMPANY_SUMMARY,
    { variables, ...options }
  );
  return { ...res, data: res.data?.getCompanySummary };
};

export const getCompanySummary = (
  variables: GetCompanySummaryVariables,
  options?: Partial<QueryOptions<GetCompanySummary, GetCompanySummaryVariables>>
) => {
  return client.query<GetCompanySummary, GetCompanySummaryVariables>({
    ...options,
    query: GET_COMPANY_SUMMARY,
    variables,
  });
};

export const useCompanyArticles = (
  variables: GetCompanyArticlesVariables,
  options: QueryHookOptions<GetCompanyArticles, GetCompanyArticlesVariables>
) => {
  const res = useQuery<GetCompanyArticles, GetCompanyArticlesVariables>(
    GET_COMPANY_ARTICLES,
    { variables, ...options }
  );
  return { ...res, data: res.data?.getCompanyArticles };
};
