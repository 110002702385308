import { auth } from "lib/firebase";
import { loginWithIdTokenApollo } from "solostar-graphql";

import {
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";

export const login = async (email?: string) => {
  const provider = new GoogleAuthProvider();
  if (email) {
    provider.setCustomParameters({
      login_hint: email,
    });
  }
  const result = await signInWithPopup(auth, provider);
  const idToken = await result.user.getIdToken();
  const res = await loginWithIdTokenApollo({
    idToken,
    csrfToken: "",
  });
  return res?.data || null;
};

export const loginWithMicrosoft = async (email?: string) => {
  const provider = new OAuthProvider("microsoft.com");
  if (email) {
    provider.setCustomParameters({ login_hint: email });
  }
  const result = await signInWithPopup(auth, provider);
  const idToken = await result.user.getIdToken();
  const res = await loginWithIdTokenApollo({
    idToken,
    csrfToken: "",
  });
  return res?.data || null;
};
