import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import SidesheetFormItem from "./SidesheetFormItem";

type SidesheetProps = {
  title: string;
  buttons: JSX.Element[];
  description?: JSX.Element | string;
  isOpen?: boolean;
  onRequestClose?: () => void;
};

const Sidesheet: React.FC<SidesheetProps> & {
  FormItem: typeof SidesheetFormItem;
} = (props) => {
  const {
    title,
    description,
    isOpen: openControlled,
    onRequestClose,
    buttons,
  } = props;
  const [openUncontrolled, setOpenUncontrolled] = useState(true);

  const isControlled = openControlled !== undefined && onRequestClose;
  const open = isControlled ? openControlled : openUncontrolled;
  const onClose = isControlled
    ? onRequestClose
    : () => setOpenUncontrolled(false);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-hidden"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <form className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="px-4 py-6 bg-gray-50 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            {title}
                          </Dialog.Title>
                          {description && (
                            <p className="text-sm text-gray-500">
                              {description}
                            </p>
                          )}
                        </div>
                        <div className="h-7 flex items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {props.children}
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                    <div className="space-x-3 flex justify-end">{buttons}</div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Sidesheet.FormItem = SidesheetFormItem;

export default Sidesheet;
