import { events } from "solostar-lib";
import { EventName, AnalyticsEvent } from "solostar-graphql";
import { AnalyticsBrowser } from "@segment/analytics-next";

export type SourceType = "Company" | "Institution";

type SearchedKeywords = {
  searchGroupId: string;
  name: string;
  category?: string;
};

type SearchedSuggestion = {
  name: string;
  sourceType: SourceType;
  sourceName?: string;
  candidateExternalId?: string;
  country?: string;
};

const emitAnalytics = (
  event: AnalyticsEvent,
  properties?: Record<string, number | string>
) => {
  events.emit(
    { name: EventName.SS_EMIT_ANALYTICS, data: { event, properties } },
    "top"
  );
};

export const emitSearchedKeywords = (properties: SearchedKeywords) => {
  emitAnalytics(AnalyticsEvent.SEARCHED_KEYWORDS, properties);
};

export const emitSearchedSuggestion = (properties: SearchedSuggestion) => {
  emitAnalytics(AnalyticsEvent.SEARCHED_SUGGESTION, properties);
};

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY ?? "",
});
