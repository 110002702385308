import React, { useMemo, useState } from "react";
import Sidesheet from "pages/Options/InstitutionsSidesheet";
import Modal from "components/Modal";
import EmptyState from "components/EmptyState";

import { array } from "solostar-lib";
import { useMatchPath } from "utils/hooks";
import { Header, Table, Badge, Tabs, Button } from "solostar-components";
import { Link } from "react-router-dom";
import {
  PlusCircleIcon,
  AcademicCapIcon,
  TrashIcon,
} from "@heroicons/react/solid";

import {
  useInstitutions,
  useDeleteInstitution,
  Institution,
  Scope,
  useDeleteAllInstitutions,
} from "solostar-graphql";

const TABS = [
  { displayName: "My Institutions", path: "", scope: Scope.USER },
  { displayName: "Team Institutions", path: "team", scope: Scope.TENANT },
];

const Institutions: React.FC = () => {
  const [deleteAllModalOpen, setDeleteAllModalOpen] = useState(false);
  const [deleteAllInstitutions] = useDeleteAllInstitutions();
  const [loadingDeleteAll, setLoadingDeleteAll] = useState(false);
  const [deleteInstitution] = useDeleteInstitution();
  const [sidesheetOpen, setSidesheetOpen] = useState(false);
  const [editingInstitution, setEditingInstitution] = useState<
    undefined | Institution
  >();

  const { data: institutions, loading, error } = useInstitutions();

  const activePath = useMatchPath(
    TABS.map((tab) => ({
      toReturn: tab.path,
      toMatch: `options/institutions/${tab.path}`,
    }))
  );

  const activeTab = TABS.find((tab) => tab.path === activePath) || TABS[0];

  const organized = useMemo(() => {
    const sorted = [...(institutions || [])].sort(array.sortByIdAndScope);
    return {
      [Scope.TENANT]: sorted.filter((obj) => obj.scope === Scope.TENANT),
      [Scope.USER]: sorted.filter((obj) => obj.scope === Scope.USER),
      [Scope.APP]: [],
    };
  }, [institutions]);

  if (loading) {
    return null;
  }

  if (!institutions || error) {
    return <p>{error?.message}</p>;
  }

  const hasInstitutions = !!organized[activeTab.scope].length;
  const addInstitution = (
    <Button
      key="add"
      icon={PlusCircleIcon}
      onClick={() => {
        setEditingInstitution(undefined);
        setSidesheetOpen(true);
      }}
    >
      New Institution
    </Button>
  );

  return (
    <React.Fragment>
      <Modal
        open={deleteAllModalOpen}
        setOpen={setDeleteAllModalOpen}
        title="Delete All Institutions?"
        description="This will delete all the custom institutions you've added to SoloStar. This action is not reversible."
        buttons={[
          <Button type="secondary" onClick={() => setDeleteAllModalOpen(false)}>
            Cancel
          </Button>,
          <Button
            status="error"
            loading={loadingDeleteAll}
            onClick={async () => {
              setLoadingDeleteAll(true);
              await deleteAllInstitutions();
              setLoadingDeleteAll(false);
              setDeleteAllModalOpen(false);
            }}
          >
            Delete All Institutions
          </Button>,
        ]}
      />
      <Sidesheet
        isOpen={sidesheetOpen}
        onRequestClose={() => setSidesheetOpen(false)}
        institution={editingInstitution}
      />
      <div className="mb-8">
        <Header
          title="Institutions"
          buttons={[
            <Button
              key="deleteAll"
              status="error"
              icon={TrashIcon}
              onClick={() => setDeleteAllModalOpen(true)}
            >
              Delete All
            </Button>,
            addInstitution,
          ]}
        />
      </div>
      <Tabs
        linkComponent={Link}
        tabs={TABS.map((tab) => ({
          ...tab,
          count: organized[tab.scope]?.length,
          active: activeTab.scope === tab.scope,
        }))}
      />
      {hasInstitutions ? (
        <Table
          columns={[
            {
              id: 0,
              displayName: "Name",
              accessor: (institution) => institution.displayName,
            },
            {
              id: 1,
              displayName: "Selectivity",
              accessor: (company) =>
                company.selectivity ? (
                  <Badge
                    color={company.selectivity.color}
                    key={company.selectivity.id}
                  >
                    {company.selectivity.displayName}
                  </Badge>
                ) : (
                  "None"
                ),
            },
            {
              id: 2,
              displayName: "Tags",
              accessor: (institution) =>
                institution.tags?.length ? (
                  <div>
                    {institution.tags?.map((tag) => (
                      <Badge color={tag.color} key={tag.id}>
                        {tag.displayName}
                      </Badge>
                    ))}
                  </div>
                ) : (
                  "None"
                ),
            },
            {
              id: 3,
              displayName: "",
              accessor: (institution) => (
                <div className="text-right">
                  <Button
                    type="text"
                    status="error"
                    onClick={() =>
                      deleteInstitution({
                        variables: { input: { id: institution.id } },
                      })
                    }
                  >
                    Delete
                  </Button>
                  <Button
                    type="text"
                    onClick={() => {
                      setEditingInstitution(institution);
                      setSidesheetOpen(true);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              ),
            },
          ]}
          data={organized[activeTab.scope]}
        />
      ) : (
        <div className="mt-8 center">
          <EmptyState
            title="No Institutions"
            description="Add an institution here to show custom tags and selectivity when you're viewing results on LinkedIn Recruiter."
            buttons={[addInstitution]}
            icon={AcademicCapIcon}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Institutions;
