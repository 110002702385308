import { gql, useMutation } from "@apollo/client";
import { AddCompany, AddCompanyVariables } from "../__generated__/AddCompany";
import { COMPANY_FRAGMENT } from "fragments/candidate";

export const ADD_COMPANY = gql`
  ${COMPANY_FRAGMENT}
  mutation AddCompany($input: AddCompanyInput!) {
    addCompany(input: $input) {
      ...CompanyFragment
    }
  }
`;

export const useAddCompany = (variables?: AddCompanyVariables) =>
  useMutation<AddCompany, AddCompanyVariables>(ADD_COMPANY, {
    variables,
    refetchQueries: ["GetCompanies"],
    awaitRefetchQueries: true,
  });
