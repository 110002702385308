import Creatable from "react-select/creatable";
import Select from "react-select";

import { useMemo, useState } from "react";
import { Badge, RadioGroupOption } from "solostar-components";
import { constants, array, colors, async } from "solostar-lib";

import {
  Scope,
  Tag,
  TagTarget,
  TagType,
  useTags,
  addTag,
} from "solostar-graphql";

export const DEFAULT_TAG_IDS = Object.keys(constants.DEFAULT_TAGS);
export const DEFAULT_TAG_COLOR = colors.BRAND_DARK;

export type MultiSelectOption = {
  value: string;
  label: JSX.Element;
  displayName: string;
  isNew?: boolean;
};

export const tagToBadge = (tag: Tag, isUrm?: boolean) => (
  <Badge key={tag.id} color={isUrm ? colors.URM : tag.color}>
    {tag.displayName}
  </Badge>
);

export const tagToSelectOption = (
  tag: Tag,
  isUrm?: boolean
): MultiSelectOption => ({
  value: tag.id,
  label: tagToBadge(tag, isUrm),
  displayName: tag.displayName,
});

export const deDupeTags = (target: TagTarget, tags?: Tag[]): Tag[] =>
  (tags || [])
    ?.filter((tag) => tag.target === target)
    .filter((tag) => !tag.hidden)
    .sort(array.sortByIdAndScope)
    .reduce(array.reduceByFirstMatch, [] as Tag[]);

export const useDefaultTags = (target: TagTarget) => {
  const { data: tags } = useTags();
  return useMemo(() => {
    const deduped = deDupeTags(target, tags);
    return {
      default: deduped.filter(
        (tag) => DEFAULT_TAG_IDS.includes(tag.id) && tag.target === target
      ),
      custom: deduped.filter(
        (tag) => !DEFAULT_TAG_IDS.includes(tag.id) && tag.target === target
      ),
    };
  }, [tags, target]);
};

export const getTagIds = async (
  tags: MultiSelectOption[],
  scope: Scope,
  target: TagTarget
) => {
  const tagIds: string[] = [];
  await async.asyncForEach(tags, async (tag) => {
    if (!tag.isNew) {
      tagIds.push(tag.value);
      return;
    }
    const { data } = await addTag({
      input: {
        displayName: tag.value,
        scope,
        color: DEFAULT_TAG_COLOR,
        type: TagType.CUSTOM,
        target: target,
        hidden: false,
      },
    });
    if (data?.addTag?.id) {
      tagIds.push(data.addTag.id);
    }
  });
  return tagIds;
};

export const getTagOptions = (
  target: TagTarget,
  tags?: Tag[],
  showSelectivity?: boolean
): MultiSelectOption[] =>
  (tags || [])
    ?.filter((tag) => tag.target === target)
    .filter((tag) =>
      showSelectivity
        ? tag.type === TagType.SELECTIVITY
        : tag.type !== TagType.SELECTIVITY
    )
    .sort(array.sortByIdAndScope)
    .reduce(array.reduceByFirstMatch, [] as Tag[])
    .map((tag) => ({
      value: tag.id,
      label: <Badge color={tag.color}>{tag.displayName}</Badge>,
      displayName: tag.displayName,
    }));

export const useSelectivitySelect = (target: TagTarget, tags: Tag[] = []) => {
  const [selectivity, setSelectivity] = useState<MultiSelectOption | null>(
    null
  );
  const selectivityOptions = useMemo(
    () => getTagOptions(target, tags, true),
    [tags, target]
  );
  const selectivitySelect = useMemo(
    () => (
      <Select
        options={selectivityOptions}
        value={selectivity}
        isClearable={true}
        onChange={(selectivity) => setSelectivity(selectivity)}
        filterOption={(item, inputValue) =>
          item.data.displayName.toLowerCase().includes(inputValue.toLowerCase())
        }
      />
    ),
    [selectivity, selectivityOptions]
  );
  return { selectivity, setSelectivity, selectivitySelect };
};

export const useTagsSelect = (target: TagTarget, tagsData: Tag[] = []) => {
  const [tags, setTags] = useState<MultiSelectOption[]>([]);
  const tagsOptions = useMemo(
    () => getTagOptions(target, tagsData),
    [tagsData, target]
  );
  const tagsSelect = useMemo(
    () => (
      <Creatable
        options={tagsOptions}
        isMulti={true}
        closeMenuOnSelect={false}
        value={tags}
        onChange={(tags) => setTags(tags as MultiSelectOption[])}
        filterOption={(item, inputValue) =>
          item.data.displayName.toLowerCase().includes(inputValue.toLowerCase())
        }
        getNewOptionData={(inputValue) => ({
          value: inputValue,
          label: <Badge color={DEFAULT_TAG_COLOR}>{inputValue}</Badge>,
          displayName: inputValue,
          isNew: true,
        })}
      />
    ),
    [tagsOptions, tags]
  );
  return { tags, setTags, tagsSelect };
};

export const getScopeOptions = (
  target: TagTarget,
  tenant?: string | null
): RadioGroupOption<Scope>[] => [
  {
    id: Scope.USER,
    displayName: {
      title: "Just You",
      description: `Only you will be able to see the tags applied to this ${target}.`,
    },
  },
  {
    id: Scope.TENANT,
    displayName: {
      title: tenant || "Your Team",
      description: `Anyone on ${
        tenant ? `the ${tenant}` : "your"
      } team can see the tags applied to this institution.`,
    },
  },
];
