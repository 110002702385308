import { gql, useMutation } from "@apollo/client";
import { AddTag, AddTagVariables } from "../__generated__/AddTag";
import { client } from "../apollo";
import { TAG_FRAGMENT } from "../fragments/tag";

export const ADD_TAG = gql`
  ${TAG_FRAGMENT}
  mutation AddTag($input: AddTagInput!) {
    addTag(input: $input) {
      ...TagFragment
    }
  }
`;

export const useAddTag = (variables?: AddTagVariables) =>
  useMutation<AddTag, AddTagVariables>(ADD_TAG, {
    variables,
    refetchQueries: ["GetTags"],
    awaitRefetchQueries: true,
  });

export const addTag = (variables?: AddTagVariables) =>
  client.mutate<AddTag, AddTagVariables>({ mutation: ADD_TAG, variables });
