import { gql, useQuery, QueryHookOptions, QueryOptions } from "@apollo/client";
import {
  GetCompanyFeeders,
  GetCompanyFeedersVariables,
} from "../__generated__/GetCompanyFeeders";
import { client } from "../apollo";

export const GET_COMPANY_FEEDERS = gql`
  query GetCompanyFeeders($id: String!) {
    getCompanyFeeders(id: $id) {
      id
      displayName
      url
      summary {
        website
      }
    }
  }
`;

export const useCompanyFeeders = (
  variables: GetCompanyFeedersVariables,
  options: QueryHookOptions<GetCompanyFeeders, GetCompanyFeedersVariables>
) => {
  const res = useQuery<GetCompanyFeeders, GetCompanyFeedersVariables>(
    GET_COMPANY_FEEDERS,
    {
      variables,
      ...options,
    }
  );
  return { ...res, data: res.data?.getCompanyFeeders };
};

export const getCompanyFeeders = (
  variables: GetCompanyFeedersVariables,
  options?: Partial<QueryOptions<GetCompanyFeeders, GetCompanyFeedersVariables>>
) => {
  return client.query<GetCompanyFeeders, GetCompanyFeedersVariables>({
    ...options,
    query: GET_COMPANY_FEEDERS,
    variables,
  });
};
