import { gql, useQuery } from "@apollo/client";
import {
  GetAnalytics,
  GetAnalyticsVariables,
} from "../__generated__/GetAnalytics";

export const GET_ANALYTICS = gql`
  query GetAnalytics($input: GetAnalyticsInput!) {
    getAnalytics(input: $input) {
      timeSeries {
        id
        granularity
        displayName
        unit
        data {
          time
          value
        }
      }
    }
  }
`;

export const useAnalytics = (variables: GetAnalyticsVariables) => {
  const res = useQuery<GetAnalytics, GetAnalyticsVariables>(GET_ANALYTICS, {
    variables,
  });
  return { ...res, data: res.data?.getAnalytics };
};
