import React from "react";

type EmptyStateProps = {
  title: string | JSX.Element;
  icon?: React.FC<any>;
  description?: string | JSX.Element;
  buttons?: JSX.Element[];
};

const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const { title, icon: Icon, description, buttons } = props;
  return (
    <div className="relative max-w-lg block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 m-8 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      {Icon && (
        <Icon
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        />
      )}

      <h3 className="mt-2 text-sm font-medium text-gray-900">{title}</h3>
      {description &&
        (typeof description === "string" ? (
          <p className="mt-1 text-sm text-gray-500">{description}</p>
        ) : (
          description
        ))}
      {buttons && (
        <div className="mt-8 mx-auto flex group w-fit">{buttons}</div>
      )}
    </div>
  );
};

export default EmptyState;
