import React from "react";
import cn from "classnames";
import { MinusCircleIcon } from "@heroicons/react/outline";

type Size = "default" | "small";
type Type = "primary" | "secondary" | "text";
type Status = "default" | "error";

type Props = {
  type?: Type;
  size?: Size;
  status?: Status;
  icon?: React.FC<{ className?: string }>;
  onClick?: () => void;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
};

const CLASSNAMES_NON_TEXT_DEFAULT =
  "inline-flex items-center border rounded-md shadow-sm font-medium focus:ring-2 focus:ring-offset-2";

const CLASSNAMES_DISABLED = "cursor-not-allowed opacity-50 bg-current";

const getSize = (size: Size, type: Type) => {
  if (type === "text") {
    return "px-2 py-0 text-xs";
  }
  switch (size) {
    case "small":
      return "px-2 py-1 text-xs";
    default:
      return "px-4 py-2 text-sm";
  }
};

const getTextColor = (type: Type, status: Status) => {
  if (type === "primary") {
    return "text-white";
  }
  if (status === "error") {
    return "text-red-500 hover:text-red-700";
  }
  return "text-gray-700 hover:text-gray-900";
};

const getBackground = (type: Type, status: Status) => {
  if (type === "primary" && status === "error") {
    return `${CLASSNAMES_NON_TEXT_DEFAULT} border-transparent bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-red-700`;
  }
  switch (type) {
    case "secondary":
      return `${CLASSNAMES_NON_TEXT_DEFAULT} border-gray-300 bg-white hover:bg-gray-50 focus:outline-none focus:ring-indigo-500`;
    case "text":
      return "underline";
    default:
      return `${CLASSNAMES_NON_TEXT_DEFAULT} border-transparent bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-indigo-500`;
  }
};

const getIconSize = (size: Size) => {
  switch (size) {
    case "small":
      return "h-4 w-4";
    default:
      return "h-5 w-5";
  }
};

const Button: React.FC<Props> = (props) => {
  const {
    onClick,
    children,
    icon: Icon,
    loading,
    type = "primary",
    status = "default",
    size = "default",
    className: classNameExtra,
    disabled,
  } = props;

  const className = cn([
    classNameExtra,
    disabled && CLASSNAMES_DISABLED,
    getSize(size, type),
    getTextColor(type, status),
    getBackground(type, status),
  ]);

  const iconClassName = cn(["-ml-1 mr-2", getIconSize(size)]);

  return (
    <button
      disabled={!!disabled}
      type="button"
      onClick={() => {
        if (!loading && onClick && !disabled) {
          onClick();
        }
      }}
      className={className}
    >
      {loading ? (
        <MinusCircleIcon className={`animate-spin ${iconClassName}`} />
      ) : (
        Icon && <Icon className={iconClassName} aria-hidden="true" />
      )}
      {children}
    </button>
  );
};

export default Button;
