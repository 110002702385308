/**
 * Simple "sleep" function that waits for a certain period of time and blocks
 * the thread during that time.
 */
export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const asyncForEach = async <T>(
  array: T[],
  callback: (value: T) => Promise<void>
) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index]);
  }
};
