import { gql } from "@apollo/client";
import { TAG_FRAGMENT } from "fragments/tag";
import { PERSON_FRAGMENT } from "fragments/person";
import { SEARCH_GROUP_FRAGMENT } from "fragments/searchGroup";

export const COMPANY_FRAGMENT = gql`
  ${TAG_FRAGMENT}
  fragment CompanyFragment on Company {
    id
    displayName
    url
    selectivity {
      ...TagFragment
    }
    scope
    levels {
      name
      years
    }
    tags {
      ...TagFragment
    }
    contactInfos {
      type
      value
    }
    competitors {
      description
      companies {
        displayName
        website
        description
      }
    }
    summary {
      summary
      why
      website
    }
    layoffs {
      id
      amount
      createdAt
      source
    }
  }
`;

export const INSTITUTION_FRAGMENT = gql`
  ${TAG_FRAGMENT}
  fragment InstitutionFragment on Institution {
    id
    url
    displayName
    selectivity {
      ...TagFragment
    }
    scope
    country
    tags {
      ...TagFragment
    }
    summary {
      description
      programs {
        title
        whyImportant
      }
      researchLabs {
        title
        whyImportant
        url
      }
    }
  }
`;

export const DURATION_FRAGMENT = gql`
  fragment DurationTimeFragment on DurationTime {
    month
    year
    now
  }
`;

export const POSITION_FRAGMENT = gql`
  ${COMPANY_FRAGMENT}
  ${INSTITUTION_FRAGMENT}
  ${DURATION_FRAGMENT}
  fragment PositionFragment on Position {
    domId
    duration {
      lengthInDays
      start {
        ...DurationTimeFragment
      }
      end {
        ...DurationTimeFragment
      }
    }
    title
    location
    source {
      ... on Institution {
        ...InstitutionFragment
      }
      ... on Company {
        ...CompanyFragment
      }
    }
    searchIdeas {
      ...SearchGroupFragment
    }
  }
`;

export const CANDIDATE_FRAGMENT = gql`
  ${SEARCH_GROUP_FRAGMENT}
  ${POSITION_FRAGMENT}
  ${PERSON_FRAGMENT}

  fragment CandidateFragment on Candidate {
    name
    domId
    positions {
      ...PositionFragment
    }
    education {
      ...PositionFragment
    }
    person {
      ...PersonFragment
    }
    contactInfos {
      type
      value
    }
    externalUrl
    externalId
    location
    industry
    headline
    avatarUrl
    summary {
      description
      accomplishments {
        title
        whyImportant
        reasons
      }
    }
    interestingNotes {
      description
      interestingNotes {
        title
        whyImportant
        reasons
      }
    }
  }
`;
