import { gql, useQuery } from "@apollo/client";
import { GetInstitutions } from "../__generated__/GetInstitutions";
import { INSTITUTION_FRAGMENT } from "fragments/candidate";

export const GET_INSTITUTIONS = gql`
  ${INSTITUTION_FRAGMENT}
  query GetInstitutions {
    getInstitutions {
      ...InstitutionFragment
    }
  }
`;

export const useInstitutions = () => {
  const res = useQuery<GetInstitutions>(GET_INSTITUTIONS);
  return { ...res, data: res.data?.getInstitutions };
};
