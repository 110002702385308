import { gql, useQuery, QueryHookOptions, QueryOptions } from "@apollo/client";
import {
  GetCompanyCompetitors,
  GetCompanyCompetitorsVariables,
} from "../__generated__/GetCompanyCompetitors";
import { client } from "../apollo";

export const GET_COMPANY_COMPETITORS = gql`
  query GetCompanyCompetitors($query: String!) {
    getCompanyCompetitors(query: $query) {
      description
      companies {
        displayName
        website
        description
      }
    }
  }
`;

export const useCompanyCompetitors = (
  variables: GetCompanyCompetitorsVariables,
  options: QueryHookOptions<
    GetCompanyCompetitors,
    GetCompanyCompetitorsVariables
  >
) => {
  const res = useQuery<GetCompanyCompetitors, GetCompanyCompetitorsVariables>(
    GET_COMPANY_COMPETITORS,
    {
      variables,
      ...options,
    }
  );
  return { ...res, data: res.data?.getCompanyCompetitors };
};

export const getCompanyCompetitors = (
  variables: GetCompanyCompetitorsVariables,
  options?: Partial<
    QueryOptions<GetCompanyCompetitors, GetCompanyCompetitorsVariables>
  >
) => {
  return client.query<GetCompanyCompetitors, GetCompanyCompetitorsVariables>({
    ...options,
    query: GET_COMPANY_COMPETITORS,
    variables,
  });
};
