import React, { useMemo } from "react";

import { Position, ContactInfoType } from "solostar-graphql";

type Props = {
  position: Position;
};

const HistoryItem: React.FC<Props> = (props) => {
  const { position } = props;
  const { source } = position;

  const links = useMemo(() => {
    let cb = null;
    let finance = null;
    let stackshare = null;
    if (source && "contactInfos" in source) {
      cb = source.contactInfos?.find(
        (info) => info.type === ContactInfoType.LINK_CRUNCHBASE
      );
      finance = source.contactInfos?.find(
        (info) => info.type === ContactInfoType.LINK_GOOGLE_FINANCE
      );
      stackshare = source.contactInfos?.find(
        (info) => info.type === ContactInfoType.LINK_STACKSHARE
      );
    }
    return { cb, finance, stackshare };
  }, [source]);

  if (!Object.keys(links).length) {
    return null;
  }

  return (
    <div className="flex">
      {links.cb && (
        <a
          key={links.cb.value}
          href={links.cb.value || ""}
          target="_blank"
          rel="noopener noreferrer"
          className="h-6 flex items-center bg-gray-200 rounded-full py-1 px-2 hover:bg-gray-300"
        >
          <img
            width="16"
            alt="Crunchbhase logo"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a1/Crunchbase_favicon.svg/64px-Crunchbase_favicon.svg.png?20201102213614"
          />
        </a>
      )}
      {links.finance && (
        <a
          key={links.finance.value}
          href={links.finance.value || ""}
          target="_blank"
          rel="noopener noreferrer"
          className="h-6 flex items-center bg-gray-200 rounded-full py-1 px-2 hover:bg-gray-300"
        >
          <img
            width="16"
            alt="Google Finance logo"
            src="https://www.solostar.io/logos/google-finance.png"
          />
        </a>
      )}
      {links.stackshare && (
        <a
          key={links.stackshare.value}
          href={links.stackshare.value || ""}
          target="_blank"
          rel="noopener noreferrer"
          className="h-6 flex items-center bg-gray-200 rounded-full py-1 px-2 hover:bg-gray-300"
        >
          <img
            width="16"
            alt="StackShare logo"
            src="https://cdn.iconscout.com/icon/free/png-512/free-stackshare-logo-icon-download-in-svg-png-gif-file-formats--technology-social-media-vol-6-pack-logos-icons-3029699.png?f=webp&w=512"
          />
        </a>
      )}
    </div>
  );
};

export default HistoryItem;
