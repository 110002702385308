import { gql } from "@apollo/client";

export const TAG_FRAGMENT = gql`
  fragment TagFragment on Tag {
    id
    type
    displayName
    color
    hidden
    scope
    target
  }
`;
