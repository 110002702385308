import { gql, useMutation } from "@apollo/client";
import {
  UpdateInstitution,
  UpdateInstitutionVariables,
} from "../__generated__/UpdateInstitution";
import { INSTITUTION_FRAGMENT } from "fragments/candidate";

export const UPDATE_INSTITUTION = gql`
  ${INSTITUTION_FRAGMENT}
  mutation UpdateInstitution($input: UpdateInstitutionInput!) {
    updateInstitution(input: $input) {
      ...InstitutionFragment
    }
  }
`;

export const useUpdateInstitution = (variables?: UpdateInstitutionVariables) =>
  useMutation<UpdateInstitution, UpdateInstitutionVariables>(
    UPDATE_INSTITUTION,
    {
      variables,
      refetchQueries: ["GetCompanies"],
      awaitRefetchQueries: true,
    }
  );
