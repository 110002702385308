import React from "react";

import {
  Text,
  Table,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
} from "@tremor/react";

import { TimeSeries, TimeSeriesGranularity } from "solostar-graphql";
import { format } from "date-fns";

type Props = {
  data: TimeSeries[];
  granularity: TimeSeriesGranularity;
};

const formatString = (granularity: TimeSeriesGranularity) => {
  switch (granularity) {
    case TimeSeriesGranularity.QUARTER:
      return "QQQ y";
    case TimeSeriesGranularity.MONTH:
      return "MMMM y";
    default:
      return "PP";
  }
};

const AnalyticsTable: React.FC<Props> = (props) => {
  const { granularity } = props;

  const data = React.useMemo(() => {
    return [...props.data].sort(
      (a, b) =>
        Math.max(...b.data.map((d) => d.value || 0)) -
        Math.max(...a.data.map((d) => d.value || 0))
    );
  }, [props.data]);

  const dates = React.useMemo(() => {
    const d: string[] = [];
    data.forEach((user) => {
      user.data.forEach((point) => {
        if (!d.includes(point.time)) {
          d.push(point.time);
        }
      });
    });
    return d.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  }, [data]);

  return (
    <Table marginTop="mt-5">
      <TableHead>
        <TableRow>
          <TableHeaderCell>User</TableHeaderCell>
          {dates.map((d) => (
            <TableHeaderCell key={d}>
              {format(new Date(d), formatString(granularity))}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <TableRow key={item.id}>
            <TableCell>{item.displayName}</TableCell>
            {dates.map((d) => {
              const count =
                item.data.find((point) => point.time === d)?.value || 0;
              return (
                <TableCell key={d}>
                  <Text>{count}</Text>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default AnalyticsTable;
