import React from "react";

type Orientation = "vertical" | "horizontal";

type SidesheetFormItemProps = {
  label: string;
  htmlFor?: string;
  orientation?: Orientation;
};

const SidesheetFormItem: React.FC<SidesheetFormItemProps> = (props) => {
  const { label, htmlFor } = props;

  return (
    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
      <div>
        <label
          htmlFor={htmlFor}
          className="block text-sm font-medium capitalize text-gray-900 sm:mt-px sm:pt-2"
        >
          {label}
        </label>
      </div>
      <div className="sm:col-span-2">{props.children}</div>
    </div>
  );
};

export default SidesheetFormItem;
