import Loader from "components/Loader";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "solostar-components";
import {
  SearchFieldType,
  useCompany,
  useCompanyFeeders,
} from "solostar-graphql";
import SidebarEditKeywordsSidesheet from "./SidebarEditKeywordsSidesheet";
import { isExtensionOrPopup } from "utils/env";
import SidebarHeader from "./SidebarHeader";
import { getSearchId, useFakeLoading } from "./CompanyCompetitors";
import { useNavigationHook } from "utils/navigationHook";
import GoBackButton, { EmptyStateGoBack } from "components/AIGoBackHeader";

export const Feeders: React.FC<{
  companyId?: string;
}> = (props) => {
  const { companyId } = props;
  useNavigationHook();
  const [saveOpen, setSaveOpen] = useState(false);

  const id = getSearchId(companyId);
  const fakeLoading = useFakeLoading();
  const navigate = useNavigate();

  const { data: company, loading: l1 } = useCompany(
    { id: id?.id ?? "" },
    { skip: !id || id.type !== "lookup" }
  );

  const { data, loading: l2 } = useCompanyFeeders(
    { id: id?.id ?? "" },
    { skip: id?.type !== "lookup" }
  );

  if (l1 || l2 || fakeLoading) {
    return (
      <div>
        {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
        <div className="w-screen h-screen flex flex-col items-center justify-center">
          <Loader />
          <div className="mb-2 mt-8 animate-pulse flex items-center">
            <img
              className="w-8 mr-1 object-contain animate-pulse"
              src="/logo192.png"
              alt="SoloStar Logo"
            />
            <span className="text-xl font-medium">SoloStar</span>
          </div>
          <p className="p-8 text-center">
            SoloStar AI is thinking 🤔...we're going to find some great company
            matches similar to <b>{company?.displayName}</b> that you can save
            into your search.
          </p>
        </div>
      </div>
    );
  }

  if (!data || !company) {
    return (
      <EmptyStateGoBack
        id={companyId}
        title="Feeders Not Found"
        description="Sorry, we couldn't find any feeders for this Company."
      />
    );
  }

  return (
    <div className="">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
      <div className="p-8">
        <GoBackButton id={companyId} />
        <SidebarEditKeywordsSidesheet
          searchGroup={null}
          onFinishSave={() => navigate("/")}
          defaultValues={{
            id: "1",
            name: `${company.displayName} Feeders`,
            category: "Feeders",
          }}
          defaultFields={[
            {
              type: SearchFieldType.ENTITY,
              replaceCurrent: false,
              data: {
                key: "companies",
                scopeKey: "companyScope",
                scope: "CURRENT_OR_PAST",
                entities:
                  data?.map((c) => ({
                    required: false,
                    entity: null,
                    text: c?.displayName,
                    negated: false,
                  })) ?? [],
              },
            },
          ]}
          isOpen={saveOpen}
          onRequestClose={() => {
            navigate("/");
          }}
        />
        <p className="">
          Some companies that often feed candidates into {company.displayName}{" "}
          are:
        </p>
        <ol className="flex flex-wrap gap-1 my-8">
          {data.map((c) => (
            <li
              key={c.displayName}
              className="text-center p-2 w-full bg-gray-100 flex items-center gap-2 border border-gray-200 overflow-hidden text-sm"
            >
              {c.url && (
                <img
                  alt="clearbit logo"
                  src={`https://logo.clearbit.com/${c.url}`}
                  className="w-8 h-8 rounded-md object-contain"
                />
              )}
              {c.url ? (
                <a
                  href={c.url}
                  className="text-sm underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>{c.displayName}</b>
                </a>
              ) : (
                <b>{c.displayName}</b>
              )}
            </li>
          ))}
        </ol>
        <Button onClick={() => setSaveOpen(true)}>Save as Search Query</Button>
        <div className="mt-8 text-xs">
          <a href="https://clearbit.com">Logos provided by Clearbit</a>
        </div>
      </div>
    </div>
  );
};

const CompanyFeeders: React.FC = () => {
  const { companyId } = useParams<{ companyId?: string }>();
  return <Feeders companyId={companyId} />;
};

export default CompanyFeeders;
