import React, { MouseEventHandler, useEffect, useState } from "react";
import cs from "classnames";

import { auth } from "solostar-lib";
import { LockClosedIcon } from "@heroicons/react/solid";
import { Input } from "solostar-components";
import { isExtension } from "utils/env";

type Props = {
  description?: string | JSX.Element;
  className?: string;
};

const LOGIN_WITH_EMAIL_PARAM = "loginWithEmail";

const getEmailParam = () => {
  const params = new URL(document.location.href).searchParams;
  return params.get(LOGIN_WITH_EMAIL_PARAM);
};

const Login: React.FC<Props> = (props) => {
  const { description, className } = props;

  const [email, setEmail] = useState("");

  useEffect(() => {
    const loginWithEmail = getEmailParam();
    if (loginWithEmail !== null) {
      auth.login(loginWithEmail);
    }
  }, []);

  const onSubmitGoogle: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (isExtension()) {
      const loginUrl = `${process.env.REACT_APP_EXTENSION_WEB_URL}?${LOGIN_WITH_EMAIL_PARAM}=${email}`;
      return window.open(loginUrl);
    }
    auth.login(email);
  };

  return (
    <div className={cs(["center", className])}>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="/logo192.png"
              alt="SoloStar Logo"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 leading-tight">
              Sign in to your
              <br />
              SoloStar account
            </h2>
            {description ? (
              <p className="mt-2 text-center text-sm text-gray-600">
                {description}
              </p>
            ) : (
              <p className="mt-2 text-center text-sm text-gray-600">
                <span className="font-medium text-indigo-600 hover:text-indigo-500">
                  Start your 30-day free trial
                </span>
              </p>
            )}
          </div>
          <form className="mt-8 space-y-6" onSubmit={onSubmitGoogle as any}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <Input
                  value={email}
                  setValue={setEmail}
                  // className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group mb-2 relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onSubmitGoogle}
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                Sign in with Google
              </button>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-50 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={(e) => {
                  e.preventDefault();
                  if (isExtension()) {
                    const loginUrl = process.env.REACT_APP_EXTENSION_WEB_URL;
                    return window.open(loginUrl);
                  }
                  auth.loginWithMicrosoft(email);
                }}
              >
                Sign in with Microsoft
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
