import XRegExp from "xregexp";

const PARANTHESES_START_DELIMITER = "\\(";
const PARANTHESES_END_DELIMITER = "\\)";
const OPERATORS = ["and", "or"];
const VALUE_NAME_BETWEEN = "between";
const VALUE_NAME_MATCH = "match";
const PARANTHESES_ESCAPE_REGEX = new RegExp(/".*?"/g);
const PARANTHESES_ESCAPE_CHAR = "%";
const PARANTHESES_START_REGEX = `(?<!${PARANTHESES_ESCAPE_CHAR})\\(`;
const PARANTHESES_END_REGEX = `(?<!${PARANTHESES_ESCAPE_CHAR})\\)`;

const memoized: Record<string, string> = {};

type ParsedQueryString = (string | ParsedQueryString)[] | string;

const isOperator = (str: string) => OPERATORS.includes(str.toLowerCase());

const cleanString = (str: string) =>
  str
    .replace(`${PARANTHESES_ESCAPE_CHAR}(`, "(")
    .replace(`${PARANTHESES_ESCAPE_CHAR})`, ")");

/**
 * Takes a string and parses it into the boolean's "tokens." For example,
 * it turns  `AAA OR BBB OR "CCC DDD"` into `["AAA", "OR", "BBB", "OR", "CCC DDD"].
 *
 * @param str: a string that needs to be parsed.
 * @returns ParsedQueryString
 */
const cleanGroup = (str: string) => {
  /**
   * Find all items that are within quotes so that we don't split
   * those by their spaces.
   */
  const matches = Array.from(str.trim().matchAll(RegExp(/"(.+?)"/g))).map(
    (match) => match[1]
  );

  /** Final array of strings to return. */
  const fragments: string[] = [];

  /**
   * Split the string by quotes and then parse each of the fragments
   * individually. We want to keep segments within quotes in tact, but
   * split the rest of the tokens by space.
   */
  str.split('"').forEach((fragment) => {
    /** If this is one of quoted tokens, don't break it apart by space. */
    if (matches?.includes(fragment)) {
      fragments.push(`"${fragment}"`);
    } else if (fragment) {
      /** Otherwise break it apart by space and filter out any empty spaces. */
      const cleaned = fragment
        .split(" ")
        .filter((str) => str)
        .map((str) => str.trim());
      fragments.push(...cleaned);
    }
  });

  if (fragments[0] && fragments.every(isOperator)) {
    return cleanString(fragments[0]);
  }

  /**
   * If the array only has one item, return it directly, otherwise return the
   * array.
   */
  if (fragments.length > 1) {
    return fragments.map(cleanString);
  }

  return cleanString(fragments[0]);
};

/**
 * Parse a "boolean" query string into its relevant tokens, while keeping
 * in tact parantheticals and the order of operations.
 */
export const parseQueryString = (str: string): ParsedQueryString => {
  if (!str) {
    return "";
  }

  /**
   * If keywords surrounding by quotation marks have parantheses in them,
   * we need to escape those parantheses so that the next Regex doesn't think
   * they are separate keywords. For example `"Rutgers University" AND "University of Texas (A&M)"`
   * should not be parsed as `["Rutgers University", "AND", "Unviversity of Texas", ["A&M"]]`.
   */
  const strToParse = str.replace(PARANTHESES_ESCAPE_REGEX, (m) => {
    return m
      .replace(
        new RegExp(PARANTHESES_START_REGEX),
        `${PARANTHESES_ESCAPE_CHAR}(`
      )
      .replace(
        new RegExp(PARANTHESES_END_REGEX),
        `${PARANTHESES_ESCAPE_CHAR})`
      );
  });

  /** Instantitate final array of fragments to return. */
  const parsed: ParsedQueryString = [];

  /**
   * Run pattern matching algo to detect "groups" within parantheses. This algo parses
   * strings into substrings and classifies them as one of "between," "match," "left"
   * (always just the left delimiter), and "right" (always right delimiter).
   */
  const groups = XRegExp.matchRecursive(
    strToParse,
    PARANTHESES_START_DELIMITER,
    PARANTHESES_END_DELIMITER,
    "g",
    {
      valueNames: [VALUE_NAME_BETWEEN, "l", VALUE_NAME_MATCH, "r"],
      unbalanced: "skip",
      escapeChar: PARANTHESES_ESCAPE_CHAR,
    }
  );

  /**
   * If pattern matcher finds no matches, parse the string as a single "group."
   */
  if (!groups.length) {
    return cleanGroup(str);
  }

  /**
   * Parse each matched group.
   */
  groups.forEach((group, i, arr) => {
    /**
     * For the values that are "between" (meaning not surrounded by parantheses but between
     * such groups) and "match" (meaning strings within a set of parantheses), evaluate them
     * further by sending them through this parsing algorithm again.
     */
    if (group.name === VALUE_NAME_BETWEEN) {
      const parsedGroup = parseQueryString(group.value);

      const isFirstOrLast = i === 0 || i === arr.length - 1;
      const isString = typeof parsedGroup === "string";
      const isArray = Array.isArray(parsedGroup);

      /**
       * If this is the first or last element in the group, it cannot be an operator, as we cannot
       * start or end a parantheses group with AND or OR. So when that is the case, just ignore it.
       */
      if (isFirstOrLast && isString && isOperator(parsedGroup)) {
        return;
      }

      /** Otherwise, if the parsed group is being built, add it there. */
      if (isArray) {
        parsed.push(...parsedGroup);
      } else {
        parsed.push(parsedGroup);
      }
    }

    if (group.name === VALUE_NAME_MATCH) {
      const parsedGroup = parseQueryString(group.value);
      parsed.push(parsedGroup);
    }
  });

  /** If there were no groups to be parsed, return an empty string. */
  if (!parsed.length) {
    return "";
  }

  /** Otherwise, return the parsed array. */
  return parsed;
};

export const getQueryString = (parsed: ParsedQueryString): string => {
  if (typeof parsed === "string") {
    return parsed;
  }
  if (parsed?.length === 1) {
    return getQueryString(parsed[0]);
  }
  if (!parsed?.length) {
    return "";
  }
  return `(${parsed
    .map((str) => getQueryString(str))
    .filter((str) => str)
    .join(" ")})`;
};

export const sanitizeQueryString = (str: string) => {
  const memoizedResult = memoized[str];
  if (memoizedResult) {
    return memoizedResult;
  }
  const parsed = parseQueryString(str);
  const newQueryString = getQueryString(parsed);
  memoized[str] = newQueryString;
  return newQueryString;
};
