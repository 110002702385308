const GRAPHQL_FIELDS = ["__typename"];

/** Remove all GraphQL specific fields from an object. */
export const cleanGraphQl = <T extends Record<string | number, unknown>>(
  obj: T
): T => {
  const newObj = { ...obj };
  GRAPHQL_FIELDS.forEach((field) => {
    if (newObj[field]) {
      delete newObj[field];
    }
  });
  return newObj;
};
