import { gql, useQuery, QueryOptions } from "@apollo/client";
import { SEARCH_GROUP_FRAGMENT } from "fragments/searchGroup";
import { client } from "../apollo";

import {
  GetGeneratedSearchGroup,
  GetGeneratedSearchGroupVariables,
} from "../__generated__/GetGeneratedSearchGroup";

export const GET_SEARCH_GROUP = gql`
  ${SEARCH_GROUP_FRAGMENT}
  query GetGeneratedSearchGroup($input: GenerateSearchGroupInput!) {
    generatedSearchGroup(input: $input) {
      ...SearchGroupFragment
    }
  }
`;

export const useSearchGroup = () => {
  const res = useQuery<
    GetGeneratedSearchGroup,
    GetGeneratedSearchGroupVariables
  >(GET_SEARCH_GROUP);
  return { ...res, data: res.data?.generatedSearchGroup };
};

export const getSearchGroup = async (
  variables: GetGeneratedSearchGroupVariables,
  options?: Partial<
    QueryOptions<GetGeneratedSearchGroup, GetGeneratedSearchGroupVariables>
  >
) => {
  const res = await client.query<
    GetGeneratedSearchGroup,
    GetGeneratedSearchGroupVariables
  >({
    ...options,
    query: GET_SEARCH_GROUP,
    variables,
  });
  return { ...res, data: res.data?.generatedSearchGroup };
};
