import React, { useState, useEffect, useCallback, useMemo } from "react";
import Select from "react-select";
import clone from "lodash/cloneDeep";

import {
  Button,
  Sidesheet,
  Input,
  InputValidation,
  RadioGroup,
} from "solostar-components";

import { CheckCircleIcon } from "@heroicons/react/outline";
import { getKeywords } from "utils/search";
import { MultiSelectOption } from "utils/tags";

import {
  SearchGroup,
  Scope,
  FacetType,
  useSearchIdeas,
  useUpdateSearchGroup,
  useDeleteSearchGroup,
  useAddSearchGroup,
  useMe,
  SearchFieldData,
  SearchFieldType,
  SearchFieldDataEntity,
  SearchFieldDataMultipleValues,
  SearchFieldDataSingleValue,
  SearchFieldEntity,
  SearchField,
} from "solostar-graphql";

type SidebarEditKeywordsSidesheetProps = {
  searchGroup: null | SearchGroup;
  defaultValues?: Partial<SearchGroup>;
  isOpen: boolean;
  onRequestClose: () => void;
  onFinishSave?: () => void;
  defaultFields?: SearchField[];
};

type Validation = {
  displayName?: InputValidation;
  keywords?: InputValidation;
  fields?: Record<string, InputValidation>;
};

const KEYWORD_CATEGORIES = [
  "Women",
  "Black",
  "LGBTQIA",
  "Veterans",
  "LatinX",
  "Other",
  "Direct Competitors",
  "Feeders",
  "Similar Institutions",
  "Special Interests",
];

const getEntitiesText = (entities: SearchFieldEntity[]) =>
  entities
    .filter((e) => !e.entity && !!e.text)
    .map((e) => e.text)
    .join(", ");

const KEYWORD_SUBCATEGORY = "Other";
const KEYWORD_CATEGORY_OPTIONS: MultiSelectOption[] = KEYWORD_CATEGORIES.map(
  (name) => ({ value: name, displayName: name, label: <>{name}</> })
);

const getDefaultGroup = (id: string, original?: SearchGroup[]) => {
  return (original || []).find(
    (g) => g.id === id && (!g.scope || g.scope === Scope.APP)
  );
};

const getSearchGroupFields = (fields?: SearchField[] | null) => {
  const newFields: Record<string, string> = {};
  fields?.forEach((field) => {
    const data = field.data;
    if ("value" in data && "key" in data) {
      newFields[data.key] = data.value;
    }
    if ("values" in data && "key" in data) {
      newFields[data.key] = data.values.join(", ");
    }
    if ("entities" in data) {
      const entitiesText = getEntitiesText(data.entities);
      if (entitiesText) {
        newFields[data.key] = entitiesText;
      }
    }
  });
  return newFields;
};

const getFieldType = (
  name: string,
  values: string
): { type: SearchFieldType; data: SearchFieldData } | undefined => {
  const split = [];
  if (values.includes(",")) {
    split.push(...values.split(",").map((val) => val.trim()));
  } else if (values.includes("\n")) {
    split.push(...values.split("\n").map((val) => val.trim()));
  }
  if (!split.length) {
    return undefined;
  }
  switch (name) {
    case "companies":
      return {
        type: SearchFieldType.ENTITY,
        data: {
          key: "companies",
          scopeKey: "companyScope",
          scope: "CURRENT_OR_PAST",
          entities: split.map((text) => ({
            required: false,
            entity: null,
            text,
            negated: false,
          })),
        },
      };
    case "keywords":
      return {
        type: SearchFieldType.VALUE_SINGLE,
        data: {
          key: "keywords",
          union: false,
          value: `(${split?.map((c) => `"${c}"`).join(" OR ")})`,
        },
      };
    default:
      return undefined;
  }
};

const SidebarEditKeywordsSidesheet: React.FC<
  SidebarEditKeywordsSidesheetProps
> = (props) => {
  const { data: original } = useSearchIdeas();
  const { data: me } = useMe();
  const {
    searchGroup,
    isOpen,
    onRequestClose,
    onFinishSave,
    defaultFields,
    defaultValues,
  } = props;

  const [validationEnabled, setValidationEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState("");
  const [displayName, setDisplayName] = useState(defaultValues?.name || "");
  const [scope, setScope] = useState(defaultValues?.scope ?? Scope.USER);
  const [fields, setFields] = useState<Record<string, string>>({});
  const [category, setCategory] = useState<MultiSelectOption | null>(() => {
    const category = defaultValues?.category;
    if (!category) {
      return KEYWORD_CATEGORY_OPTIONS[0];
    }
    return KEYWORD_CATEGORY_OPTIONS.find((o) => o.value === category) ?? null;
  });

  const [update] = useUpdateSearchGroup();
  const [del] = useDeleteSearchGroup();
  const [add] = useAddSearchGroup();

  const tenant = me?.tenant?.displayName;
  const scopeOptions = [
    {
      id: Scope.USER,
      displayName: {
        title: "Just You",
        description: `Only you will be able to see this search group.`,
      },
    },
    {
      id: Scope.TENANT,
      displayName: {
        title: tenant || "Your Team",
        description: `Anyone on ${
          tenant ? `the ${tenant}` : "your"
        } team can see this search group.`,
      },
    },
  ];

  const validate = useCallback(() => {
    const validation: Validation = {};
    if (!searchGroup && !displayName) {
      validation.displayName = {
        status: "error",
        text: "You must provide a name for this keyword group.",
      };
    }
    if (searchGroup?.queries.length && !keywords) {
      validation.keywords = {
        status: "error",
        text: "Keyword groups must have at least one keyword",
      };
    }
    const fieldValidation: Record<string, InputValidation> = {};
    const hasAtLeastOneField = Object.keys(fields).some((key) => !!fields[key]);
    if (!hasAtLeastOneField) {
      Object.keys(fields).forEach((key) => {
        if (!fields[key]) {
          fieldValidation[key] = {
            status: "error",
            text: "Must have at least one value.",
          };
        }
      });
    }
    if (Object.keys(fieldValidation).length) {
      validation.fields = fieldValidation;
    }
    return validation;
  }, [displayName, keywords, fields, searchGroup]);

  const validation = useMemo(() => {
    if (validationEnabled) {
      return validate();
    }
    return {};
  }, [validate, validationEnabled]);

  const onSave = useCallback(async () => {
    setValidationEnabled(true);
    const validation = validate();
    if (Object.keys(validation).length > 0) {
      return;
    }
    setLoading(true);
    if (!searchGroup) {
      const fieldsToSave = [];
      if (keywords?.length) {
        fieldsToSave.push({
          type: SearchFieldType.VALUE_SINGLE,
          data: {
            key: "keywords",
            value: keywords,
          } as any,
        });
      }
      Object.entries(fields).forEach(([key, value]) => {
        const thisField = getFieldType(key, value);
        if (thisField) {
          fieldsToSave.push(thisField);
        }
      });
      await add({
        variables: {
          input: {
            name: displayName,
            category: category?.value,
            subcategory: KEYWORD_SUBCATEGORY,
            scope,
            queries: [],
            fields: fieldsToSave,
          },
        },
      });
      setDisplayName("");
      setKeywords("");
      setLoading(false);
      onRequestClose();
      onFinishSave?.();
      return;
    }
    if (!!searchGroup.queries.length && keywords === getKeywords(searchGroup)) {
      setLoading(false);
      return;
    }
    const defaultGroup = getDefaultGroup(searchGroup.id, original);
    if (keywords === getKeywords(defaultGroup)) {
      await del({
        variables: {
          input: { id: searchGroup.id, scope: searchGroup.scope || scope },
        },
      });
    } else {
      /** Construct new fields array. */
      const newFields: Array<{
        type: SearchFieldType;
        data: SearchFieldData;
      }> = [];
      let areFieldsDifferent = false;
      searchGroup?.fields?.forEach((field) => {
        const data = clone(field.data) as any;
        if (data.__typename) {
          delete data.__typename;
        }
        if (
          "values" in data &&
          "key" in data &&
          fields[data.key] !== data.values.join(", ")
        ) {
          areFieldsDifferent = true;
          const values = fields[data.key]
            ?.split(",")
            .map((value) => value.trim());
          newFields.push({
            type: field.type,
            data: { ...data, values },
          });
        } else if ("entities" in data && fields[data.key]) {
          const entitiesTextOld = getEntitiesText(data.entities);
          const entitiesTextNew = fields[data.key];
          if (entitiesTextOld !== entitiesTextNew) {
            areFieldsDifferent = true;
          }
          const newEntities: SearchFieldEntity[] = [
            ...data.entities.filter((e: SearchFieldEntity) => !!e.entity),
            ...fields[data.key]
              .split(", ")
              .map((text) => ({ text, required: false, negated: false })),
          ];
          newFields.push({
            type: field.type,
            data: { ...data, entities: newEntities },
          });
        } else if ("value" in data && "key" in data) {
          if (data.value !== fields[data.key]) {
            areFieldsDifferent = true;
          }
          newFields.push({
            type: field.type,
            data: { ...data, value: fields[data.key] },
          });
        } else {
          newFields.push({
            type: field.type,
            data,
          });
        }
      });

      const areQueriesDifferent =
        !!keywords && keywords !== getKeywords(searchGroup);
      const isScopeDifferent = searchGroup.scope !== scope;
      const shouldUpdate =
        areFieldsDifferent || areQueriesDifferent || isScopeDifferent;

      if (shouldUpdate) {
        await update({
          variables: {
            input: {
              id: searchGroup.id,
              scope: searchGroup.scope || Scope.APP,
              searchGroup: {
                queries: keywords
                  ? [{ facetType: FacetType.KEYWORDS, query: keywords }]
                  : undefined,
                fields: areFieldsDifferent ? newFields : undefined,
                scope,
              },
            },
          },
        });
      }
    }
    setValidationEnabled(false);
    setLoading(false);
    onRequestClose();
    onFinishSave?.();
  }, [
    keywords,
    searchGroup,
    onRequestClose,
    update,
    del,
    original,
    category,
    add,
    displayName,
    validate,
    fields,
    scope,
    onFinishSave,
  ]);

  const onRevert = useCallback(async () => {
    if (searchGroup?.scope) {
      const defaultGroup = getDefaultGroup(searchGroup.id, original);
      const defaultKeywords = getKeywords(defaultGroup);
      if (defaultKeywords) {
        setKeywords(defaultKeywords);
      }
    }
  }, [original, searchGroup]);

  useEffect(() => {
    const newFields = getSearchGroupFields(defaultFields);
    if (Object.values(newFields).length) {
      setFields(newFields);
    }
  }, [defaultFields]);

  useEffect(() => {
    if (defaultValues?.name) {
      setDisplayName(defaultValues.name);
    }
    if (defaultValues?.queries) {
      const keywords = getKeywords(defaultValues as SearchGroup);
      setKeywords(keywords ?? "");
    }
  }, [defaultValues]);

  useEffect(() => {
    const keywords = getKeywords(searchGroup);
    setKeywords(keywords ?? "");
    const newFields = getSearchGroupFields(searchGroup?.fields);
    setFields(newFields);
  }, [searchGroup]);

  return (
    <Sidesheet
      title={searchGroup ? "Edit Search Group" : "New Search Group"}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      buttons={[
        <Button type="secondary" onClick={onRequestClose} key="cancel">
          Cancel
        </Button>,
        <Button
          icon={CheckCircleIcon}
          onClick={onSave}
          loading={loading}
          disabled={keywords === getKeywords(searchGroup)}
          key="create"
        >
          Save
        </Button>,
      ]}
    >
      <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
        {searchGroup && (
          <h1 className="text-md font-semibold p-6">{searchGroup.name}</h1>
        )}

        {!searchGroup && (
          <Sidesheet.FormItem label="Keyword Group Name">
            <Input
              value={displayName}
              setValue={setDisplayName}
              placeholder="What would you like to name this group?"
              validation={validation.displayName}
            />
          </Sidesheet.FormItem>
        )}

        {!searchGroup && (
          <Sidesheet.FormItem label="Category">
            <Select
              options={KEYWORD_CATEGORY_OPTIONS}
              value={category}
              onChange={(category) => setCategory(category)}
              filterOption={(item, inputValue) =>
                item.data.displayName
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </Sidesheet.FormItem>
        )}

        {((!searchGroup && !defaultFields) ||
          !!searchGroup?.queries?.length) && (
          <Sidesheet.FormItem label="Keywords">
            <Input
              type="textarea"
              value={keywords}
              setValue={setKeywords}
              validation={validation.keywords}
            />
          </Sidesheet.FormItem>
        )}

        {/* Allow user to add companies if this is a newly created suggestion. */}
        {!searchGroup && !defaultFields && (
          <Sidesheet.FormItem label="Companies" key="companies">
            <Input
              type="textarea"
              value={fields.companies}
              setValue={(newValues) => {
                setFields({ ...fields, companies: newValues });
              }}
              validation={validation.fields?.companies}
            />
          </Sidesheet.FormItem>
        )}

        {(searchGroup?.fields ?? defaultFields)
          ?.filter((field) => field.type === SearchFieldType.VALUE_SINGLE)
          .map((field) => {
            const data = field.data as SearchFieldDataSingleValue;
            return (
              <Sidesheet.FormItem label={data.key} key={data.key}>
                <Input
                  type="textarea"
                  value={fields[data.key] || ""}
                  setValue={(newValues) => {
                    setFields({ ...fields, [data.key]: newValues });
                  }}
                  validation={validation.fields?.[data.key]}
                />
              </Sidesheet.FormItem>
            );
          })}

        {searchGroup?.fields
          ?.filter((field) => field.type === SearchFieldType.VALUE_MULTIPLE)
          .map((field) => {
            const data = field.data as SearchFieldDataMultipleValues;
            return (
              <Sidesheet.FormItem label={data.key} key={data.key}>
                <Input
                  type="textarea"
                  value={fields[data.key] || ""}
                  setValue={(newValues) => {
                    setFields({ ...fields, [data.key]: newValues });
                  }}
                  validation={validation.fields?.[data.key]}
                />
              </Sidesheet.FormItem>
            );
          })}

        {(searchGroup?.fields ?? defaultFields)
          ?.filter(
            (field) =>
              field.type === SearchFieldType.ENTITY &&
              !!(field.data as SearchFieldDataEntity).entities.find(
                (e) => !e.entity
              )
          )
          .map((field) => {
            const data = field.data as SearchFieldDataEntity;
            const entity = data.entities.find((e) => !e.entity);
            if (!entity) {
              return null;
            }
            return (
              <Sidesheet.FormItem label={data.key} key={data.key}>
                <Input
                  type="textarea"
                  value={fields[data.key] || ""}
                  setValue={(newValues) => {
                    setFields({ ...fields, [data.key]: newValues });
                  }}
                  validation={validation.fields?.[data.key]}
                />
              </Sidesheet.FormItem>
            );
          })}

        <Sidesheet.FormItem label="Scope">
          <RadioGroup
            options={scopeOptions}
            value={scope}
            onChange={setScope}
          />
        </Sidesheet.FormItem>

        {searchGroup &&
          searchGroup.scope !== Scope.APP &&
          searchGroup.subcategory !== KEYWORD_SUBCATEGORY &&
          !!searchGroup?.queries?.length && (
            <p className="text-xs mt-6 p-6">
              <Button onClick={onRevert} type="text" className="py-0 px-0">
                Click here to reset the keywords to their defaults.
              </Button>
            </p>
          )}
      </div>
    </Sidesheet>
  );
};

export default SidebarEditKeywordsSidesheet;
