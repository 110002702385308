import { gql, QueryOptions } from "@apollo/client";
import { client } from "../apollo";

import { CANDIDATE_FRAGMENT } from "fragments/candidate";
import { FRAGMENT_DECORATION } from "fragments/html";

import {
  GetParsedDomItem,
  GetParsedDomItemVariables,
  GetParsedDomItem_getParsedDomItem,
} from "../__generated__/GetParsedDomItem";

export type ParsedDomItem = GetParsedDomItem_getParsedDomItem;

export const GET_PARSED_DOM_ITEM = gql`
  ${CANDIDATE_FRAGMENT}
  ${FRAGMENT_DECORATION}
  query GetParsedDomItem($input: ParseDomItemInput!) {
    getParsedDomItem(input: $input) {
      candidate {
        ...CandidateFragment
      }
      decorations {
        ...DecorationFragment
      }
    }
  }
`;

export const getParsedDomItem = (
  variables: GetParsedDomItemVariables,
  options: Partial<QueryOptions<GetParsedDomItem, GetParsedDomItemVariables>>
) => {
  return client.query<GetParsedDomItem, GetParsedDomItemVariables>({
    ...options,
    query: GET_PARSED_DOM_ITEM,
    variables,
  });
};
