import { AreaChart } from "@tremor/react";

const chartdata = [
  {
    date: "Jan 22",
    SemiAnalysis: 2890,
  },
  {
    date: "Feb 22",
    SemiAnalysis: 2756,
  },
  {
    date: "Mar 22",
    SemiAnalysis: 3322,
  },
  {
    date: "Apr 22",
    SemiAnalysis: 3470,
  },
  {
    date: "May 22",
    SemiAnalysis: 3475,
  },
  {
    date: "Jun 22",
    SemiAnalysis: 3129,
  },
  {
    date: "Jul 22",
    SemiAnalysis: 3490,
  },
  {
    date: "Aug 22",
    SemiAnalysis: 2903,
  },
  {
    date: "Sep 22",
    SemiAnalysis: 2643,
  },
  {
    date: "Oct 22",
    SemiAnalysis: 2837,
  },
  {
    date: "Nov 22",
    SemiAnalysis: 2954,
  },
  {
    date: "Dec 22",
    SemiAnalysis: 3239,
  },
];

const FakeStockChart = () => {
  return (
    <div className="relative">
      <img
        className="mx-auto h-12 w-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50"
        src="/logo192.png"
        alt="SoloStar Logo"
      />
      <div className="opacity-50">
        <AreaChart
          data={chartdata}
          categories={["SemiAnalysis"]}
          colors={["blue"]}
          dataKey="date"
          showLegend={false}
          showXAxis={false}
          showYAxis={false}
          showTooltip={false}
          height="h-48"
        />
      </div>
    </div>
  );
};

export default FakeStockChart;
