import React from "react";
import cn from "classnames";
import { LinkProps } from "react-router-dom";

type Tab = {
  displayName: string;
  path: string;
  active: boolean;
  count?: number;
};

type TabsProps = {
  tabs: Tab[];
  linkComponent?: React.FC<LinkProps>;
};

const getLinkClassName = (active?: boolean) =>
  cn({
    "border-indigo-500 text-indigo-600": active,
    "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200": !active,
    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm": true,
  });

const Tabs: React.FC<TabsProps> = (props) => {
  const { tabs, linkComponent: Link } = props;
  const activeTab = tabs.find((tab) => tab.active) || tabs[0];
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={activeTab.displayName}
        >
          {tabs.map((tab) => (
            <option key={tab.path}>{tab.displayName}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => {
              const content = (
                <React.Fragment>
                  {tab.displayName}
                  {tab.count ? (
                    <span
                      className={cn({
                        "bg-indigo-100 text-indigo-600": tab.active,
                        "bg-gray-100 text-gray-900": !tab.active,
                        "hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block": true,
                      })}
                    >
                      {tab.count}
                    </span>
                  ) : null}
                </React.Fragment>
              );
              const className = getLinkClassName(tab.active);
              return Link ? (
                <Link
                  key={tab.path}
                  to={tab.path}
                  className={className}
                  aria-current={tab.active ? "page" : undefined}
                >
                  {content}
                </Link>
              ) : (
                <a
                  key={tab.path}
                  href={tab.path}
                  className={className}
                  aria-current={tab.active ? "page" : undefined}
                >
                  {content}
                </a>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
