import React, { useMemo, useState } from "react";
import Sidesheet from "pages/Options/CompaniesSidesheet";
import Modal from "components/Modal";
import EmptyState from "components/EmptyState";

import { array } from "solostar-lib";
import { Company, Scope } from "solostar-graphql";
import { useMatchPath } from "utils/hooks";
import { Link } from "react-router-dom";
import { Button, Header, Table, Badge, Tabs } from "solostar-components";
import {
  PlusCircleIcon,
  TrashIcon,
  BriefcaseIcon,
} from "@heroicons/react/solid";

import {
  useCompanies,
  useDeleteCompany,
  useDeleteAllCompanies,
} from "solostar-graphql";

const { sortByIdAndScope } = array;

const TABS = [
  { displayName: "My Companies", path: "", scope: Scope.USER },
  { displayName: "Team Companies", path: "team", scope: Scope.TENANT },
];

const Companies: React.FC = () => {
  const [deleteAllModalOpen, setDeleteAllModalOpen] = useState(false);
  const [deleteCompany] = useDeleteCompany();
  const [deleteAllCompanies] = useDeleteAllCompanies();
  const [sidesheetOpen, setSidesheetOpen] = useState(false);
  const [editingCompany, setEditingCompany] = useState<undefined | Company>();
  const [loadingDeleteAll, setLoadingDeleteAll] = useState(false);

  const { data: companies, loading, error } = useCompanies();

  const activePath = useMatchPath(
    TABS.map((tab) => ({
      toReturn: tab.path,
      toMatch: `options/companies/${tab.path}`,
    }))
  );

  const activeTab = TABS.find((tab) => tab.path === activePath) || TABS[0];

  const organized = useMemo(() => {
    const sorted = [...(companies || [])].sort(sortByIdAndScope);
    return {
      [Scope.TENANT]: sorted.filter((obj) => obj.scope === Scope.TENANT),
      [Scope.USER]: sorted.filter((obj) => obj.scope === Scope.USER),
      [Scope.APP]: [],
    };
  }, [companies]);

  if (loading) {
    return null;
  }

  if (!companies || error) {
    return <p>{error?.message}</p>;
  }

  const hasItems = !!organized[activeTab.scope].length;
  const addItem = (
    <Button
      key="add"
      icon={PlusCircleIcon}
      onClick={() => {
        setEditingCompany(undefined);
        setSidesheetOpen(true);
      }}
    >
      New Company
    </Button>
  );

  return (
    <React.Fragment>
      <Modal
        open={deleteAllModalOpen}
        setOpen={setDeleteAllModalOpen}
        title="Delete All Companies?"
        description="This will delete all the custom companies you've added to SoloStar. This action is not reversible."
        buttons={[
          <Button type="secondary" onClick={() => setDeleteAllModalOpen(false)}>
            Cancel
          </Button>,
          <Button
            status="error"
            loading={loadingDeleteAll}
            onClick={async () => {
              setLoadingDeleteAll(true);
              await deleteAllCompanies();
              setLoadingDeleteAll(false);
              setDeleteAllModalOpen(false);
            }}
          >
            Delete All Companies
          </Button>,
        ]}
      />
      <Sidesheet
        isOpen={sidesheetOpen}
        onRequestClose={() => setSidesheetOpen(false)}
        company={editingCompany}
      />
      <div className="mb-8">
        <Header
          title="Companies"
          buttons={[
            <Button
              key="deleteAll"
              status="error"
              icon={TrashIcon}
              onClick={() => setDeleteAllModalOpen(true)}
            >
              Delete All
            </Button>,
            addItem,
          ]}
        />
      </div>
      <Tabs
        linkComponent={Link}
        tabs={TABS.map((tab) => ({
          ...tab,
          count: organized[tab.scope]?.length,
          active: activeTab.scope === tab.scope,
        }))}
      />
      {hasItems ? (
        <Table
          columns={[
            {
              id: 0,
              displayName: "Name",
              accessor: (company) => company.displayName,
            },
            {
              id: 1,
              displayName: "Selectivity",
              accessor: (company) =>
                company.selectivity ? (
                  <Badge
                    color={company.selectivity.color}
                    key={company.selectivity.id}
                  >
                    {company.selectivity.displayName}
                  </Badge>
                ) : (
                  "None"
                ),
            },
            {
              id: 2,
              displayName: "Tags",
              accessor: (company) =>
                company.tags?.length ? (
                  <div>
                    {company.tags?.map((tag) => (
                      <Badge color={tag.color} key={tag.id}>
                        {tag.displayName}
                      </Badge>
                    ))}
                  </div>
                ) : (
                  "None"
                ),
            },
            {
              id: 3,
              displayName: "",
              accessor: (company) => (
                <div className="text-right">
                  <Button
                    type="text"
                    status="error"
                    onClick={() =>
                      deleteCompany({
                        variables: { input: { id: company.id } },
                      })
                    }
                  >
                    Delete
                  </Button>
                  <Button
                    type="text"
                    onClick={() => {
                      setEditingCompany(company);
                      setSidesheetOpen(true);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              ),
            },
          ]}
          data={organized[activeTab.scope]}
        />
      ) : (
        <div className="mt-8 center">
          <EmptyState
            title="No Companies"
            description="Add an institution here to show custom tags and selectivity when you're viewing results on LinkedIn Recruiter."
            buttons={[addItem]}
            icon={BriefcaseIcon}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Companies;
