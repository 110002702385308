import { gql, useMutation } from "@apollo/client";
import { AddSearchGroup } from "../__generated__/AddSearchGroup";
import { client } from "../apollo";
import { SEARCH_GROUP_FRAGMENT } from "../fragments/searchGroup";
import { SearchFieldData, SearchFieldType } from "../types";
import { AddSearchGroupInput } from "../__generated__/globalTypes";

type AddSearchGroupVariables = {
  input: AddSearchGroupInput & {
    fields?: Array<{ type: SearchFieldType; data: SearchFieldData }>;
  };
};

export const ADD_SEARCH_GROUP = gql`
  ${SEARCH_GROUP_FRAGMENT}
  mutation AddSearchGroup($input: AddSearchGroupInput!) {
    addSearchGroup(input: $input) {
      ...SearchGroupFragment
    }
  }
`;

export const useAddSearchGroup = (variables?: AddSearchGroupVariables) =>
  useMutation<AddSearchGroup, AddSearchGroupVariables>(ADD_SEARCH_GROUP, {
    variables,
    refetchQueries: ["GetSearchIdeas"],
    awaitRefetchQueries: true,
  });

export const addSearchGroup = (variables?: AddSearchGroupVariables) =>
  client.mutate<AddSearchGroup, AddSearchGroupVariables>({
    mutation: ADD_SEARCH_GROUP,
    variables,
  });
