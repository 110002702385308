import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EventName } from "solostar-graphql";
import { useEventListener } from "./hooks";
import { SIDEBAR_TIMELINE_ID } from "pages/Sidebar/SidebarTimeline";
import { Context } from "App";
import { analytics } from "./analytics";

export const useNavigationHook = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { setCandidate, setActiveItemId } = useContext(Context);

  /** Analytics event every time pathname changes. */
  useEffect(() => {
    analytics.page(pathname);
  }, [pathname]);

  useEventListener({
    listenName: EventName.SS_INIT_SIDEBAR,
    listenHandler: (msg) => {
      if (!msg.data) {
        setCandidate?.(undefined);
        navigate(`/`);
        return;
      }
      if (msg.data.competitorsQuery) {
        navigate(`/ai/companies/${msg.data.competitorsQuery}`);
        setCandidate?.(undefined);
        return;
      }
      if (msg.data.candidateAi) {
        navigate(`/ai/candidates/${msg.data.candidateAi}`);
        setCandidate?.(undefined);
        return;
      }
      if (msg.data.institutionAi) {
        navigate(`/ai/institutions/${msg.data.institutionAi}`);
        setCandidate?.(undefined);
        return;
      }
      if (msg.data.companyAi) {
        navigate(`/ai/companies/${msg.data.companyAi}`);
        setCandidate?.(undefined);
        return;
      }
      if (msg.data.candidate) {
        setCandidate(msg.data?.candidate);
        setActiveItemId(msg.data?.activeItemId);
        navigate(`/`);
        if (msg.data?.activeItemId && !msg.data?.skipScrollTo) {
          /** Scroll this item in the timeline into view. */
          const el = document.getElementById(msg.data.activeItemId);
          const container = document.getElementById(SIDEBAR_TIMELINE_ID);
          if (el && container) {
            const y = el.getBoundingClientRect().top + window.pageYOffset - 200;
            container.scrollTo({ top: y, behavior: "smooth" });
          }
        }
        return;
      }
      setCandidate(undefined);
    },
  });
};
