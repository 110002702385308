import Loader from "components/Loader";
import React from "react";
import SidebarHeader from "../../Sidebar/SidebarHeader";

import { Link, useParams } from "react-router-dom";
import { useCandidate, useCandidateInterestingNotes } from "solostar-graphql";
import { isExtensionOrPopup } from "utils/env";
import { useFakeLoading } from "pages/Sidebar/CompanyCompetitors";
import { useNavigationHook } from "utils/navigationHook";
import { Button } from "solostar-components";
import { EmptyStateGoBack } from "components/AIGoBackHeader";

export const InterestingNotes: React.FC<{
  candidateId?: string;
}> = (props) => {
  const { candidateId } = props;
  useNavigationHook();
  const fakeLoading = useFakeLoading();

  const { data: interestingNotes, loading: l1 } = useCandidateInterestingNotes(
    { id: candidateId ?? "" },
    { skip: !candidateId }
  );

  const { data: candidate, loading: l2 } = useCandidate(
    { id: candidateId ?? "" },
    { skip: !candidateId }
  );

  if (l1 || l2 || fakeLoading) {
    return (
      <div>
        {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
        <div className="w-screen h-screen flex flex-col items-center justify-center">
          <Loader />
          <div className="mb-2 mt-8 animate-pulse flex items-center">
            <img
              className="w-8 mr-1 object-contain animate-pulse"
              src="/logo192.png"
              alt="SoloStar Logo"
            />
            <span className="text-xl font-medium">SoloStar</span>
          </div>
          <p className="p-8 text-center">
            SoloStar AI is generating some interesting notes about{" "}
            {candidate?.name ?? "this candidate"}. 🤔
          </p>
        </div>
      </div>
    );
  }

  if (!interestingNotes || !candidate) {
    return (
      <EmptyStateGoBack
        id={candidateId}
        type="candidates"
        title="Interesting Notes Not Found"
        description={`Sorry, we could not generate any interesting notes for ${
          candidate?.name ?? "this candidate"
        }.`}
      />
    );
  }

  return (
    <div className="">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}

      <div className="p-8">
        <Button className="mb-6" type="secondary" size="small">
          <Link to={`/ai/candidates/${candidateId}`}>Go Back</Link>
        </Button>

        {candidate.externalUrl && (
          <a
            href={candidate.externalUrl}
            className="text-sm underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h1 className="text-xl mb-4">{candidate.name}</h1>
          </a>
        )}

        <p className="">{interestingNotes.description}</p>

        <ol className="mt-4 flex flex-col gap-y-6">
          {interestingNotes.interestingNotes?.map((acc) => (
            <li key={acc.title} className="">
              <p>
                <b>{acc.title}</b>
              </p>
              <p>{acc.whyImportant}</p>
              <ul className="list-disc">
                {acc.reasons?.map((reason) => (
                  <li key={reason} className="ml-4">
                    {reason}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

const CandidateInterestingNotes: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  return <InterestingNotes candidateId={id} />;
};

export default CandidateInterestingNotes;
