import { gql } from "@apollo/client";
import {
  AddRecruiterSearch,
  AddRecruiterSearchVariables,
} from "../__generated__/AddRecruiterSearch";
import { client } from "../apollo";

export const ADD_RECRUITER_SEARCH = gql`
  mutation AddRecruiterSearch($input: AddRecruiterSearchInput!) {
    addRecruiterSearch(input: $input) {
      id
      url
      requestId
      historyId
      contextId
      metadata {
        searchHistoryId
        count
        start
        searchRequestId
        searchContextId
        total
        pageLimit
        highlightTerms
        formattedTotal
        searchFields {
          keywords
          facetContainers {
            type
            values {
              count
              displayValue
              formattedCount
              negated
              required
              selected
              value
            }
          }
          companies {
            entity
            negated
            required
            text
          }
          companyScope
          firstNames
          lastNames
          titles {
            entity
            negated
            required
            text
          }
          titleScope
        }
      }
    }
  }
`;

export const addRecruiterSearch = (variables: AddRecruiterSearchVariables) =>
  client.mutate<AddRecruiterSearch, AddRecruiterSearchVariables>({
    mutation: ADD_RECRUITER_SEARCH,
    variables,
  });

export {
  AddRecruiterSearch,
  AddRecruiterSearchVariables,
} from "../__generated__/AddRecruiterSearch";
