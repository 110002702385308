import React, { Dispatch, SetStateAction, useCallback } from "react";
import cn from "classnames";

import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/solid";

type RadioGroupBubbleDisplayName = {
  title: string;
  description: string;
};

type RadioGroupBubbleDisplayProps = RadioGroupBubbleDisplayName & {
  checked: boolean;
  active: boolean;
};

export type RadioGroupOption<T> = {
  id: T;
  displayName: string | JSX.Element | RadioGroupBubbleDisplayName;
};

type RadioGroupProps<T = string | number> = {
  options: RadioGroupOption<T>[];
  value: T;
  onChange: Dispatch<SetStateAction<T>>;
};

const RadioGroupBubbleDisplay: React.FC<RadioGroupBubbleDisplayProps> = (
  props
) => {
  const { title, description, checked, active } = props;

  return (
    <React.Fragment>
      <div className="flex-1 flex">
        <div className="flex flex-col">
          <RadioGroup.Label
            as="span"
            className="block text-sm font-medium text-gray-900"
          >
            {title}
          </RadioGroup.Label>
          <RadioGroup.Description
            as="span"
            className="mt-1 flex items-center text-sm text-gray-500"
          >
            {description}
          </RadioGroup.Description>
        </div>
      </div>
      <CheckCircleIcon
        className={cn(!checked ? "invisible" : "", "h-5 w-5 text-indigo-600")}
        aria-hidden="true"
      />
      <div
        className={cn(
          active ? "border" : "border-2",
          checked ? "border-indigo-500" : "border-transparent",
          "absolute -inset-px rounded-lg pointer-events-none"
        )}
        aria-hidden="true"
      />
    </React.Fragment>
  );
};

const RadioGroupDefault = <T extends {}>(props: RadioGroupProps<T>) => {
  const { options, value, onChange } = props;

  const getDisplay = useCallback(
    (option: RadioGroupOption<T>, active: boolean, checked: boolean) => {
      const value = option.displayName;
      if (typeof value === "string") {
        return option.displayName;
      }
      if ("title" in value) {
        return (
          <RadioGroupBubbleDisplay
            title={value.title}
            description={value.description}
            active={active}
            checked={checked}
          />
        );
      }
    },
    []
  );

  return (
    <RadioGroup value={value} onChange={onChange}>
      <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        {options.map((option, idx) => (
          <RadioGroup.Option
            key={typeof option.id === "string" ? option.id : idx}
            value={option.id}
            className={({ checked, active }) =>
              cn(
                checked ? "border-transparent" : "border-gray-300",
                active ? "ring-2 ring-indigo-500" : "",
                "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
              )
            }
          >
            {({ active, checked }) => getDisplay(option, active, checked)}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default RadioGroupDefault;
