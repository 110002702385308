import SidebarHeader from "pages/Sidebar/SidebarHeader";
import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Candidate,
  ContactInfoType,
  useCandidate,
  useMe,
} from "solostar-graphql";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { isExtensionOrPopup } from "utils/env";
import { Menu } from "@headlessui/react";
import { useNavigationHook } from "utils/navigationHook";

const LINKS_DONT_COUNT = [ContactInfoType.LINK_LINKED_IN_PROFILE];

const getOptions = (candidate?: Candidate) => {
  const base = [
    {
      displayName: "Achievements",
      emoji: "🏆",
      description:
        "View an AI-generated summary of this candidate's strengths and their past achievements to help you craft the perfect outreach e-mail.",
      role: "AI ✨",
      href: "summary",
    },
  ];
  const contacts = [
    ...(candidate?.person?.contactInfos ?? []),
    ...(candidate?.contactInfos ?? []),
  ];
  const linksLength = contacts.filter(
    (c) => c.type?.startsWith("LINK") && !LINKS_DONT_COUNT.includes(c.type)
  ).length;
  if (linksLength) {
    base.push({
      displayName: "Notes & Tidbits",
      emoji: "💌",
      description:
        "SoloStar AI can scour this candidate's public profiles and pull out a few interesting tidbits about their work and their personality.",
      role: "AI ✨",
      href: "notes",
    });
  }
  return base;
};

export const Options: React.FC<{
  id?: string;
}> = (props) => {
  const { id } = props;
  useNavigationHook();

  const { data: me } = useMe();
  const { data: candidate } = useCandidate({ id: id ?? "" }, { skip: !id });

  if (!candidate || !me) {
    return null;
  }
  const options = getOptions(candidate);

  return (
    <div className="">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
      <div className="bg-white px-4 py-5 sm:px-6 lg:px-8">
        <div className="flex space-x-3 items-center">
          {!!candidate.avatarUrl && (
            <div className="w-12 h-12 bg-gray-200">
              <img
                className="bg-cover"
                alt={candidate.name}
                src={candidate.avatarUrl}
              />
            </div>
          )}
          <div className="min-w-0 flex-1">
            <p className="font-semibold text-gray-900">{candidate.name}</p>
          </div>
          <div className="flex flex-shrink-0 self-center">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                  <span className="sr-only">Open options</span>
                </Menu.Button>
              </div>
            </Menu>
          </div>
        </div>
      </div>
      <ul className="divide-y divide-gray-100">
        {options.map((opt) => (
          <li
            key={opt.displayName}
            className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 lg:px-8"
          >
            <div className="flex min-w-0 gap-x-4">
              <div className="h-12 w-12 flex-none rounded-full text-2xl flex items-center justify-center bg-gray-100">
                {opt.emoji}
              </div>
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  <Link to={opt.href}>
                    <span className="absolute inset-x-0 -top-px bottom-0" />
                    {opt.displayName}
                  </Link>
                </p>
                <p className="text-sm flex leading-5 text-gray-500">
                  {opt.description}
                </p>
              </div>
            </div>
            <div className="flex shrink-0 items-center gap-x-4">
              <div className="hidden sm:flex sm:flex-col sm:items-end">
                <p className="text-sm leading-6 text-gray-900">{opt.role}</p>
              </div>
              <ChevronRightIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const CandidatesOptions: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  return <Options id={id} />;
};

export default CandidatesOptions;
