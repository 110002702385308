import React, { useMemo, useState } from "react";
import TagsSidesheet from "pages/Options/TagsSidesheet";
import TagTargetCell from "pages/Options/TagTarget";

import { useMatchPath } from "utils/hooks";
import { constants, array } from "solostar-lib";
import { Link } from "react-router-dom";

import {
  Tag,
  Scope,
  useTags,
  useUpdateTag,
  useDeleteTag,
} from "solostar-graphql";

import {
  Header,
  Toggle,
  Badge,
  Tabs,
  Table,
  Button,
} from "solostar-components";

const { DEFAULT_TAGS } = constants;
const { sortByIdAndScope, reduceByFirstMatch } = array;

const DEFAULT_TAG_IDS = Object.keys(DEFAULT_TAGS);
const TABS = [
  { displayName: "Default Tags", path: "", scope: Scope.APP },
  { displayName: "My Tags", path: "user", scope: Scope.USER },
  { displayName: "Team Tags", path: "team", scope: Scope.TENANT },
];

const Tags: React.FC = () => {
  const [sidesheetOpen, setSidesheetOpen] = useState(false);
  const [tagBeingEdited, setTagBeingEdited] = useState<undefined | Tag>();
  const [updateTag] = useUpdateTag();
  const [deleteTag] = useDeleteTag();

  const { data: tags, loading, error } = useTags();

  const activePath = useMatchPath(
    TABS.map((tab) => ({
      toReturn: tab.path,
      toMatch: `options/tags/${tab.path}`,
    }))
  );

  const activeTab = TABS.find((tab) => tab.path === activePath) || TABS[0];

  const organizedTags = useMemo(() => {
    const allTags = [...(tags || [])];
    const defaultTags = allTags
      .filter((tag) => DEFAULT_TAG_IDS.includes(tag.id))
      .sort(sortByIdAndScope)
      .reduce(reduceByFirstMatch, [] as Tag[]);
    return {
      [Scope.APP]: defaultTags,
      [Scope.TENANT]: allTags.filter(
        (tag) => !DEFAULT_TAG_IDS.includes(tag.id) && tag.scope === Scope.TENANT
      ),
      [Scope.USER]: allTags.filter(
        (tag) => !DEFAULT_TAG_IDS.includes(tag.id) && tag.scope === Scope.USER
      ),
    } as Record<string, Tag[]>;
  }, [tags]);

  if (loading) {
    return null;
  }

  if (!tags || error) {
    return <p>{error?.message}</p>;
  }

  return (
    <React.Fragment>
      <TagsSidesheet
        isOpen={sidesheetOpen}
        onRequestClose={() => setSidesheetOpen(false)}
        tag={tagBeingEdited}
      />
      <div className="mb-8">
        <Header
          title="Tags"
          buttons={
            [
              // <Button
              //   key="add"
              //   icon={PlusCircleIcon}
              //   onClick={() => { setTagBeingEdited(undefined); setSidesheetOpen(true)}}
              // >
              //   New Tag
              // </Button>,
            ]
          }
        />
      </div>
      <Tabs
        linkComponent={Link}
        tabs={TABS.map((tab) => ({
          ...tab,
          count: organizedTags[tab.scope]?.length,
          active: activePath === tab.path,
        }))}
      />
      <Table
        columns={[
          {
            id: 0,
            displayName: "Target",
            accessor: (tag) => <TagTargetCell target={tag.target} />,
          },
          {
            id: 1,
            displayName: "Name",
            accessor: (tag) => {
              if (activeTab.scope === Scope.APP) {
                const original = DEFAULT_TAGS[tag.id];
                if (original.displayName !== tag.displayName) {
                  return (
                    <p className="text-sm text-gray-900">
                      {DEFAULT_TAGS[tag.id]?.displayName}{" "}
                      <span className="text-gray-500">
                        (renamed to <b>{tag.displayName}</b>)
                      </span>
                    </p>
                  );
                }
              }
              return tag.displayName;
            },
          },
          {
            id: 2,
            displayName: "Color",
            accessor: (tag) => <Badge color={tag.color}>{tag.color}</Badge>,
          },
          {
            id: 3,
            displayName: "Visible",
            accessor: (tag) => (
              <Toggle
                enabled={!tag.hidden}
                setEnabled={(visible) => {
                  updateTag({
                    variables: {
                      input: {
                        id: tag.id,
                        scope: tag.scope || Scope.APP,
                        tag: { hidden: !visible },
                      },
                    },
                  });
                }}
              />
            ),
          },
          {
            id: 4,
            displayName: "",
            accessor: (tag) => (
              <div className="text-right">
                {tag.scope !== Scope.APP && (
                  <Button
                    type="text"
                    status="error"
                    onClick={() =>
                      deleteTag({
                        variables: { input: { id: tag.id } },
                      })
                    }
                  >
                    Delete
                  </Button>
                )}
                <Button
                  type="text"
                  onClick={() => {
                    setTagBeingEdited(tag);
                    setSidesheetOpen(true);
                  }}
                >
                  Edit
                </Button>
              </div>
            ),
          },
        ]}
        data={organizedTags[activeTab.scope]}
      />
    </React.Fragment>
  );
};

export default Tags;
