import { useEffect, useMemo } from "react";
import { events, Event } from "solostar-lib";
import { EventName } from "solostar-graphql";
import { matchPath, useLocation } from "react-router-dom";

export const useEventListener = <
  EmitName extends EventName,
  ListenName extends EventName
>(options: {
  emit?: Extract<Event, { name: EmitName }>;
  listenName: ListenName;
  listenHandler: (data: Extract<Event, { name: ListenName }>) => void;
}) => {
  const { emit, listenName, listenHandler } = options;

  const evtHandler = useMemo(() => {
    if (!listenHandler || !listenName) {
      return null;
    }
    return (evt: MessageEvent) => {
      const msg = events.parse<ListenName>(evt.data);
      if (msg?.name === listenName) {
        listenHandler(msg);
      }
    };
  }, [listenHandler, listenName]);

  useEffect(() => {
    if (emit) {
      events.emit(emit, "top");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (evtHandler) {
      window.addEventListener("message", evtHandler);
      return () => window.removeEventListener("message", evtHandler);
    }
  }, [evtHandler]);
};

useEventListener.Events = EventName;

export const useMatchPath = (
  paths: (string | { toMatch: string; toReturn: string })[]
) => {
  const { pathname } = useLocation();
  return useMemo(() => {
    const toReturn = paths.find(
      (path) =>
        !!matchPath(typeof path === "string" ? path : path.toMatch, pathname)
    );
    if (!toReturn) {
      return null;
    }
    return typeof toReturn === "string" ? toReturn : toReturn.toReturn;
  }, [paths, pathname]);
};
