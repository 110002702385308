import Loader from "components/Loader";
import React from "react";
import { useParams } from "react-router-dom";
import { useInstitution, useInstitutionSummary } from "solostar-graphql";
import { isExtensionOrPopup } from "utils/env";
import SidebarHeader from "../../Sidebar/SidebarHeader";
import { useNavigationHook } from "utils/navigationHook";
import GoBackButton, { EmptyStateGoBack } from "components/AIGoBackHeader";
import { useFakeLoading } from "pages/Sidebar/CompanyCompetitors";

export const useInstitutionWithSummary = (id?: string) => {
  const { data: institution, loading: l1 } = useInstitution(
    { id: id ?? "" },
    { skip: !id }
  );

  const { data: summary, loading: l2 } = useInstitutionSummary(
    { id: id ?? "" },
    { skip: !id || (id && l1) || !!institution?.summary?.description }
  );

  return {
    loading: l1 || l2,
    institution: {
      ...(institution ?? {}),
      summary: institution?.summary || summary,
    },
  };
};

export const Summary: React.FC<{
  id?: string;
  onSaveQuery?: () => void;
}> = (props) => {
  const { id } = props;
  useNavigationHook();
  const fakeLoading = useFakeLoading();

  const { institution, loading } = useInstitutionWithSummary(id);

  const data = institution?.summary;

  if (loading || fakeLoading) {
    return (
      <div>
        {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
        <div className="w-screen h-screen flex flex-col items-center justify-center">
          <Loader />
          <div className="mb-2 mt-8 animate-pulse flex items-center">
            <img
              className="w-8 mr-1 object-contain animate-pulse"
              src="/logo192.png"
              alt="SoloStar Logo"
            />
            <span className="text-xl font-medium">SoloStar</span>
          </div>
          <p className="p-8 text-center">
            SoloStar AI is generating a summary of this institution for you. 🤔
          </p>
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <EmptyStateGoBack
        type="institutions"
        id={id ?? ""}
        title="Couldn't Generate Summary"
        description="Sorry, we couldn't generate a summary for this institution."
      />
    );
  }

  return (
    <div className="">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}

      <div className="p-8">
        <GoBackButton type="institutions" id={id} />

        {!!institution?.url && (
          <img
            alt="clearbit logo"
            src={`https://logo.clearbit.com/${institution.url}`}
            className="w-24 h-24 bg-gray-100 mb-4 border border-gray-300 object-contain"
          />
        )}

        {!!institution?.url && (
          <a
            href={institution.url}
            className="text-sm underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h1 className="text-xl mb-4">{institution?.displayName}</h1>
          </a>
        )}

        <p className="">{data.description}</p>

        <div className="mt-8 text-xs">
          <a href="https://clearbit.com">Logo provided by Clearbit</a>
        </div>
      </div>
    </div>
  );
};

const InstitutionSummary: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  return <Summary id={id} />;
};

export default InstitutionSummary;
