import React, { useMemo, useState, createContext, useEffect } from "react";
import "@tremor/react/dist/esm/tremor.css";

import Login from "pages/Login";
import Sidebar from "pages/Sidebar/Sidebar";
import Popup from "pages/Popup";
import Options from "pages/Options/Options";
import CompanyCompetitors from "pages/Sidebar/CompanyCompetitors";
import SubscriptionExpired from "components/SubscriptionExpired";
import CompaniesOptions from "pages/AI/Companies/CompaniesOptions";
import CompanySummary from "pages/AI/Companies/CompanySummary";
import CompanyFeeders from "pages/Sidebar/CompanyFeeders";
import CompanyStockComparison from "pages/Sidebar/CompanyStockComparison";

import CandidatesOptions from "pages/AI/Candidate/CandidateOptions";
import CandidateSummary from "pages/AI/Candidate/CandidateSummary";
import CompanyArticles from "pages/AI/Companies/CompanyNews";
import CompanyLayoffs from "pages/AI/Companies/CompanyLayoffs";
import InstitutionsOptions from "pages/AI/Companies/InsitutionOptions";
import InstitutionSummary from "pages/AI/Companies/InstitutionSummary";
import InstitutionTopPrograms from "pages/AI/Companies/InstitutionPrograms";
import InstitutionTopResearchLabs from "pages/AI/Companies/InstitutionResearch";
import SimilarInstitutions from "pages/AI/Companies/InsitutionSimilar";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Candidate, EventName } from "solostar-graphql";
import { events } from "solostar-lib";
import { useMe } from "solostar-graphql";
import { analytics } from "utils/analytics";
import CandidateInterestingNotes from "pages/AI/Candidate/CandidateInterestingNotes";
import GenerateSearchSuggestion from "pages/Sidebar/GenerateSearchSuggestion";

/**
 * Establish connection with the content script that's running in the browser
 * so that we can pass messages back and forth between the iframe version
 * of the sidebar.
 */
events.emit(
  { name: EventName.SS_ESTABLISH_TOP_WINDOW_CONNECTION, data: undefined },
  "top"
);

export const Context = createContext({
  candidate: undefined as undefined | Candidate,
  setCandidate: (_candidate: undefined | Candidate) => {},
  activeItemId: undefined as undefined | string,
  setActiveItemId: (_id: undefined | string) => {},
});

const App: React.FC = () => {
  const { data, loading } = useMe();

  const [candidate, setCandidate] = useState<undefined | Candidate>();
  const [activeItemId, setActiveItemId] = useState<undefined | string>();

  const isSubscriptionExpired = useMemo(
    () =>
      data?.subscription?.expires &&
      new Date(data.subscription.expires).getTime() < new Date().getTime(),
    [data]
  );

  /** Identity user to analytics. */
  useEffect(() => {
    if (data) {
      const { subscription, __typename, ...me } = data as any;
      analytics.identify(data.id, me);
    }
  }, [data]);

  /** Don't show anything until the user data has loaded. */
  if (loading) {
    return null;
  }

  /**
   * Once the user data has loaded, check if the subscription
   * has expired, and if so, show a message telling the user.
   */
  if (isSubscriptionExpired) {
    return <SubscriptionExpired />;
  }

  return (
    <Context.Provider
      value={{ candidate, activeItemId, setActiveItemId, setCandidate }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Sidebar />} />
          <Route path="login" element={<Login />} />
          <Route path="popup" element={<Popup />} />
          <Route path="options/*" element={<Options />} />
          <Route
            path="competitors/:companyId"
            element={<CompanyCompetitors />}
          />
          <Route path="ai/search" element={<GenerateSearchSuggestion />} />

          {/* Companies */}
          <Route
            path="ai/companies/:companyId"
            element={<CompaniesOptions />}
          />
          <Route
            path="ai/companies/:companyId/competitors"
            element={<CompanyCompetitors />}
          />
          <Route
            path="ai/companies/:companyId/summary"
            element={<CompanySummary />}
          />
          <Route
            path="ai/companies/:companyId/feeders"
            element={<CompanyFeeders />}
          />
          <Route
            path="ai/companies/:companyId/stocks"
            element={<CompanyStockComparison />}
          />
          <Route
            path="ai/companies/:companyId/articles"
            element={<CompanyArticles />}
          />
          <Route
            path="ai/companies/:companyId/layoffs"
            element={<CompanyLayoffs />}
          />

          {/* Candidates */}
          <Route path="ai/candidates/:id" element={<CandidatesOptions />} />
          <Route
            path="ai/candidates/:id/summary"
            element={<CandidateSummary />}
          />
          <Route
            path="ai/candidates/:id/notes"
            element={<CandidateInterestingNotes />}
          />

          {/* Institutions */}
          <Route path="ai/institutions/:id" element={<InstitutionsOptions />} />
          <Route
            path="ai/institutions/:id/summary"
            element={<InstitutionSummary />}
          />
          <Route
            path="ai/institutions/:id/programs"
            element={<InstitutionTopPrograms />}
          />
          <Route
            path="ai/institutions/:id/research"
            element={<InstitutionTopResearchLabs />}
          />
          <Route
            path="ai/institutions/:id/similar"
            element={<SimilarInstitutions />}
          />
        </Routes>
      </BrowserRouter>
    </Context.Provider>
  );
};

export default App;
