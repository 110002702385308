import { gql } from "@apollo/client";

export const SEARCH_GROUP_FRAGMENT = gql`
  fragment SearchGroupFragment on SearchGroup {
    id
    name
    scope
    category
    subcategory
    queries {
      query
      facetType
      removeIfPresent
      replaceQuery
    }
    fields {
      type
      replaceCurrent
      data {
        ... on SearchFieldEntity {
          scope
          key
          scopeKey
          entities {
            entity
            negated
            required
            text
          }
        }
        ... on SearchFieldSingleValue {
          key
          value
          union
        }
        ... on SearchFieldMultipleValue {
          key
          values
        }
        ... on SearchFieldFacet {
          type
          facets {
            count
            displayValue
            formattedCount
            negated
            required
            selected
            value
          }
        }
      }
    }
  }
`;
