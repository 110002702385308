import { gql } from "@apollo/client";
import { Logout } from "../__generated__/Logout";
import { client } from "../apollo";

export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export const logout = () => {
  return client.mutate<Logout>({
    mutation: LOGOUT,
    refetchQueries: ["GetMe"],
  });
};
