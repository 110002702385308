import { $ } from "./html";
import { emit } from "./events";
import { DEFAULT_SS_DECORATION_CLASS_NAME } from "../utils/constants";

import {
  Decoration,
  HTMLTag,
  HTMLElementIdentifierType,
} from "solostar-graphql";

export const decorateDOM = (
  configuration: Omit<Decoration, "forceUpdate"> & {
    forceUpdate?: boolean | null;
  },
  node?: HTMLElement,
  /**
   * If an element with the supplied `domId` already exists, this is a no-op, but this flag forces
   * the decorate function to remove the existing DOM element and add a new one per the supplied configuration.
   */
  forceRemove?: boolean
) => {
  const { html, onClickEvent, parentIdentifier } = configuration;
  const forceUpdate = forceRemove || configuration.forceUpdate;

  /**
   * If the item we want to add already exists in some form, remove it first and
   * then add it back with the new configuration.
   */
  if (html) {
    const item = $(
      {
        id: html.id,
        type: HTMLElementIdentifierType.ID,
        multiple: false,
      },
      node
    );
    if (item && forceUpdate) {
      item.remove();
    } else if (item) {
      return;
    }
  }

  /**
   * Find the parent that we're meant to manipulate. If it's not there, error out because
   * we need it to do anything.
   */
  const parent = $(parentIdentifier, node);
  if (!parent) {
    // console.warn(
    //   `Decoration's parent identifier did not match any DOM elements: ${JSON.stringify(
    //     configuration,
    //     null,
    //     2
    //   )}`
    // );
    return;
  }

  /**
   * Check that the decoration has either an onClickEvent or HTML (or both) supplied.
   * Otherwise, it is an invalid decoration.
   */
  if (!html && !onClickEvent) {
    // console.warn(
    //   `Decoration must have either an onClickEvent or HTML specified: ${JSON.stringify(
    //     configuration,
    //     null,
    //     2
    //   )}`
    // );
    return;
  }

  /** If we have a parent and HTML, add it to the DOM. */
  if (parent && html) {
    const { id, content, className, tag = HTMLTag.DIV } = html;

    const el = document.createElement(tag);
    el.id = id;
    el.innerHTML = content;
    el.className =
      DEFAULT_SS_DECORATION_CLASS_NAME + (className ? ` ${className}` : "");

    if (onClickEvent) {
      el.onclick = () => {
        onClickEvent.forEach((event) => emit(event));
      };
    }

    parent.appendChild(el);
    return;
  }

  /**
   * If we don't have HTML in the deocration, but we do have an onClickEvent, add the event
   * handler to the parent.
   */
  if (parent && onClickEvent) {
    parent.onclick = () => {
      onClickEvent.forEach((event) => emit(event));
    };
  }
};
