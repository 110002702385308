import EmptyState from "components/EmptyState";
import Loader from "components/Loader";
import React from "react";
import { useParams } from "react-router-dom";
import { useCompany, useCompanySummary } from "solostar-graphql";
import { isExtensionOrPopup } from "utils/env";
import SidebarHeader from "../../Sidebar/SidebarHeader";
import { getSearchId, useFakeLoading } from "pages/Sidebar/CompanyCompetitors";
import { useNavigationHook } from "utils/navigationHook";
import GoBackButton from "components/AIGoBackHeader";

export const Summary: React.FC<{
  companyId?: string;
  onSaveQuery?: () => void;
}> = (props) => {
  const { companyId } = props;
  useNavigationHook();
  const fakeLoading = useFakeLoading();

  const id = getSearchId(companyId);

  const { data: company, loading: l1 } = useCompany(
    { id: id?.id ?? "" },
    { skip: !id || id.type !== "lookup" }
  );

  const query = id?.type === "query" ? id.id : company?.displayName ?? "";

  const { data: summary, loading: l2 } = useCompanySummary(
    { query },
    {
      skip: !query || (id?.type !== "query" && (!id || !!company?.summary)),
    }
  );

  const data = company?.summary ?? summary;

  if (l1 || l2 || fakeLoading) {
    return (
      <div>
        {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
        <div className="w-screen h-screen flex flex-col items-center justify-center">
          <Loader />
          <div className="mb-2 mt-8 animate-pulse flex items-center">
            <img
              className="w-8 mr-1 object-contain animate-pulse"
              src="/logo192.png"
              alt="SoloStar Logo"
            />
            <span className="text-xl font-medium">SoloStar</span>
          </div>
          <p className="p-8 text-center">
            SoloStar AI is generating a summary of this company for you. 🤔
          </p>
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <GoBackButton id={companyId} />
        <EmptyState
          title="Couldn't Generate Summary"
          description="Sorry, we couldn't generate a summary for this company."
        />
      </div>
    );
  }

  return (
    <div className="">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}

      <div className="p-8">
        <GoBackButton id={companyId} />

        {data.website && (
          <img
            alt="clearbit logo"
            src={`https://logo.clearbit.com/${data.website}`}
            className="w-24 h-24 bg-gray-100 mb-4 border border-gray-300 object-contain"
          />
        )}
        {data.website && (
          <a
            href={data.website}
            className="text-sm underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h1 className="text-xl mb-4">{company?.displayName}</h1>
          </a>
        )}
        <p className="">{data.summary}</p>

        {data.why && (
          <div className="mt-4">
            <p>
              <b>Why you might want to work there</b>
            </p>
            <p>{data.why}</p>
          </div>
        )}

        <div className="mt-8 text-xs">
          <a href="https://clearbit.com">Logo provided by Clearbit</a>
        </div>
      </div>
    </div>
  );
};

const CompanySummary: React.FC = () => {
  const { companyId } = useParams<{ companyId?: string }>();
  return <Summary companyId={companyId} />;
};

export default CompanySummary;
