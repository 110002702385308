import { gql } from "@apollo/client";
import { TAG_FRAGMENT } from "./tag";

export const PERSON_FRAGMENT = gql`
  ${TAG_FRAGMENT}
  fragment PersonFragment on Person {
    id
    scope
    displayName
    contactInfos {
      type
      value
    }
    tags {
      ...TagFragment
    }
  }
`;
