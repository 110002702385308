import { gql, useMutation } from "@apollo/client";
import {
  DeleteCompany,
  DeleteCompanyVariables,
} from "../__generated__/DeleteCompany";
import { DeleteAllCompanies } from "../__generated__/DeleteAllCompanies";

export const DELETE_COMPANY = gql`
  mutation DeleteCompany($input: DeleteCompanyInput!) {
    deleteCompany(input: $input)
  }
`;

export const DELETE_ALL_COMPANIES = gql`
  mutation DeleteAllCompanies {
    deleteAllCompanies
  }
`;

export const useDeleteCompany = (variables?: DeleteCompanyVariables) =>
  useMutation<DeleteCompany, DeleteCompanyVariables>(DELETE_COMPANY, {
    variables,
    refetchQueries: ["GetCompanies"],
    awaitRefetchQueries: true,
  });

export const useDeleteAllCompanies = () =>
  useMutation<DeleteAllCompanies>(DELETE_ALL_COMPANIES, {
    refetchQueries: ["GetCompanies"],
    awaitRefetchQueries: true,
  });
