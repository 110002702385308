import React, { useEffect, useMemo } from "react";
import Login from "pages/Login";
import classNames from "classnames";

import { Scope, SubscriptionType, useMe } from "solostar-graphql";
import {
  PencilIcon,
  PaperAirplaneIcon,
  CheckCircleIcon,
} from "@heroicons/react/outline";
import { differenceInDays } from "date-fns";
import { capitalCase } from "change-case";
import { Button } from "solostar-components";
import { useLocation, useNavigate } from "react-router-dom";

import {
  useOnSubscribe,
  useManageBilling,
} from "components/SubscriptionExpired";

const Billing: React.FC = () => {
  const { search } = useLocation();
  const { loading: subloading, onSubscribe } = useOnSubscribe();
  const { loading: manageLoading, onManageBilling } = useManageBilling();
  const { data, error, loading, refetch } = useMe({
    fetchPolicy: "cache-and-network",
  });
  const navigate = useNavigate();

  const state = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get("state") ?? undefined;
  }, [search]);

  useEffect(() => {
    if (!!state) {
      refetch();
    }
  }, [state, refetch]);

  const daysRemaining = useMemo(() => {
    if (!data?.subscription?.expires) {
      return Infinity;
    }
    return differenceInDays(new Date(data.subscription.expires), new Date());
  }, [data]);

  const isTrial = useMemo(
    () =>
      data?.subscription?.type === SubscriptionType.FREE &&
      daysRemaining < Infinity,
    [data, daysRemaining]
  );

  const isExpiring = useMemo(
    () =>
      data?.subscription?.type !== SubscriptionType.FREE &&
      daysRemaining < Infinity,
    [data, daysRemaining]
  );

  const pill = useMemo(() => {
    if (isTrial) {
      return (
        <span
          className={classNames(
            "inline-flex flex-shrink-0 items-center rounded-full bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-500 ring-1 ring-inset ring-red-600/20"
          )}
        >
          {daysRemaining > 0 ? `${daysRemaining} days left` : "Trial Expired"}
        </span>
      );
    }

    if (isExpiring) {
      return (
        <span
          className={classNames(
            "inline-flex flex-shrink-0 items-center rounded-full bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-500 ring-1 ring-inset ring-red-600/20"
          )}
        >
          {daysRemaining > 0
            ? `Expires in ${daysRemaining} days`
            : "Susbcription Expired"}
        </span>
      );
    }
    return (
      <span
        className={classNames(
          "inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
        )}
      >
        Active
      </span>
    );
  }, [isTrial, isExpiring, daysRemaining]);

  const isTenantPlan = useMemo(
    () => data?.subscription?.scope === Scope.TENANT,
    [data]
  );

  /** If user is not logged in, show the Login component. */
  if (!loading && error) {
    return (
      <div>
        <Login />
      </div>
    );
  }

  if (!data) {
    /** TO DO: Replace with a <Loader /> */
    return null;
  }

  return (
    <div>
      {state === "success" && (
        <div className="rounded-md bg-green-50 p-4 mb-8 border border-green-300">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                Subscription Activated!
              </h3>
              <div className="mt-2 text-sm text-green-700">
                <p>
                  You can now enjoy the full benefits of SoloStar while
                  sourcing. Thank you!
                </p>
              </div>
              <div className="mt-4">
                <div className="-mx-2 -my-1.5 flex">
                  <button
                    type="button"
                    onClick={() => navigate({ search: "" })}
                    className="ml-3 rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <img
              className="h-16 w-16 rounded-full"
              src={data.photoUrl || undefined}
              alt={data.email || undefined}
            />
            <span
              className="absolute inset-0 shadow-inner rounded-full"
              aria-hidden="true"
            />
          </div>
        </div>
        <div>
          <h1 className="text-xl font-bold text-gray-900">
            {data.displayName}
          </h1>
          <p className="text-sm font-medium text-gray-500">
            <span className="text-gray-900">{data.email}</span>, member of the{" "}
            <span className="text-gray-900">{data.tenant?.displayName}</span>{" "}
            team.
          </p>
        </div>
      </div>
      {data?.subscription && (
        <div className="mt-8 max-w-[32rem] col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
          <div className="flex w-full items-center justify-between space-x-2 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-2">
                <h3 className="truncate text-sm font-medium text-gray-900">
                  {data?.subscription?.type === SubscriptionType.FREE
                    ? "Trial"
                    : capitalCase(data.subscription.type)}
                </h3>
                {pill}
              </div>
              <p className="mt-1 truncate text-sm text-gray-500">
                {isTenantPlan
                  ? `via ${data?.tenant?.displayName} Plan. Please contact us to change.`
                  : "Personal Plan"}
              </p>
            </div>
          </div>
          <div>
            <div className="flex divide-x divide-gray-200 overflow-hidden">
              <div className="flex w-0 flex-1">
                <Button
                  onClick={isTrial ? onSubscribe : onManageBilling}
                  loading={isTrial ? subloading : manageLoading}
                  disabled={isTenantPlan}
                  type="secondary"
                  icon={isTrial ? PaperAirplaneIcon : PencilIcon}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-none rounded-bl-lg py-4 text-sm font-semibold text-gray-900 border-none bg-transparent"
                >
                  {isTrial ? "Subscribe to Premium" : "Manage"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Billing;
