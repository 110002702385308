import { HTMLElementIdentifier } from "solostar-graphql";

export const $ = (identifier: HTMLElementIdentifier, node?: HTMLElement) => {
  switch (identifier.type) {
    case "ID":
      return document.getElementById(identifier.id) as HTMLElement;
    case "CLASS":
      return (node || document).getElementsByClassName(
        identifier.id
      )[0] as HTMLElement;
    case "TAG":
      return (node || document).getElementsByTagName(
        identifier.id
      )[0] as HTMLElement;
    default:
      return (node || document).querySelector(identifier.id) as HTMLElement;
  }
};

export const $$ = (
  identifier: HTMLElementIdentifier,
  node?: HTMLElement
): HTMLElement[] => {
  switch (identifier.type) {
    case "ID":
      return [document.getElementById(identifier.id)] as HTMLElement[];
    case "CLASS":
      return Array.from(
        (node || document).getElementsByClassName(identifier.id)
      ) as HTMLElement[];
    case "TAG":
      return Array.from(
        (node || document).getElementsByTagName(identifier.id)
      ) as HTMLElement[];
    default:
      return Array.from(
        (node || document).querySelectorAll(identifier.id)
      ) as HTMLElement[];
  }
};

export const getHTMLFromDOMNode = (node: Node | null) => {
  if (node instanceof HTMLElement) {
    return node.outerHTML;
  }
  return null;
};
