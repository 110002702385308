import { gql, useMutation } from "@apollo/client";
import {
  DeletePerson,
  DeletePersonVariables,
} from "../__generated__/DeletePerson";
import { DeleteAllPersons } from "../__generated__/DeleteAllPersons";

export const DELETE_PERSON = gql`
  mutation DeletePerson($input: DeletePersonInput!) {
    deletePerson(input: $input)
  }
`;

export const DELETE_ALL_PERSONS = gql`
  mutation DeleteAllPersons {
    deleteAllPersons
  }
`;

export const useDeletePerson = (variables?: DeletePersonVariables) =>
  useMutation<DeletePerson, DeletePersonVariables>(DELETE_PERSON, {
    variables,
    refetchQueries: ["GetPersons"],
    awaitRefetchQueries: true,
  });

export const useDeleteAllPersons = () =>
  useMutation<DeleteAllPersons>(DELETE_ALL_PERSONS, {
    refetchQueries: ["GetPersons"],
    awaitRefetchQueries: true,
  });
