import EmptyState from "components/EmptyState";
import Loader from "components/Loader";
import React from "react";
import { useParams } from "react-router-dom";
import { useCompanyArticles } from "solostar-graphql";
import { isExtensionOrPopup } from "utils/env";
import SidebarHeader from "../../Sidebar/SidebarHeader";
import { useNavigationHook } from "utils/navigationHook";
import { getSearchId, useFakeLoading } from "pages/Sidebar/CompanyCompetitors";
import GoBackButton from "components/AIGoBackHeader";

export const Articles: React.FC<{
  companyId?: string;
}> = (props) => {
  const { companyId } = props;
  useNavigationHook();
  const fakeLoading = useFakeLoading();

  const id = getSearchId(companyId);

  const { data: articles, loading } = useCompanyArticles(
    { id: id?.id ?? "" },
    { skip: !id || id.type !== "lookup" }
  );

  if (loading || fakeLoading) {
    return (
      <div>
        {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
        <div className="w-screen h-screen flex flex-col items-center justify-center">
          <Loader />
          <div className="mb-2 mt-8 animate-pulse flex items-center">
            <img
              className="w-8 mr-1 object-contain animate-pulse"
              src="/logo192.png"
              alt="SoloStar Logo"
            />
            <span className="text-xl font-medium">SoloStar</span>
          </div>
          <p className="p-8 text-center">
            SoloStar AI is finding articles related to this company for you. 🤔
          </p>
        </div>
      </div>
    );
  }

  if (!articles?.length) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <GoBackButton id={companyId} />
        <EmptyState
          title="No Articles"
          description="Sorry, we couldn't find any articles related to this company."
        />
      </div>
    );
  }

  return (
    <div className="">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}

      <div className="p-8">
        <GoBackButton id={companyId} />

        <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-x-4 lg:grid-cols-6">
          {articles
            .filter((a) => a.href && a.title && a.image)
            .map((a) => (
              <a
                key={a.title}
                target="_blank"
                href={a.href ?? ""}
                rel="noopener noreferrer"
              >
                <div className="relative">
                  {!!a.image && (
                    <div className="h-32 overflow-hidden w-full rounded-lg">
                      <img
                        src={a.image}
                        alt={a.title ?? "Article Image"}
                        className="h-full w-full object-cover object-center"
                      />
                    </div>
                  )}
                </div>
                <p className="relative mt-2 font-semibold">{a.title}</p>
                {!a.source && (
                  <h3 className="text-sm font-medium text-gray-900">
                    Source: {a.source}
                  </h3>
                )}
              </a>
            ))}
        </div>
      </div>
    </div>
  );
};

const CompanyArticles: React.FC = () => {
  const { companyId } = useParams<{ companyId?: string }>();
  return <Articles companyId={companyId} />;
};

export default CompanyArticles;
