import { get as getColor } from "colornames";
import { padZero } from "utils/numbers";

export const GRAY0 = "#F8F8F8";
export const BRAND = "#ba68c8";
export const BRAND_DARK = "#6850FF";
export const URM = BRAND;

export const getRgbaFromHex = (hex: string, alpha = 1) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    return null;
  }
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  return `rgba(${r},${g},${b},${alpha})`;
};

export const getHex6Digit = (oldHex: string) => {
  const oldColor = getColor(oldHex);
  if (oldColor?.value) {
    return oldColor?.value.slice(1);
  }
  const hex = oldHex.startsWith("#") ? oldHex.slice(1) : oldHex;
  if (hex.length === 3) {
    return hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  return hex;
};

export const getInvertedColor = (oldHex: string, bw: boolean = true) => {
  const hex = getHex6Digit(oldHex);
  const oldRgb = {
    r: parseInt(hex.slice(0, 2), 16),
    g: parseInt(hex.slice(2, 4), 16),
    b: parseInt(hex.slice(4, 6), 16),
  };
  if (bw) {
    const darkness = oldRgb.r * 0.299 + oldRgb.g * 0.587 + oldRgb.b * 0.114;
    return darkness > 186 ? "#000000" : "#FFFFFF";
  }
  /** Invert the color. */
  const newRgb = {
    r: (255 - oldRgb.r).toString(16),
    g: (255 - oldRgb.g).toString(16),
    b: (255 - oldRgb.b).toString(16),
  };
  /** Pad the color components. */
  return "#" + padZero(newRgb.r) + padZero(newRgb.g) + padZero(newRgb.b);
};
