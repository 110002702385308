import { gql, useQuery, QueryOptions, QueryHookOptions } from "@apollo/client";
import { GetMe } from "../__generated__/GetMe";
import { client } from "../apollo";

export const GET_ME = gql`
  query GetMe {
    getMe {
      id
      displayName
      email
      photoUrl
      tenant {
        id
        displayName
        stockSymbol
      }
      subscription {
        id
        scope
        type
        expires
      }
    }
  }
`;

export const useMe = (opts?: QueryHookOptions) => {
  const res = useQuery<GetMe>(GET_ME, opts);
  return { ...res, data: res.data?.getMe };
};

export const getMe = (options?: Partial<QueryOptions<GetMe>>) => {
  return client.query<GetMe>({
    ...options,
    query: GET_ME,
  });
};
