import Loader from "components/Loader";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "solostar-components";
import {
  SearchFieldType,
  useCompany,
  useCompanyCompetitors,
} from "solostar-graphql";
import SidebarEditKeywordsSidesheet from "./SidebarEditKeywordsSidesheet";
import { isExtensionOrPopup } from "utils/env";
import SidebarHeader from "./SidebarHeader";
import { useNavigationHook } from "utils/navigationHook";
import GoBackButton, { EmptyStateGoBack } from "components/AIGoBackHeader";

const SS_COMPANY_PREFIX = "SS_COMPANY:";

type CompanySearchCriteria = {
  type: "lookup" | "query";
  id: string;
};

export const getSearchId = (
  companyId?: string
): CompanySearchCriteria | undefined => {
  if (!companyId) {
    return undefined;
  }
  if (companyId.startsWith(SS_COMPANY_PREFIX)) {
    const id = companyId.split(SS_COMPANY_PREFIX).pop() ?? "";
    return { id, type: "lookup" };
  }
  return { id: companyId, type: "query" };
};

export const useFakeLoading = (timeout = 4000) => {
  const [fakeLoading, setFakeLoading] = useState(true);

  /** Fake loading for at least 4 seconds. */
  useEffect(() => {
    setFakeLoading(true);
    setTimeout(() => {
      setFakeLoading(false);
    }, timeout);
  }, [timeout]);

  return fakeLoading;
};

export const Competitors: React.FC<{
  companyId?: string;
}> = (props) => {
  const { companyId } = props;

  useNavigationHook();

  const [saveOpen, setSaveOpen] = useState(false);

  const id = getSearchId(companyId);
  const fakeLoading = useFakeLoading();
  const navigate = useNavigate();

  const { data: company, loading: l1 } = useCompany(
    { id: id?.id ?? "" },
    { skip: !id || id.type !== "lookup" }
  );

  const query = id?.type === "query" ? id.id : company?.displayName ?? "";

  const { data: competitors, loading: l2 } = useCompanyCompetitors(
    { query },
    {
      skip: !query || (id?.type !== "query" && (!id || !!company?.competitors)),
    }
  );

  const data = company?.competitors ?? competitors;

  if (l1 || l2 || fakeLoading) {
    return (
      <div>
        {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
        <div className="w-screen h-screen flex flex-col items-center justify-center">
          <Loader />
          <div className="mb-2 mt-8 animate-pulse flex items-center">
            <img
              className="w-8 mr-1 object-contain animate-pulse"
              src="/logo192.png"
              alt="SoloStar Logo"
            />
            <span className="text-xl font-medium">SoloStar</span>
          </div>
          <p className="p-8 text-center">
            SoloStar AI is thinking 🤔...we're going to find some great company
            matches similar to <b>{query}</b> that you can save into your
            search.
          </p>
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <EmptyStateGoBack
        id={companyId}
        title="Competitors Not Found"
        description="Sorry, we couldn't find any competitors for this Company."
      />
    );
  }

  return (
    <div className="">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
      <div className="p-8">
        <GoBackButton id={companyId} />
        <SidebarEditKeywordsSidesheet
          searchGroup={null}
          onFinishSave={() => navigate("/")}
          defaultValues={{
            id: "1",
            name: `Competitors of ${query}`,
            category: "Direct Competitors",
          }}
          defaultFields={[
            {
              type: SearchFieldType.ENTITY,
              replaceCurrent: false,
              data: {
                key: "companies",
                scopeKey: "companyScope",
                scope: "CURRENT_OR_PAST",
                entities:
                  data?.companies.map((c) => ({
                    required: false,
                    entity: null,
                    text: c.displayName,
                    negated: false,
                  })) ?? [],
              },
            },
          ]}
          isOpen={saveOpen}
          onRequestClose={() => setSaveOpen(false)}
        />
        <p className="">{data.description}</p>
        <ol className="flex flex-wrap gap-1 my-8">
          {data.companies.map((c) => (
            <li
              key={c.displayName}
              className="text-center p-1.5 w-full bg-gray-100 flex items-center gap-2 border border-gray-200 overflow-hidden"
            >
              {c.website && (
                <img
                  alt="clearbit logo"
                  src={`https://logo.clearbit.com/${c.website}`}
                  className="w-8 h-8 rounded-md object-contain"
                />
              )}
              {c.website ? (
                <a
                  href={c.website}
                  className="text-sm underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>{c.displayName}</b>
                </a>
              ) : (
                <span className="text-sm">
                  <b>{c.displayName}</b>
                </span>
              )}
            </li>
          ))}
        </ol>
        <Button onClick={() => setSaveOpen(true)}>Save as Search Query</Button>
        <div className="mt-8 text-xs">
          <a href="https://clearbit.com">Logos provided by Clearbit</a>
        </div>
      </div>
    </div>
  );
};

const CompanyCompetitors: React.FC = () => {
  const { companyId } = useParams<{ companyId?: string }>();
  return <Competitors companyId={companyId} />;
};

export default CompanyCompetitors;
