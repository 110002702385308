import React, { useState, useMemo, useEffect, useCallback } from "react";
import Creatable from "react-select/creatable";

import {
  Candidate,
  EventName,
  TagTarget,
  Scope,
  useAddPerson,
  useUpdatePerson,
} from "solostar-graphql";
import {
  tagToSelectOption,
  useDefaultTags,
  DEFAULT_TAG_COLOR,
  MultiSelectOption,
  getTagIds,
} from "utils/tags";
import { Badge, Button } from "solostar-components";
import { RefreshCandidateData, events } from "solostar-lib";

type Props = {
  candidate: Candidate;
};

const SidebarCandidateHeader: React.FC<Props> = (props) => {
  const { candidate } = props;
  const tags = useDefaultTags(TagTarget.PERSON);

  const [isEditingTags, setIsEditingTags] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addPerson] = useAddPerson();
  const [updatePerson] = useUpdatePerson();
  const [customTags, setCustomTags] = useState(
    (candidate.person?.tags || []).map((t) => tagToSelectOption(t))
  );

  const areTagsDirty = useMemo(() => {
    const oldTags = (candidate.person?.tags?.map((tag) => tag.id) || []).sort();
    const newTags = customTags.map((tag) => tag.value).sort();
    const tagsSame =
      oldTags.every((id, idx) => id === newTags[idx]) &&
      newTags.every((id, idx) => id === oldTags[idx]);

    return !tagsSame;
  }, [customTags, candidate]);

  useEffect(() => {
    if (isEditingTags) {
      setCustomTags(
        (candidate.person?.tags || []).map((t) => tagToSelectOption(t))
      );
    }
  }, [isEditingTags, candidate]);

  const onSave = useCallback(async () => {
    setLoading(true);
    const existing = candidate.person;

    /** Create any new tags the user might have added. */
    const tagIds = await getTagIds(customTags, Scope.USER, TagTarget.PERSON);

    if (existing) {
      await updatePerson({
        variables: {
          input: {
            id: existing.id,
            scope: existing.scope,
            person: { tagIds },
          },
        },
      });
    } else {
      await addPerson({
        variables: {
          input: {
            scope: Scope.USER,
            displayName: candidate.name,
            tagIds,
            externalId: candidate.externalId,
          },
        },
      });
    }

    if (candidate.domId) {
      const data: RefreshCandidateData = {
        domId: candidate.domId,
      };
      events.emit({ name: EventName.SS_REFRESH_CANDIDATE, data }, "top");
    }

    setIsEditingTags(false);
    setLoading(false);
  }, [customTags, candidate, addPerson, updatePerson]);

  return (
    <div className="bg-white px-4 py-5 w-full h-fit z-10 border-b border-gray-200 sm:px-6 flex justify-between items-center flex-wrap sm:flex-nowrap">
      <div className="flex flex-auto items-center">
        {candidate.avatarUrl && (
          <div className="flex-shrink-0 relative">
            <img
              className="h-12 w-12 rounded-full"
              src={candidate.avatarUrl}
              alt={candidate.name}
            />
          </div>
        )}
        <div className="ml-4 flex-auto">
          <div className="flex items-center">
            <h3 className="leading-tight text-lg font-medium text-gray-900">
              {candidate.name}
            </h3>
            <div className="flex ml-1">
              {candidate.person?.tags?.map((tag) => (
                <div key={tag.id} className="ml-1">
                  <Badge color={tag.color}>{tag.displayName}</Badge>
                </div>
              ))}
            </div>
            <div className="ml-1 flex-auto text-right">
              <Button
                className="px-0 py-0"
                type="text"
                onClick={() => setIsEditingTags(!isEditingTags)}
              >
                Edit
              </Button>
            </div>
          </div>
          <p
            className="text-sm text-gray-500 antialiased max-w-full truncate"
            style={{ maxWidth: "calc(100vw - 100px" }}
          >
            {candidate.headline}
          </p>
        </div>
      </div>
      {isEditingTags && (
        <div className="ss__history__suggestions mt-4 rounded-md flex-auto w-full bg-gray-100 p-4">
          <h4 className="text-xs font-medium mb-2">
            Tags for {candidate.name}
          </h4>
          <div className="">
            <Creatable
              isMulti={true}
              className="flex-auto mr-2"
              options={tags.custom.map((tag) => tagToSelectOption(tag))}
              value={customTags}
              onChange={(tags) => setCustomTags(tags as MultiSelectOption[])}
              filterOption={(item, inputValue) =>
                item.data.displayName
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
              getNewOptionData={(inputValue) => ({
                value: inputValue,
                label: <Badge color={DEFAULT_TAG_COLOR}>{inputValue}</Badge>,
                displayName: inputValue,
                isNew: true,
              })}
            />
            <div className="flex items-center mt-3">
              <Button
                size="small"
                className="mr-1"
                type="secondary"
                onClick={() => setIsEditingTags(false)}
              >
                Cancel
              </Button>
              <Button
                size="small"
                disabled={!areTagsDirty}
                loading={loading}
                onClick={onSave}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarCandidateHeader;
