import React, { useMemo, useState } from "react";
import CardSkeleton from "./CardSkeleton";
import AnalyticsTable from "./AnalyticsTable";

import { format } from "date-fns";
import { Card, Title, AreaChart } from "@tremor/react";
import { Dropdown, DropdownItem, Callout } from "@tremor/react";
import { title } from "case";
import { EmojiSadIcon, PlusIcon, MinusIcon } from "@heroicons/react/outline";
import { Button } from "solostar-components";

import {
  GetAnalyticsInput,
  useAnalytics,
  AnalyticsFacet,
  Scope,
} from "solostar-graphql";

export type AnalyticsFacetOption = {
  key: AnalyticsFacet;
  value: string;
  displayName?: string;
};

type Props = {
  title: string;
  input: GetAnalyticsInput;
  shouldAggregate?: boolean;
  maxSeries?: number;
  facetOptions?: AnalyticsFacetOption[];
};

export const FACET_KEYWORD_NO_FILTER = "No Filter";
export const FACET_KEYWORD_ALL = "ALL";
const DEFAULT_MAX_SERIES = 5;
const AGGREGATE_KEY = "Total";

const getFacetOptionDisplay = (o: AnalyticsFacetOption) =>
  o.displayName || `${title(o.key)} - ${o.value}`;

const AnalyticsChart: React.FC<Props> = (props) => {
  const {
    input,
    title,
    shouldAggregate,
    maxSeries = DEFAULT_MAX_SERIES,
    facetOptions,
  } = props;

  const [facet, setFacet] = useState<AnalyticsFacetOption | undefined>();
  const [tableOpen, setTableOpen] = useState(false);

  const { data: analytics, loading } = useAnalytics({
    input: {
      ...input,
      facet: facet
        ? {
            key: facet.key,
            value: facet.value,
          }
        : undefined,
    },
  });

  const chart = useMemo(() => {
    const series = analytics?.timeSeries;
    if (!series?.[0]) {
      return null;
    }
    const data: Array<Record<string, number | string>> = [];
    series[0].data.forEach((d, idx) => {
      const value: Record<string, number | string> = {
        date: new Date(d.time).getTime(),
      };
      if (shouldAggregate) {
        const total = series.reduce((prev, next) => {
          const v = next.data.find((n) => n.time === d.time);
          return prev + (v?.value || 0);
        }, 0);
        value[AGGREGATE_KEY] = total;
      } else {
        [...series]
          .sort(
            (a, b) =>
              Math.max(...b.data.map((d) => d.value || 0)) -
              Math.max(...a.data.map((d) => d.value || 0))
          )
          .slice(0, maxSeries)
          .forEach((s) => {
            const dataPoint = s.data[idx];
            const category = s.displayName;
            value[category] = dataPoint?.value || 0;
          });
      }
      data.push(value);
    });
    data.sort((a, b) => Number(a.date) - Number(b.date));
    return {
      data: data.map((d) => ({ ...d, date: format(new Date(d.date), "PP") })),
      categories: Object.keys(data[0]).filter((k) => k !== "date"),
      yAxisKey: "date",
    };
  }, [analytics, maxSeries, shouldAggregate]);

  return (
    <div className="mb-8">
      <Card>
        <div className="flex items-center justify-between">
          <Title>{title}</Title>
          <div className="flex items-center w-72">
            <p className="mr-3 text-sm font-medium text-gray-700">Filter</p>
            {facetOptions && (
              <Dropdown
                placeholder="Filter this data..."
                handleSelect={(idx) => {
                  const newFacet = facetOptions[idx];
                  if (newFacet?.displayName === FACET_KEYWORD_NO_FILTER) {
                    setFacet(undefined);
                  } else if (newFacet) {
                    setFacet(newFacet);
                  }
                }}
                marginTop="mt-0"
              >
                {facetOptions.map((o, idx) => (
                  <DropdownItem
                    key={idx}
                    value={idx.toString()}
                    text={getFacetOptionDisplay(o)}
                  />
                ))}
              </Dropdown>
            )}
          </div>
        </div>
        {loading ? (
          <div className="h-72 mt-4">
            <CardSkeleton />
          </div>
        ) : chart ? (
          <React.Fragment>
            <AreaChart
              data={chart.data}
              categories={chart.categories}
              dataKey={chart.yAxisKey}
              height="h-72"
              marginTop="mt-4"
              valueFormatter={(n) => n.toLocaleString()}
            />
            {/** Only show table of full data when we're showing analytics broken down by user. */}
            {analytics?.timeSeries &&
              !shouldAggregate &&
              input.scope === Scope.TENANT && (
                <React.Fragment>
                  <Button
                    type="secondary"
                    icon={tableOpen ? MinusIcon : PlusIcon}
                    className="mt-6"
                    onClick={() => setTableOpen(!tableOpen)}
                  >
                    {tableOpen ? "Hide Table" : "Show Table With All Users"}
                  </Button>
                  {tableOpen && (
                    <AnalyticsTable
                      data={analytics.timeSeries}
                      granularity={input.granularity}
                    />
                  )}
                </React.Fragment>
              )}
          </React.Fragment>
        ) : (
          <div className="h-72 flex items-center justify-center">
            <Callout
              title="No Data"
              text="Sorry, we don't have any analytics data for this set of filters."
              icon={EmojiSadIcon}
              color="slate"
            />
          </div>
        )}
      </Card>
    </div>
  );
};

export default AnalyticsChart;
