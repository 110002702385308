import { gql, useMutation } from "@apollo/client";
import { AddPerson, AddPersonVariables } from "../__generated__/AddPerson";
import { PERSON_FRAGMENT } from "fragments/person";

export const ADD_PERSON = gql`
  ${PERSON_FRAGMENT}
  mutation AddPerson($input: AddPersonInput!) {
    addPerson(input: $input) {
      ...PersonFragment
    }
  }
`;

export const useAddPerson = (variables?: AddPersonVariables) =>
  useMutation<AddPerson, AddPersonVariables>(ADD_PERSON, {
    variables,
    refetchQueries: ["GetCompanies"],
    awaitRefetchQueries: true,
  });
