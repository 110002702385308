import React, { useState, useCallback, useMemo, useEffect } from "react";
import { CheckCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";

import {
  getScopeOptions,
  getTagIds,
  getTagOptions,
  useTagsSelect,
  useSelectivitySelect,
} from "utils/tags";

import {
  Company,
  Scope,
  TagTarget,
  useMe,
  useTags,
  useUpdateCompany,
  useAddCompany,
} from "solostar-graphql";

import {
  Sidesheet,
  Button,
  Input,
  InputValidation,
  RadioGroup,
} from "solostar-components";

const TARGET = TagTarget.COMPANY;

type CompaniesSidesheetProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  company?: Company;
};

type Validation = {
  displayName?: InputValidation;
};

const CompaniesSidesheet: React.FC<CompaniesSidesheetProps> = (props) => {
  const { isOpen, onRequestClose, company } = props;
  const { data: me } = useMe();
  const { data: tagsData } = useTags();

  const { tags, setTags, tagsSelect } = useTagsSelect(TARGET, tagsData);

  const { selectivity, setSelectivity, selectivitySelect } =
    useSelectivitySelect(TARGET, tagsData);

  const scopeOptions = useMemo(
    () => getScopeOptions(TARGET, me?.tenant?.displayName || me?.tenant?.id),
    [me]
  );

  const [validationEnabled, setValidationEnabled] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [scope, setScope] = useState(scopeOptions[0].id);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (company) {
      setDisplayName(company.displayName);
      setTags(getTagOptions(TARGET, company.tags ?? []));
      setScope(company.scope || scopeOptions[0].id);
      if (company.selectivity) {
        const tags = getTagOptions(TARGET, [company.selectivity], true);
        setSelectivity(tags[0] || null);
      } else {
        setSelectivity(null);
      }
    }
  }, [company, scopeOptions, setSelectivity, setTags]);

  const [addCompany] = useAddCompany();
  const [updateCompany] = useUpdateCompany();

  const validate = useCallback(() => {
    const validation: Validation = {};
    if (!displayName) {
      validation.displayName = {
        status: "error",
        text: "You must provide a name for this company.",
      };
    }
    return validation;
  }, [displayName]);

  const validation = useMemo(() => {
    if (validationEnabled) {
      return validate();
    }
    return {};
  }, [validate, validationEnabled]);

  const onCreate = useCallback(async () => {
    setValidationEnabled(true);
    const validation = validate();
    if (Object.keys(validation).length > 0) {
      return;
    }
    setLoading(true);

    /** Create any new tags the user might have added. */
    const tagIds = await getTagIds(tags, scope, TARGET);

    if (company) {
      await updateCompany({
        variables: {
          input: {
            id: company.id,
            scope: company.scope || Scope.USER,
            company: {
              displayName,
              tagIds,
              selectivityTagId: selectivity?.value || null,
            },
          },
        },
      });
    } else {
      await addCompany({
        variables: {
          input: {
            displayName,
            scope,
            tagIds,
            selectivityTagId: selectivity?.value,
            feeders: [],
            aliases: [],
          },
        },
      });
    }
    setLoading(false);
    onRequestClose();
  }, [
    company,
    updateCompany,
    addCompany,
    onRequestClose,
    validate,
    displayName,
    scope,
    tags,
    selectivity,
  ]);

  return (
    <Sidesheet
      title={`${company ? "Edit" : "Create"} Company`}
      description="Once you create custom companies, any tags you add to them will show up in the LinkedIn UI."
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      buttons={[
        <Button type="secondary" onClick={onRequestClose} key="cancel">
          Cancel
        </Button>,
        <Button
          icon={company ? CheckCircleIcon : PlusCircleIcon}
          onClick={onCreate}
          loading={loading}
          key="create"
        >
          {company ? "Save" : "Create"}
        </Button>,
      ]}
    >
      <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
        <Sidesheet.FormItem label="Company Name">
          <Input
            value={displayName}
            setValue={setDisplayName}
            placeholder="What is the company's name?"
            validation={validation.displayName}
          />
        </Sidesheet.FormItem>

        {!company && (
          <Sidesheet.FormItem label="Scope">
            <RadioGroup
              options={scopeOptions}
              value={scope}
              onChange={setScope}
            />
          </Sidesheet.FormItem>
        )}

        <Sidesheet.FormItem label="Selectivity" htmlFor="Selectivity">
          {selectivitySelect}
        </Sidesheet.FormItem>

        <Sidesheet.FormItem label="Tags" htmlFor="tags">
          {tagsSelect}
        </Sidesheet.FormItem>
      </div>
    </Sidesheet>
  );
};

export default CompaniesSidesheet;
