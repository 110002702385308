import { gql } from "@apollo/client";

export const FRAGMENT_HTML_IDENTIFIER = gql`
  fragment HTMLElementIdentifierFragment on HTMLElementIdentifier {
    multiple
    type
    id
  }
`;

export const FRAGMENT_ONCLICK_EVENT = gql`
  fragment OnClickEventFragment on OnClickEvent {
    name
    data
  }
`;

export const FRAGMENT_DECORATION_HTML = gql`
  fragment DecorationHTMLFragment on DecorationHTML {
    id
    content
    tag
    className
  }
`;

export const FRAGMENT_DECORATION = gql`
  ${FRAGMENT_HTML_IDENTIFIER}
  ${FRAGMENT_ONCLICK_EVENT}
  ${FRAGMENT_DECORATION_HTML}
  fragment DecorationFragment on Decoration {
    html {
      ...DecorationHTMLFragment
    }
    parentIdentifier {
      ...HTMLElementIdentifierFragment
    }
    onClickEvent {
      ...OnClickEventFragment
    }
    forceUpdate
  }
`;
