import { gql, useMutation } from "@apollo/client";
import {
  AddInstitution,
  AddInstitutionVariables,
} from "../__generated__/AddInstitution";
import { INSTITUTION_FRAGMENT } from "fragments/candidate";

export const ADD_INSTITUTION = gql`
  ${INSTITUTION_FRAGMENT}
  mutation AddInstitution($input: AddInstitutionInput!) {
    addInstitution(input: $input) {
      ...InstitutionFragment
    }
  }
`;

export const useAddInstitution = (variables?: AddInstitutionVariables) =>
  useMutation<AddInstitution, AddInstitutionVariables>(ADD_INSTITUTION, {
    variables,
    refetchQueries: ["GetInstitutions"],
    awaitRefetchQueries: true,
  });
