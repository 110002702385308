import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const config = {
  apiKey: process.env.GCP_API_KEY,
  authDomain: process.env.GCP_AUTH_DOMAIN,
  projectId: process.env.GCP_PROJECT_ID,
  appId: process.env.GCP_APP_ID,
};

export const app = initializeApp(config);
export const auth = getAuth();
