import { Scope } from "solostar-graphql";
import { SCOPE_PRIORITY, DEFAULT_TAGS_ARRAY } from "utils/constants";

/**
 * Takes an array of objects with a `scope` property (`scope` is the enum from
 * our GraphQL schema) and sorts them according to the scope's priority.
 */
export const sortByIdAndScope = <T extends { scope: Scope | null; id: string }>(
  a: T,
  b: T
) => {
  /** First sort by the custom ID order we defined in this constant. */
  const aId = DEFAULT_TAGS_ARRAY.indexOf(a.id);
  const bId = DEFAULT_TAGS_ARRAY.indexOf(b.id);
  const id = aId - bId;
  /** That being equal, sort by scope. */
  if (id === 0) {
    const aScope = a.scope || Scope.APP;
    const bScope = b.scope || Scope.APP;
    return SCOPE_PRIORITY.indexOf(aScope) - SCOPE_PRIORITY.indexOf(bScope);
  }
  return id;
};

/**
 * Reduces an array of objects to only include the first match of an object with
 * the given key. For example, if you have multiple objects in an array with
 * `id` = `1`, this will reduce it to a new array with only the first object with
 * `id` = `1`.
 */
export const reduceByFirstMatch = <T extends { id: string | number | null }>(
  acc: T[],
  obj: T
) => {
  if (!acc.find((accObj) => accObj.id === obj.id)) {
    acc.push(obj);
  }
  return acc;
};

export const isNotEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => value !== null && value !== undefined;

export const isEqual = <T>(arr1: T[], arr2: T[]) => {
  if (!arr1 || !arr2) {
    return false;
  }

  if (arr1.length != arr2.length) {
    return false;
  }

  return arr1.every((arr1Item) => arr2.includes(arr1Item));
};
