import { gql, useQuery } from "@apollo/client";
import { GetCompanies } from "../__generated__/GetCompanies";
import { COMPANY_FRAGMENT } from "fragments/candidate";

export const GET_COMPANIES = gql`
  ${COMPANY_FRAGMENT}
  query GetCompanies {
    getCompanies {
      ...CompanyFragment
    }
  }
`;

export const useCompanies = () => {
  const res = useQuery<GetCompanies>(GET_COMPANIES);
  return { ...res, data: res.data?.getCompanies };
};
