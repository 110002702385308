import React from "react";
import { TagTarget } from "solostar-graphql";

import {
  UsersIcon,
  BriefcaseIcon,
  AcademicCapIcon,
} from "@heroicons/react/outline";

const TYPE_ICONS: Record<TagTarget, React.FC<{ className: string }>> = {
  [TagTarget.COMPANY]: BriefcaseIcon,
  [TagTarget.INSTITUTION]: AcademicCapIcon,
  [TagTarget.PERSON]: UsersIcon,
};

const TagTargetCell: React.FC<{ target: TagTarget }> = (props) => {
  const { target } = props;
  const Icon = TYPE_ICONS[target];
  return (
    <div className="flex items-center">
      <Icon className="h-4 w-4 stroke-gray-500 mr-1" />
      <span className="text-xs font-medium text-gray-500 uppercase tracking-wider">
        {target}
      </span>
    </div>
  );
};

export default TagTargetCell;
