import React, { useState, useCallback, useMemo, useEffect } from "react";
import cn from "classnames";
import TagTypeCell from "pages/Options/TagTarget";

import { BlockPicker } from "react-color";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";

import {
  Tag,
  TagType,
  TagTarget,
  Scope,
  useMe,
  useUpdateTag,
  useAddTag,
} from "solostar-graphql";

import {
  Input,
  InputValidation,
  SelectOption,
  Sidesheet,
  Button,
} from "solostar-components";

type TagsSidesheetProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  tag?: Tag;
};

const TARGET_OPTIONS: SelectOption<TagTarget>[] = Object.values(TagTarget).map(
  (target) => ({
    id: target,
    displayName: <TagTypeCell target={target} />,
  })
);

const getScopeOptions = (tenant?: string | null) => [
  {
    id: Scope.USER,
    title: "Just You",
    description: "Only you will be able to see and use this tag.",
  },
  {
    id: Scope.TENANT,
    title: tenant || "Your Team",
    description: `Anyone on ${
      tenant ? `the ${tenant}` : "your"
    } team can see and use this tag.`,
  },
];

type Validation = {
  displayName?: InputValidation;
};

const TagsSidesheet: React.FC<TagsSidesheetProps> = (props) => {
  const { isOpen, onRequestClose, tag } = props;
  const { data: me } = useMe();

  const scopeOptions = getScopeOptions(me?.tenant?.id);

  const [validationEnabled, setValidationEnabled] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [color, setColor] = useState("#22194d");
  const [scope, setScope] = useState(scopeOptions[0].id);
  const [target, setTarget] = useState(TARGET_OPTIONS[0]);

  useEffect(() => {
    if (tag) {
      const tagType = TARGET_OPTIONS.find((option) => option.id === tag.target);
      setDisplayName(tag.displayName);
      setColor(tag.color);
      setTarget(tagType || TARGET_OPTIONS[0]);
    }
  }, [tag]);

  const [addTag, addTagStatus] = useAddTag();
  const [updateTag, updateTagStatus] = useUpdateTag();

  const loading = addTagStatus?.loading || updateTagStatus?.loading || false;

  const validate = useCallback(() => {
    const validation: Validation = {};
    if (!displayName) {
      validation.displayName = {
        status: "error",
        text: "You must provide a name for this tag.",
      };
    }
    return validation;
  }, [displayName]);

  const validation = useMemo(() => {
    if (validationEnabled) {
      return validate();
    }
    return {};
  }, [validate, validationEnabled]);

  const onCreate = useCallback(async () => {
    setValidationEnabled(true);
    const validation = validate();
    if (Object.keys(validation).length > 0) {
      return;
    }
    if (tag) {
      await updateTag({
        variables: {
          input: {
            id: tag.id,
            scope: tag.scope || Scope.USER,
            tag: { displayName, color, target: target.id },
          },
        },
      });
    } else {
      await addTag({
        variables: {
          input: {
            displayName,
            scope,
            type: TagType.CUSTOM,
            color,
            target: target.id,
            hidden: false,
          },
        },
      });
    }
    onRequestClose();
  }, [
    tag,
    updateTag,
    addTag,
    onRequestClose,
    validate,
    displayName,
    scope,
    color,
    target.id,
  ]);

  return (
    <Sidesheet
      title={`${tag ? "Edit" : "Create"} Tag`}
      description={
        tag
          ? undefined
          : "Once you create a tags, you can it to any companies, institutions, or people."
      }
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      buttons={[
        <Button type="secondary" onClick={onRequestClose} key="cancel">
          Cancel
        </Button>,
        <Button
          icon={tag ? CheckCircleIcon : PlusCircleIcon}
          onClick={onCreate}
          loading={loading}
          key="create"
        >
          {tag ? "Save" : "Create"}
        </Button>,
      ]}
    >
      <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
        {/* Project name */}
        <Sidesheet.FormItem label="Tag Name">
          <Input
            value={displayName}
            setValue={setDisplayName}
            placeholder="What the tag should be called"
            validation={validation.displayName}
          />
        </Sidesheet.FormItem>

        {/* <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="project-description"
              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
            >
              Type
            </label>
          </div>
          <div className="sm:col-span-2">
            <Select options={TYPE_OPTIONS} value={type} onChange={setType} />
            <p className="text-xs mt-3 text-gray-500">
              This determines what types of things the label can be applied to.
              For example, a label with type "Institution" can only be used for
              schools.
            </p>
          </div>
        </div> */}

        {!tag && (
          <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
            <div>
              <h3 className="text-sm font-medium text-gray-900">Scope</h3>
            </div>
            <div className="sm:col-span-2">
              <RadioGroup value={scope} onChange={setScope}>
                <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  {scopeOptions.map((option) => (
                    <RadioGroup.Option
                      key={option.id}
                      value={option.id}
                      className={({ checked, active }) =>
                        cn(
                          checked ? "border-transparent" : "border-gray-300",
                          active ? "ring-2 ring-indigo-500" : "",
                          "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <div className="flex-1 flex">
                            <div className="flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className="block text-sm font-medium text-gray-900 capitalize"
                              >
                                {option.title}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className="mt-1 flex items-center text-sm text-gray-500"
                              >
                                {option.description}
                              </RadioGroup.Description>
                            </div>
                          </div>
                          <CheckCircleIcon
                            className={cn(
                              !checked ? "invisible" : "",
                              "h-5 w-5 text-indigo-600"
                            )}
                            aria-hidden="true"
                          />
                          <div
                            className={cn(
                              active ? "border" : "border-2",
                              checked
                                ? "border-indigo-500"
                                : "border-transparent",
                              "absolute -inset-px rounded-lg pointer-events-none"
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
          </div>
        )}

        {/* Privacy */}
        <Sidesheet.FormItem label="Color">
          <div className="space-y-5 sm:mt-0 rounded-md shadow-lg">
            <BlockPicker
              width="100%"
              triangle="hide"
              color={color}
              onChangeComplete={(color) => setColor(color.hex)}
            />
          </div>
        </Sidesheet.FormItem>
      </div>
    </Sidesheet>
  );
};

export default TagsSidesheet;
