import { gql, useQuery } from "@apollo/client";
import {
  GetStockPrice,
  GetStockPriceVariables,
} from "../__generated__/GetStockPrice";

export const GET_STOCK_PRICE = gql`
  query GetStockPrice($symbol: String!) {
    getStockPrice(symbol: $symbol) {
      id
      price
      label
      symbol
    }
  }
`;

export const useStockPrice = (symbol: string, skip?: boolean) => {
  const res = useQuery<GetStockPrice, GetStockPriceVariables>(GET_STOCK_PRICE, {
    variables: { symbol },
    skip,
  });
  return { ...res, data: res.data?.getStockPrice };
};
