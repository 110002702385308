import ReactDOM from "react-dom";
import App from "App";
import "./index.css";
import { ApolloProvider, client } from "solostar-graphql";

const main = async () => {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>,
    document.getElementById("root")
  );
};

main();
