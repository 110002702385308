import React, { Fragment, useState } from "react";
import classNames from "classnames";

import Profile from "pages/Options/Profile";
// import Preferences from "pages/Options/Preferences";
import Tags from "pages/Options/Tags";
import Institutions from "pages/Options/Institutions";
import Companies from "pages/Options/Companies";
import Login from "pages/Login";
import Analytics from "pages/Options/Analytics";
import Billing from "pages/Options/Billing";

import { Link, useMatch } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useMe } from "solostar-graphql";
import { Routes, Route } from "react-router-dom";

import {
  UserIcon,
  // AdjustmentsIcon,
  TagIcon,
  BriefcaseIcon,
  AcademicCapIcon,
  // CollectionIcon,
  MenuIcon,
  XIcon,
  ChartSquareBarIcon,
} from "@heroicons/react/outline";

type SectionMetadata = {
  path: string;
  displayName: string;
  icon: React.FC<{ className: string }>;
  component: React.FC;
  linkPath?: string;
  fullWidth?: boolean;
};

const OPTIONS_SECTIONS: SectionMetadata[] = [
  {
    path: "",
    displayName: "Profile",
    icon: UserIcon,
    component: Profile,
  },
  // {
  //   path: "preferences",
  //   displayName: "Preferences",
  //   icon: AdjustmentsIcon,
  //   component: Preferences,
  // },
  {
    path: "tags/*",
    linkPath: "tags",
    displayName: "Tags",
    icon: TagIcon,
    component: Tags,
  },
  {
    path: "companies/*",
    linkPath: "companies",
    displayName: "Companies",
    icon: BriefcaseIcon,
    component: Companies,
  },
  {
    path: "institutions/*",
    linkPath: "institutions",
    displayName: "Institutions",
    icon: AcademicCapIcon,
    component: Institutions,
  },
  {
    path: "analytics/*",
    linkPath: "analytics",
    displayName: "Analytics",
    icon: ChartSquareBarIcon,
    component: Analytics,
    fullWidth: true,
  },
  // {
  //   path: "datasets",
  //   displayName: "Datasets",
  //   icon: CollectionIcon,
  //   component: Profile,
  // },
  {
    path: "billing/*",
    linkPath: "billing",
    displayName: "Billing",
    icon: AcademicCapIcon,
    component: Billing,
  },
];

const DEFAULT_SECTION = OPTIONS_SECTIONS[0];

const NavLink: React.FC<{ item: SectionMetadata }> = (props) => {
  const { item } = props;
  const active = !!useMatch("options/" + item.path);
  return (
    <Link
      to={item.linkPath || item.path}
      className={classNames(
        active
          ? "bg-gray-100 text-gray-900"
          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
      )}
    >
      <item.icon
        className={classNames(
          active ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500",
          "mr-3 flex-shrink-0 h-6 w-6"
        )}
        aria-hidden="true"
      />
      {item.displayName}
    </Link>
  );
};

export const Options = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { data: me, error, loading } = useMe();

  const activeSection = DEFAULT_SECTION;

  /** If user is not logged in, show the Login component. */
  if (!loading && error) {
    return (
      <div>
        <Login />
      </div>
    );
  }

  if (!me) {
    /** TO DO: Replace with a <Loader /> */
    return null;
  }

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="w-6 mr-2 object-contain"
                      src="/logo192.png"
                      alt="SoloStar Logo"
                    />
                    <span className="text-lg font-bold text-gray-700">
                      SoloStar
                    </span>
                  </div>
                  <nav className="mt-5 px-2 space-y-1">
                    {OPTIONS_SECTIONS.map((item) => (
                      <Link
                        key={item.path}
                        to={item.linkPath || item.path}
                        className={classNames(
                          activeSection.path === item.path
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            activeSection.path === item.path
                              ? "text-gray-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.displayName}
                      </Link>
                    ))}
                  </nav>
                </div>
                {me && (
                  <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                    <Link
                      to={DEFAULT_SECTION.path}
                      className="flex-shrink-0 group block"
                    >
                      <div className="flex items-center">
                        <div>
                          <img
                            className="inline-block h-10 w-10 rounded-full"
                            src={me.photoUrl || ""}
                            alt={me.displayName || ""}
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                            {me.displayName}
                          </p>
                          <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                            View profile
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
            <div className="flex-1 flex flex-col pt-6 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-6">
                <img
                  className="w-6 mr-2 object-contain"
                  src="/logo192.png"
                  alt="SoloStar Logo"
                />
                <span className="text-lg font-bold text-gray-700">
                  SoloStar
                </span>
              </div>
              <nav className="mt-5 flex-1 px-4 bg-white space-y-1">
                {OPTIONS_SECTIONS.map((item) => (
                  <NavLink key={item.path} item={item} />
                ))}
              </nav>
            </div>

            {me.displayName && (
              <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                <Link
                  to={DEFAULT_SECTION.path}
                  className="flex-shrink-0 w-full group block"
                >
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-9 w-9 rounded-full"
                        src={me.photoUrl || ""}
                        alt={me.displayName || "none"}
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                        {me.displayName}
                      </p>
                      <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                        View profile
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1 h-full">
            <div className="py-12">
              <div
                className={classNames({
                  "mx-auto px-4 sm:px-6 md:px-8": true,
                })}
              >
                <Routes>
                  {OPTIONS_SECTIONS.map((section) => (
                    <Route
                      key={section.path}
                      path={section.path}
                      element={<section.component />}
                    />
                  ))}
                </Routes>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Options;
