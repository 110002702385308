import { gql, useQuery, QueryHookOptions, QueryOptions } from "@apollo/client";
import { GetCompany, GetCompanyVariables } from "../__generated__/GetCompany";
import { COMPANY_FRAGMENT } from "fragments/candidate";
import { client } from "../apollo";

export const GET_COMPANY = gql`
  ${COMPANY_FRAGMENT}
  query GetCompany($id: String!) {
    getCompany(id: $id) {
      ...CompanyFragment
    }
  }
`;

export const useCompany = (
  variables: GetCompanyVariables,
  options: QueryHookOptions<GetCompany, GetCompanyVariables>
) => {
  const res = useQuery<GetCompany, GetCompanyVariables>(GET_COMPANY, {
    variables,
    ...options,
  });
  return { ...res, data: res.data?.getCompany };
};

export const getCompany = (
  variables: GetCompanyVariables,
  options?: Partial<QueryOptions<GetCompany, GetCompanyVariables>>
) => {
  return client.query<GetCompany, GetCompanyVariables>({
    ...options,
    query: GET_COMPANY,
    variables,
  });
};
