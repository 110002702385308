import { gql, useQuery } from "@apollo/client";
import { GetSearchIdeas } from "../__generated__/GetSearchIdeas";
import { SEARCH_GROUP_FRAGMENT } from "../fragments/searchGroup";

export const GET_SEARCH_IDEAS = gql`
  ${SEARCH_GROUP_FRAGMENT}
  query GetSearchIdeas {
    getSearchIdeas {
      ...SearchGroupFragment
    }
  }
`;

export const useSearchIdeas = () => {
  const res = useQuery<GetSearchIdeas>(GET_SEARCH_IDEAS);
  return { ...res, data: res.data?.getSearchIdeas };
};
