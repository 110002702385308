import { gql, useQuery, QueryOptions } from "@apollo/client";
import { client } from "../apollo";
import { FRAGMENT_DECORATION } from "../fragments/html";

import {
  GetBootstrap,
  GetBootstrapVariables,
} from "../__generated__/GetBootstrap";

export const GET_BOOTSTRAP = gql`
  ${FRAGMENT_DECORATION}
  query GetBootstrap($input: GetBootstrapInput!) {
    getBootstrap(input: $input) {
      configuration {
        onLoadRetriesMax
        onLoadRetriesInterval
      }
      observers {
        id
        options {
          attributes
          childList
          subtree
        }
        identifier {
          ...HTMLElementIdentifierFragment
        }
      }
      globalDecorations {
        ...DecorationFragment
      }
      itemButtonsToClick {
        ...HTMLElementIdentifierFragment
      }
      scrollIntoViewAfterClick {
        ...HTMLElementIdentifierFragment
      }
      facetTypes {
        id
        allowsBooleans
        isSingleSelection
        searchContainerIdentifier {
          ...HTMLElementIdentifierFragment
        }
        editButtonIdentifier {
          ...HTMLElementIdentifierFragment
        }
        searchInputIdentifier {
          ...HTMLElementIdentifierFragment
        }
        searchItemIdentifier {
          ...HTMLElementIdentifierFragment
        }
      }
    }
  }
`;

export const useBootstrap = () => {
  const res = useQuery<GetBootstrap, GetBootstrapVariables>(GET_BOOTSTRAP);
  return { ...res, data: res.data?.getBootstrap };
};

export const getBootstrap = async (
  variables: GetBootstrapVariables,
  options?: Partial<QueryOptions<GetBootstrap, GetBootstrapVariables>>
) => {
  const res = await client.query<GetBootstrap, GetBootstrapVariables>({
    ...options,
    query: GET_BOOTSTRAP,
    variables,
  });
  return { ...res, data: res.data?.getBootstrap };
};
