import React from "react";

import { events } from "solostar-lib";
import { EventName } from "solostar-graphql";
import { Button } from "solostar-components";

type Props = {
  showClose?: boolean;
};

const SidebarHeader: React.FC<Props> = (props) => {
  const { showClose } = props;
  return (
    <div className="ss__header h-12 flex-shrink-0 basis-12 z-20 px-4 bg-gray-100 flex align-center justify-between">
      <div className="ss__footer__logo flex items-center">
        <img
          className="w-5 mr-1 object-contain"
          src="/logo192.png"
          alt="SoloStar Logo"
        />
        <span className="text-sm font-medium">SoloStar</span>
      </div>
      <div className="ss__footer__close flex items-center">
        {showClose && (
          <Button
            type="text"
            className="px-0"
            onClick={() =>
              events.emit({ name: EventName.SS_CLOSE_SIDEBAR, data: {} }, "top")
            }
          >
            Close
          </Button>
        )}
      </div>
    </div>
  );
};

export default SidebarHeader;
