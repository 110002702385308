import { gql } from "@apollo/client";
import { client } from "../apollo";

import {
  EmitAnalytics,
  EmitAnalyticsVariables,
} from "../__generated__/EmitAnalytics";

export const EMIT_ANALYTICS = gql`
  mutation EmitAnalytics($input: EmitAnalyticsInput!) {
    emitAnalytics(input: $input)
  }
`;

export const emitAnalytics = (variables: EmitAnalyticsVariables) => {
  return client.mutate<EmitAnalytics, EmitAnalyticsVariables>({
    mutation: EMIT_ANALYTICS,
    variables,
    refetchQueries: ["GetMe"],
  });
};
