import { gql, useMutation } from "@apollo/client";
import {
  DeleteSearchGroup,
  DeleteSearchGroupVariables,
} from "../__generated__/DeleteSearchGroup";

export const DELETE_SEARCH_GROUP = gql`
  mutation DeleteSearchGroup($input: DeleteSearchGroupInput!) {
    deleteSearchGroup(input: $input)
  }
`;

export const useDeleteSearchGroup = (variables?: DeleteSearchGroupVariables) =>
  useMutation<DeleteSearchGroup, DeleteSearchGroupVariables>(
    DELETE_SEARCH_GROUP,
    {
      variables,
      refetchQueries: ["GetSearchIdeas"],
      awaitRefetchQueries: true,
    }
  );
