import React, { useState } from "react";

import { EmojiSadIcon } from "@heroicons/react/outline";
import { Button } from "solostar-components";
import { checkout, usePlans, useMe, manageBilling } from "solostar-graphql";

export const useOnSubscribe = () => {
  const [loading, setLoading] = useState(false);

  const { data: plans } = usePlans();
  const { startPolling } = useMe();

  const onSubscribe = async () => {
    setLoading(true);
    const plan = [...(plans ?? [])].sort((a, b) => b.price - a.price)[0];
    const domain =
      process.env.REACT_APP_EXTENSION_WEB_URL ??
      "https://extension.solostar.io";
    if (plan?.priceId) {
      const { data } = await checkout({
        input: { priceId: plan.priceId, domain },
      });
      if (data?.checkout) {
        startPolling(3000);
        window.open(data.checkout, "_blank");
      }
    }
    setLoading(false);
  };

  return { onSubscribe, loading };
};

export const useManageBilling = () => {
  const [loading, setLoading] = useState(false);

  const onManageBilling = async () => {
    setLoading(true);
    const domain =
      process.env.REACT_APP_EXTENSION_WEB_URL ??
      "https://extension.solostar.io";
    const { data } = await manageBilling({ input: { domain } });
    if (data?.manageBilling) {
      window.open(data.manageBilling, "_blank");
    }
    setLoading(false);
  };

  return { onManageBilling, loading };
};

const SubscriptionExpired: React.FC = () => {
  const { onSubscribe, loading } = useOnSubscribe();

  return (
    <div className="w-screen h-screen center bg-gray-100">
      <div className="inline-block align-bottom bg-white rounded-lg m-8 px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100">
            <EmojiSadIcon
              className="h-6 w-6 text-gray-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Trial Expired
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Looks like your free trial to SoloStar has expired. If you'd
                like to continue using SoloStar, you can start a subscription or{" "}
                <a
                  href="mailto:info@solostar.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  reach out to us
                </a>{" "}
                if you need more than one seat.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 gap-x-2 flex justify-center">
          <a
            href="mailto:info@solostar.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button type="secondary">Contact Us</Button>
          </a>
          <Button loading={loading} onClick={onSubscribe}>
            Subscribe
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionExpired;
