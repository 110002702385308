import { useContext, useEffect } from "react";
import { useMe } from "solostar-graphql";
import { isExtensionOrPopup } from "utils/env";

import SidebarHeader from "pages/Sidebar/SidebarHeader";
import SearchSuggestions from "pages/Sidebar/SidebarSearchSuggestions";
import SidebarOnWeb from "pages/Sidebar/SidebarOnWeb";
import SidebarNotLoggedIn from "pages/Sidebar/SidebarNotLoggedIn";
import SidebarTimeline from "pages/Sidebar/SidebarTimeline";
import { Context } from "App";
import { useNavigationHook } from "utils/navigationHook";

const Sidebar = () => {
  const { candidate, activeItemId, setActiveItemId } = useContext(Context);
  const { data: me, loading, error } = useMe();

  useNavigationHook();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [candidate]);

  if (!loading && error) {
    return <SidebarNotLoggedIn />;
  }

  if (!me) {
    return null;
  }

  return (
    <div className="flex flex-col h-screen">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
      {candidate ? (
        <SidebarTimeline
          candidate={candidate}
          activeItemId={activeItemId}
          onClickItem={(id) => {
            if (id === activeItemId) {
              setActiveItemId?.(undefined);
            } else if (id) {
              setActiveItemId?.(id);
            }
          }}
        />
      ) : isExtensionOrPopup() ? (
        <SearchSuggestions />
      ) : (
        <SidebarOnWeb />
      )}
    </div>
  );
};

export default Sidebar;
