import { gql, useMutation } from "@apollo/client";
import { UpdateTag, UpdateTagVariables } from "../__generated__/UpdateTag";
import { TAG_FRAGMENT } from "../fragments/tag";

export const UPDATE_TAG = gql`
  ${TAG_FRAGMENT}
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      ...TagFragment
    }
  }
`;

export const useUpdateTag = (variables?: UpdateTagVariables) =>
  useMutation<UpdateTag, UpdateTagVariables>(UPDATE_TAG, {
    variables,
    refetchQueries: ["GetTags"],
    awaitRefetchQueries: true,
  });
