import React from "react";

const SkeletonCard: React.FC = () => (
  <div className="w-full animate-pulse flex-row items-center justify-center space-x-1">
    <div className="flex flex-col space-y-2">
      <div className="h-6 w-11/12 rounded-md bg-gray-300 "></div>
      <div className="h-6 w-10/12 rounded-md bg-gray-300 "></div>
      <div className="h-6 w-9/12 rounded-md bg-gray-300 "></div>
      <div className="h-6 w-9/12 rounded-md bg-gray-300 "></div>
    </div>
  </div>
);

export default SkeletonCard;
