import { gql, useMutation } from "@apollo/client";
import { UpdateSearchGroup } from "../__generated__/UpdateSearchGroup";
import { SEARCH_GROUP_FRAGMENT } from "../fragments/searchGroup";
import { SearchFieldData, SearchFieldType } from "../types";
import { UpdateSearchGroupInput } from "../__generated__/globalTypes";

type UpdateSearchGroupVariables = {
  input: UpdateSearchGroupInput & {
    fields?: Array<{ type: SearchFieldType; data: SearchFieldData }>;
  };
};

export const UPDATE_SEARCH_GROUP = gql`
  ${SEARCH_GROUP_FRAGMENT}
  mutation UpdateSearchGroup($input: UpdateSearchGroupInput!) {
    updateSearchGroup(input: $input) {
      ...SearchGroupFragment
    }
  }
`;

export const useUpdateSearchGroup = (variables?: UpdateSearchGroupVariables) =>
  useMutation<UpdateSearchGroup, UpdateSearchGroupVariables>(
    UPDATE_SEARCH_GROUP,
    {
      variables,
      refetchQueries: ["GetSearchIdeas"],
      awaitRefetchQueries: true,
    }
  );
