import { gql, useQuery } from "@apollo/client";
import { GetTags } from "../__generated__/GetTags";
import { TAG_FRAGMENT } from "../fragments/tag";

export const GET_TAGS = gql`
  ${TAG_FRAGMENT}
  query GetTags {
    getTags {
      ...TagFragment
    }
  }
`;

export const useTags = () => {
  const res = useQuery<GetTags>(GET_TAGS);
  return { ...res, data: res.data?.getTags };
};
