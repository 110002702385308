import { gql } from "@apollo/client";
import {
  GetRecruiterSearchUrl,
  GetRecruiterSearchUrlVariables,
} from "../__generated__/GetRecruiterSearchUrl";
import { client } from "../apollo";

export const GET_RECRUITER_SEARCH_URL = gql`
  query GetRecruiterSearchUrl($input: GetRecruiterSearchUrlInput!) {
    getRecruiterSearchUrl(input: $input) {
      url
      isDifferent
    }
  }
`;

export const getRecruiterSearchUrl = (
  variables: GetRecruiterSearchUrlVariables
) =>
  client.query<GetRecruiterSearchUrl, GetRecruiterSearchUrlVariables>({
    query: GET_RECRUITER_SEARCH_URL,
    variables,
  });

export {
  GetRecruiterSearchUrl,
  GetRecruiterSearchUrlVariables,
} from "../__generated__/GetRecruiterSearchUrl";
