import { gql } from "@apollo/client";
import { Login, LoginVariables } from "../__generated__/Login";
import { client } from "../apollo";

export type LoginResponse = Login;

export const LOGIN = gql`
  mutation Login($idToken: String!, $csrfToken: String!) {
    login(input: { idToken: $idToken, csrfToken: $csrfToken }) {
      success
      me {
        id
        displayName
        email
        photoUrl
      }
    }
  }
`;

export const loginWithIdTokenApollo = (variables: LoginVariables) => {
  return client.mutate<Login, LoginVariables>({
    mutation: LOGIN,
    variables,
    refetchQueries: ["GetMe"],
  });
};
