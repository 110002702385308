import { gql, useMutation } from "@apollo/client";
import {
  UpdateCompany,
  UpdateCompanyVariables,
} from "../__generated__/UpdateCompany";
import { COMPANY_FRAGMENT } from "fragments/candidate";

export const UPDATE_COMPANY = gql`
  ${COMPANY_FRAGMENT}
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      ...CompanyFragment
    }
  }
`;

export const useUpdateCompany = (variables?: UpdateCompanyVariables) =>
  useMutation<UpdateCompany, UpdateCompanyVariables>(UPDATE_COMPANY, {
    variables,
    refetchQueries: ["GetCompanies"],
    awaitRefetchQueries: true,
  });
