import React, { useState, useMemo, useEffect, useCallback } from "react";
import cn from "classnames";
import Creatable from "react-select/creatable";
import Select from "react-select";
import ExternalLinks from "./SidebarTimelineItemExternalLinks";

import { Button, Badge } from "solostar-components";
import { BriefcaseIcon, AcademicCapIcon } from "@heroicons/react/outline";
import { performSearch } from "utils/search";
import { array, RefreshCandidateData, events, colors } from "solostar-lib";
import { SourceType, emitSearchedSuggestion } from "utils/analytics";
import { isUrmOrg } from "utils/orgs";

import {
  DEFAULT_TAG_COLOR,
  MultiSelectOption,
  tagToSelectOption,
  useDefaultTags,
  getTagIds,
} from "utils/tags";

import {
  EventName,
  Position,
  Company,
  TagTarget,
  useAddInstitution,
  useUpdateInstitution,
  useUpdateCompany,
  useAddCompany,
  getCompany,
  Scope,
} from "solostar-graphql";
import { cleanGraphQl } from "utils/graphql";

type Props = {
  candidateDomId: string | null;
  candidateExternalId: string | null;
  type: SourceType;
  position: Position;
  isActive?: boolean;
  isInactive?: boolean;
  onClick?: () => void;
};

const getDefaultSelectivity = (position: Position) =>
  position.source?.selectivity
    ? tagToSelectOption(position.source.selectivity, isUrmOrg(position.source))
    : undefined;

const getDefaultTags = (position: Position) =>
  (position.source?.tags || []).map((t) => tagToSelectOption(t));

const HistoryItem: React.FC<Props> = (props) => {
  const {
    candidateDomId,
    candidateExternalId,
    type,
    position,
    onClick,
    isActive,
    isInactive,
  } = props;

  const { domId, title, location, source, searchIdeas } = position;

  const tagTarget =
    type === "Company" ? TagTarget.COMPANY : TagTarget.INSTITUTION;
  const tags = useDefaultTags(tagTarget);

  const [selectivity, setSelectivity] = useState(
    getDefaultSelectivity(position)
  );

  const [customTags, setCustomTags] = useState(getDefaultTags(position));
  const [loading, setLoading] = useState(false);

  const resetState = useCallback(() => {
    setSelectivity(getDefaultSelectivity(position));
    setCustomTags(getDefaultTags(position));
  }, [position]);

  useEffect(() => {
    if (isInactive) {
      resetState();
    }
  }, [isInactive, resetState]);

  const Icon = type === "Company" ? BriefcaseIcon : AcademicCapIcon;

  const duration = `${position.duration?.start?.year || "Unknown"} - ${
    position.duration?.end?.year ||
    (position.duration?.end?.now ? "Present" : "Unknown")
  }`;

  const hasRecommendations = (searchIdeas?.length || 0) > 0;

  const areTagsDirty = useMemo(() => {
    const selectivityDifferent = selectivity?.value !== source?.selectivity?.id;
    const oldTags = (source?.tags?.map((tag) => tag.id) || []).sort();
    const newTags = customTags.map((tag) => tag.value).sort();
    const tagsSame =
      oldTags.every((id, idx) => id === newTags[idx]) &&
      newTags.every((id, idx) => id === oldTags[idx]);

    return selectivityDifferent || !tagsSame;
  }, [customTags, source, selectivity]);

  const [addCompany] = useAddCompany();
  const [updateCompany] = useUpdateCompany();
  const [addInstitution] = useAddInstitution();
  const [updateInstitution] = useUpdateInstitution();

  const onSave = useCallback(async () => {
    setLoading(true);

    const existing = source;

    /** Create any new tags the user might have added. */
    const tagIds = await getTagIds(customTags, Scope.USER, tagTarget);

    if (tagTarget === TagTarget.COMPANY) {
      const company = existing as Company;
      if (existing?.scope === Scope.USER) {
        await updateCompany({
          variables: {
            input: {
              id: existing.id,
              scope: existing.scope,
              company: { selectivityTagId: selectivity?.value || null, tagIds },
            },
          },
        });
      } else if (position.location) {
        let levels = company?.levels;
        if (company?.id && !levels) {
          const res = await getCompany({ id: company.id });
          levels = res.data.getCompany?.levels || null;
        }
        await addCompany({
          variables: {
            input: {
              scope: Scope.USER,
              selectivityTagId: selectivity?.value,
              displayName: position.location,
              tagIds,
              levels: levels?.map((l) => cleanGraphQl(l as any)),
              aliases: [],
              feeders: [],
            },
          },
        });
      }
    } else if (tagTarget === TagTarget.INSTITUTION) {
      if (existing?.scope === Scope.USER) {
        await updateInstitution({
          variables: {
            input: {
              id: existing.id,
              scope: existing.scope,
              institution: {
                selectivityTagId: selectivity?.value || null,
                tagIds,
              },
            },
          },
        });
      } else if (position.location) {
        await addInstitution({
          variables: {
            input: {
              scope: Scope.USER,
              selectivityTagId: selectivity?.value,
              displayName: position.location,
              country: "",
              tagIds,
              aliases: [],
            },
          },
        });
      }
    }

    if (candidateDomId) {
      const data: RefreshCandidateData = {
        domId: candidateDomId,
        activeItemId: position.domId || undefined,
      };
      events.emit({ name: EventName.SS_REFRESH_CANDIDATE, data }, "top");
    }

    /**
     * We don't want to hide the loading icon until the candidate's information
     * is refreshed, but because the `events.emit` function above is not a promise
     * that definitely resolves when the refresh is complete, we have to "guess."
     * Hard-coding the wait time to 1500ms for now.
     */
    setTimeout(() => setLoading(false), 1500);
  }, [
    updateCompany,
    addCompany,
    updateInstitution,
    addInstitution,
    customTags,
    position,
    source,
    selectivity,
    tagTarget,
    candidateDomId,
  ]);

  const isUrm = isUrmOrg(source);
  const tagsToShow = [...(source?.tags || []), source?.selectivity]
    .filter(array.isNotEmpty)
    .filter((tag) => !tag.hidden);

  return (
    <div
      id={domId || undefined}
      className={cn({
        "ss__history my-6 px-4 last:mb-0": true,
        "opacity-20": isInactive,
      })}
    >
      <div className="flex">
        <div
          className="ss__history__type mr-2 flex justify-center cursor-pointer"
          onClick={onClick}
        >
          <div>
            <span
              className={cn({
                "bg-gray-400 h-6 w-6 rounded-full flex items-center justify-center ring-6 ring-white":
                  true,
                "bg-indigo-600": isActive,
              })}
            >
              <Icon className="h-3 w-3 text-white" aria-hidden="true" />
            </span>
          </div>
        </div>
        <div
          className={cn({
            ss__history__details: true,
            flex: true,
            "flex-auto": true,
            transition: true,
          })}
        >
          <div className="ss__history__details__lt flex-auto">
            <h2 className="text-sm font-medium">{title}</h2>
            <div className="flex flex-wrap items-center">
              <h3 className="text-sm text-gray-500 mr-2 mb-0.5">{location}</h3>
              {tagsToShow.map((tag) => (
                <Badge key={tag.id} color={isUrm ? colors.URM : tag.color}>
                  {tag.displayName}
                </Badge>
              ))}
              {!tagsToShow.length && isUrm && (
                <Badge color={colors.URM}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Badge>
              )}
              {/* Show external links for this location. */}
              <ExternalLinks position={position} />
            </div>
            <p className="text-xs text-gray-500">{duration}</p>
          </div>
          <div className="ss__history__details__rt"></div>
        </div>
      </div>
      {isActive && (
        <>
          <div className="ss__history__suggestions mt-4 rounded-md flex-auto w-full bg-gray-100 p-4">
            <h4 className="text-xs font-medium mb-2">
              <span className="mr-2">🚀</span>
              {location} Selectivity
            </h4>
            <div className="">
              <Select
                options={tags.default.map((tag) =>
                  tagToSelectOption(tag, isUrm)
                )}
                value={selectivity}
                onChange={(tag) => setSelectivity(tag || undefined)}
                isClearable={true}
              />
            </div>
            <h4 className="text-xs font-medium mt-4 mb-2">
              <span className="mr-2">🏷</span>
              {location} Tags
            </h4>
            <div className="">
              <Creatable
                isMulti={true}
                options={tags.custom.map((tag) => tagToSelectOption(tag))}
                value={customTags}
                onChange={(tags) => setCustomTags(tags as MultiSelectOption[])}
                filterOption={(item, inputValue) =>
                  item.data.displayName
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                getNewOptionData={(inputValue) => ({
                  value: inputValue,
                  label: <Badge color={DEFAULT_TAG_COLOR}>{inputValue}</Badge>,
                  displayName: inputValue,
                  isNew: true,
                })}
              />
            </div>
            {areTagsDirty && (
              <Button
                size="small"
                loading={loading}
                className="mt-4"
                onClick={onSave}
              >
                Save
              </Button>
            )}
          </div>
          {hasRecommendations && (
            <div className="ss__history__suggestions mt-4 rounded-md flex-auto w-full bg-gray-100">
              <div className={cn(["p-4"])}>
                <h4 className="text-xs font-medium mb-2">
                  <span className="mr-2">⭐</span>Search Ideas
                </h4>
                <div className="flex flex-wrap">
                  {searchIdeas?.map((rec) => (
                    <Button
                      key={rec.id}
                      type="text"
                      onClick={() => {
                        const payload = rec.fields
                          ? { fields: rec.fields, openInNewTab: true }
                          : rec.queries;
                        performSearch(payload);
                        emitSearchedSuggestion({
                          name: rec.name,
                          sourceType: type,
                          sourceName: position.location || undefined,
                          candidateExternalId: candidateExternalId || undefined,
                        });
                      }}
                      className="mr-2 mb-1 px-0 py-0"
                    >
                      {rec.name} →
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HistoryItem;
