import React from "react";
import Login from "pages/Login";
import SidebarHeader from "pages/Sidebar/SidebarHeader";

const SidebarNotLoggedIn: React.FC = () => {
  return (
    <>
      <SidebarHeader />
      <div className="p-4">
        <Login
          className="h-full"
          description="SoloStar's advanced searching techniques supercharge sourcing and make it easy to find diverse and underrepresented candidates."
        />
      </div>
    </>
  );
};

export default SidebarNotLoggedIn;
