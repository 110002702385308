import React from "react";

const SidebarOnWeb: React.FC = () => {
  return (
    <div className="center">
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="/logo192.png"
              alt="SoloStar Logo"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 leading-tight">
              You're Signed In!
            </h2>
            <p className="mt-8 text-center text-lg text-gray-600">
              Now you can go to{" "}
              <a className="underline" href="https://www.linkedin.com/talent">
                LinkedIn Recruiter
              </a>{" "}
              or{" "}
              <a
                className="underline"
                href="https://business.linkedin.com/talent-solutions/recruiter-lite"
              >
                Recruiter Lite
              </a>{" "}
              and refresh the existing page or start a new search!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarOnWeb;
