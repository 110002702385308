import React, { useMemo } from "react";
import cn from "classnames";

import { ExclamationCircleIcon } from "@heroicons/react/solid";

export type InputType = "input" | "textarea";
export type InputStatus = "error" | "success" | "default";
export type InputSize = "small" | "default";
export type InputValidation = {
  status: InputStatus;
  text?: string;
};
interface IProps {
  value: string;
  setValue: (value: string) => void;
  validation?: InputValidation;
  placeholder?: string;
  size?: InputSize;
  type?: InputType;
}

export const INPUT_TYPE_CLASSNAMES: Record<InputStatus, string> = {
  error:
    "focus:ring-red-500 border-red-300 focus:border-red-500 text-red-900 focus:outline-none placeholder-red-300",
  default:
    "focus:ring-indigo-500 border-gray-300 focus:border-indigo-500 focus:outline-none",
  success:
    "focus:ring-green-500 border-red-300 focus:border-green-500 text-green-900 focus:outline-none placeholder-green-300",
};

export const INPUT_SIZE_CLASSNAMES: Record<InputSize, string> = {
  small: "block w-full p-2 pr-10 rounded-md text-sm sm:text-sm",
  default: "block w-full p-2 pr-10 rounded-md sm:text-sm",
};

const InputElement: React.FC<any & { type: InputType }> = (props) => {
  const { type, ...inputProps } = props;
  switch (type) {
    case "textarea":
      return <textarea type="text" rows={12} {...inputProps} />;
    default:
      return <input type="text" {...inputProps} />;
  }
};

const Input: React.FC<IProps> = (props) => {
  const {
    value,
    setValue,
    placeholder,
    validation,
    size = "default",
    type = "input",
  } = props;

  const { status, text } = validation || {
    status: "default",
  };

  const inputClassName = useMemo(() => {
    return `${INPUT_TYPE_CLASSNAMES[status]} ${INPUT_SIZE_CLASSNAMES[size]}`;
  }, [status, size]);

  const isError = status === "error";
  return (
    <div>
      <div className="mt-1 relative rounded-md shadow-sm">
        <InputElement
          type={type}
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
          className={inputClassName}
          placeholder={placeholder}
        />
        <div className="absolute inset-y-0 right-0 pr-3 pt-2 flex pointer-events-none">
          {isError && (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
      {isError && (
        <p
          className={cn([
            "mt-2",
            "text-xs",
            isError ? "text-red-600" : "text-green-600",
          ])}
        >
          {text}
        </p>
      )}
    </div>
  );
};

export default Input;
