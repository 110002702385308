import React from "react";
import { Link } from "react-router-dom";
import { Button } from "solostar-components";
import EmptyState from "./EmptyState";

interface GoBackButtonProps {
  type?: "companies" | "institutions" | "candidates";
  id?: string;
}

export const GoBackButton: React.FC<GoBackButtonProps> = (props) => {
  const { id, type = "companies" } = props;

  return (
    <Link
      className="border-gray-300 mb-4 bg-white hover:bg-gray-50 focus:outline-none focus:ring-indigo-500 inline-flex items-center border rounded-md px-4 py-2 text-sm shadow-sm font-medium focus:ring-2 focus:ring-offset-2"
      to={`/ai/${type}/${id}`}
    >
      Go Back
    </Link>
  );
};

interface EmptyStateProps extends GoBackButtonProps {
  title: string;
  description: string;
}

export const EmptyStateGoBack: React.FC<EmptyStateProps> = (props) => {
  const { type, id, title, description } = props;

  return (
    <div className="p-8">
      <GoBackButton type={type} id={id} />
      <div className="-m-8 w-screen h-screen flex items-center justify-center">
        <EmptyState title={title} description={description} />
      </div>
    </div>
  );
};

export default GoBackButton;
