import { gql, useMutation } from "@apollo/client";
import { Checkout, CheckoutVariables } from "../__generated__/Checkout";
import { client } from "../apollo";
import {
  ManageBilling,
  ManageBillingVariables,
} from "../__generated__/ManageBilling";

export const CHECKOUT = gql`
  mutation Checkout($input: CheckoutInput!) {
    checkout(input: $input)
  }
`;

export const MANAGE_BILLING = gql`
  mutation ManageBilling($input: ManageBillingInput!) {
    manageBilling(input: $input)
  }
`;

export const useCheckout = () =>
  useMutation<Checkout, CheckoutVariables>(CHECKOUT);

export const checkout = (variables: CheckoutVariables) =>
  client.mutate<Checkout, CheckoutVariables>({ mutation: CHECKOUT, variables });

export const useManageBilling = () =>
  useMutation<ManageBilling, ManageBillingVariables>(MANAGE_BILLING);

export const manageBilling = (variables: ManageBillingVariables) =>
  client.mutate<ManageBilling, ManageBillingVariables>({
    mutation: MANAGE_BILLING,
    variables,
  });
