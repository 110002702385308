import { getSearchId } from "pages/Sidebar/CompanyCompetitors";
import SidebarHeader from "pages/Sidebar/SidebarHeader";
import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Company,
  ContactInfoType,
  useCompany,
  useCompanyFeeders,
  useMe,
} from "solostar-graphql";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { isExtensionOrPopup } from "utils/env";
import { Menu } from "@headlessui/react";
import { useNavigationHook } from "utils/navigationHook";

const getOptions = (
  compStockSymbol?: string,
  baseStockSymbol?: string,
  hasFeeders?: boolean
) => {
  const base = [
    {
      displayName: "Summary",
      emoji: "📔",
      description: "View an AI-generated summary of this company.",
      role: "AI ✨",
      href: "summary",
    },
    {
      displayName: "Competitors",
      emoji: "🪢",
      description:
        "View an AI-generated list of direct competitors of this company.",
      role: "AI ✨",
      href: "competitors",
    },
    {
      displayName: "Company Layoffs",
      emoji: "🚨",
      description: "A list of layoffs this company has recently done.",
      role: "",
      href: "layoffs",
    },
  ];
  if (hasFeeders) {
    base.push({
      displayName: "Feeder Companies",
      emoji: "🐠",
      description: "View companies who often feed candidates to this one.",
      role: "",
      href: "feeders",
    });
  }
  if (compStockSymbol) {
    base.push({
      displayName: "Recent Press",
      emoji: "📰",
      description: "Recently published articles about this company.",
      role: "",
      href: "articles",
    });
  }
  if (compStockSymbol) {
    const description = `A ${
      baseStockSymbol ? `comparison` : "view"
    } of this company's stock performance ($${compStockSymbol})${
      compStockSymbol ? ` compared to your company ($${baseStockSymbol})` : ""
    }.`;
    base.push({
      displayName: "Stock Performance",
      emoji: "📈",
      description,
      role: "AI ✨",
      href: "stocks",
    });
  }
  return base;
};

export const getStockSymbolFromCompany = (company?: Company | null) => {
  const finance = company?.contactInfos?.find(
    (info) => info.type === ContactInfoType.LINK_GOOGLE_FINANCE
  );
  return finance?.value?.split("quote/").pop()?.split(":").shift();
};

export const Options: React.FC<{
  companyId?: string;
}> = (props) => {
  const { companyId } = props;
  useNavigationHook();
  const id = getSearchId(companyId);

  const { data: me } = useMe();
  const { data: company } = useCompany(
    { id: id?.id ?? "" },
    { skip: !id || id.type !== "lookup" }
  );
  const { data: feeders } = useCompanyFeeders(
    { id: id?.id ?? "" },
    { skip: id?.type !== "lookup" }
  );

  if (!company || !me) {
    return null;
  }

  const compStockSymbol = getStockSymbolFromCompany(company);
  const baseStockSymbol = me?.tenant?.stockSymbol ?? undefined;
  const options = getOptions(
    compStockSymbol,
    baseStockSymbol,
    !!feeders?.length
  );

  return (
    <div className="">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
      <div className="bg-white px-4 py-5 sm:px-6 lg:px-8">
        <div className="flex space-x-3 items-center">
          {!!company.url && (
            <div className="w-12 h-12 bg-gray-200">
              <img
                className="bg-cover"
                alt="clearbit logo"
                src={`https://logo.clearbit.com/${company.url}`}
              />
            </div>
          )}
          <div className="min-w-0 flex-1">
            <p className="font-semibold text-gray-900">{company.displayName}</p>
          </div>
          <div className="flex flex-shrink-0 self-center">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                  <span className="sr-only">Open options</span>
                </Menu.Button>
              </div>
            </Menu>
          </div>
        </div>
      </div>
      <ul className="divide-y divide-gray-100">
        {options.map((opt) => (
          <li
            key={opt.displayName}
            className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 lg:px-8"
          >
            <div className="flex min-w-0 gap-x-4">
              <div className="h-12 w-12 flex-none rounded-full text-2xl flex items-center justify-center bg-gray-100">
                {opt.emoji}
              </div>
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  <Link to={opt.href}>
                    <span className="absolute inset-x-0 -top-px bottom-0" />
                    {opt.displayName}
                  </Link>
                </p>
                <p className="text-sm flex leading-5 text-gray-500">
                  {opt.description}
                </p>
              </div>
            </div>
            <div className="flex shrink-0 items-center gap-x-4">
              <div className="hidden sm:flex sm:flex-col sm:items-end">
                <p className="text-sm leading-6 text-gray-900">{opt.role}</p>
              </div>
              <ChevronRightIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const CompaniesOptions: React.FC = () => {
  const { companyId } = useParams<{ companyId?: string }>();
  return <Options companyId={companyId} />;
};

export default CompaniesOptions;
