import Loader from "components/Loader";
import React, { useState } from "react";
import GoBackButton, { EmptyStateGoBack } from "components/AIGoBackHeader";
import SidebarHeader from "pages/Sidebar/SidebarHeader";
import SidebarEditKeywordsSidesheet from "pages/Sidebar/SidebarEditKeywordsSidesheet";

import { useNavigate, useParams } from "react-router-dom";
import { isExtensionOrPopup } from "utils/env";
import { useNavigationHook } from "utils/navigationHook";
import { useFakeLoading } from "pages/Sidebar/CompanyCompetitors";
import { Button } from "solostar-components";

import {
  SearchFieldType,
  useInstitution,
  useSimilarInstitutions,
} from "solostar-graphql";

export const SimilarInstitutions: React.FC<{
  id?: string;
}> = (props) => {
  const { id } = props;
  useNavigationHook();

  const fakeLoading = useFakeLoading();
  const navigate = useNavigate();

  const [saveOpen, setSaveOpen] = useState(false);

  const { data: institution, loading: l1 } = useInstitution(
    { id: id ?? "" },
    { skip: !id }
  );

  const { data, loading: l2 } = useSimilarInstitutions(
    { id: id ?? "" },
    { skip: !id }
  );

  if (l1 || l2 || fakeLoading) {
    return (
      <div>
        {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
        <div className="w-screen h-screen flex flex-col items-center justify-center">
          <Loader />
          <div className="mb-2 mt-8 animate-pulse flex items-center">
            <img
              className="w-8 mr-1 object-contain animate-pulse"
              src="/logo192.png"
              alt="SoloStar Logo"
            />
            <span className="text-xl font-medium">SoloStar</span>
          </div>
          <p className="p-8 text-center">
            SoloStar AI is thinking 🤔...we're looking up some institutions
            similar to <b>{institution?.displayName}</b> in{" "}
            <b>{institution?.country}</b>.
          </p>
        </div>
      </div>
    );
  }

  if (!institution || !data) {
    return (
      <EmptyStateGoBack
        id={id}
        type="institutions"
        title="No Similar Institutions Found"
        description="Sorry, we couldn't find the top programs for this institution."
      />
    );
  }

  return (
    <div className="">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
      <div className="p-8">
        <GoBackButton type="institutions" id={id} />
        <SidebarEditKeywordsSidesheet
          searchGroup={null}
          onFinishSave={() => navigate("/")}
          defaultValues={{
            id: "1",
            name: `Schools Similar to ${institution.displayName}`,
            category: "Similar Institutions",
          }}
          defaultFields={[
            {
              type: SearchFieldType.VALUE_SINGLE,
              replaceCurrent: false,
              data: {
                key: "keywords",
                value: data.map((c) => c.displayName).join(", "),
                union: false,
              },
            },
          ]}
          isOpen={saveOpen}
          onRequestClose={() => {
            setSaveOpen(false);
          }}
        />

        <p className="">
          Institutions similar to <b>{institution.displayName}</b> in{" "}
          <b>{institution.country}</b> include:
        </p>
        <ol className="flex flex-wrap gap-1 my-8">
          {data.map((c) => (
            <li
              key={c.displayName}
              className="p-2 w-full bg-gray-100 flex items-center gap-2 border border-gray-200 overflow-hidden text-sm"
            >
              {c.url && (
                <img
                  alt="clearbit logo"
                  src={`https://logo.clearbit.com/${c.url}`}
                  className="w-8 h-8 rounded-md object-contain"
                />
              )}
              {c.url ? (
                <a
                  href={c.url}
                  className="text-sm underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>{c.displayName}</b>
                </a>
              ) : (
                <b>{c.displayName}</b>
              )}
            </li>
          ))}
        </ol>
        <Button onClick={() => setSaveOpen(true)}>Save as Search Query</Button>
      </div>
    </div>
  );
};

const InstitutionSimilar: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  return <SimilarInstitutions id={id} />;
};

export default InstitutionSimilar;
