import React, { useState, useCallback, useMemo, useEffect } from "react";

import { CheckCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";

import {
  getScopeOptions,
  getTagOptions,
  getTagIds,
  useTagsSelect,
  useSelectivitySelect,
} from "utils/tags";

import {
  Institution,
  Scope,
  TagTarget,
  useMe,
  useTags,
  useAddInstitution,
  useUpdateInstitution,
} from "solostar-graphql";

import {
  Button,
  Sidesheet,
  RadioGroup,
  Input,
  InputValidation,
} from "solostar-components";

const TARGET = TagTarget.INSTITUTION;

type InstitutionsSidesheetProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  institution?: Institution;
};

type Validation = {
  displayName?: InputValidation;
  country?: InputValidation;
};

const InstitutionsSidesheet: React.FC<InstitutionsSidesheetProps> = (props) => {
  const { isOpen, onRequestClose, institution } = props;
  const { data: me } = useMe();
  const { data: tagsData } = useTags();

  const { tags, setTags, tagsSelect } = useTagsSelect(TARGET, tagsData);

  const { selectivity, setSelectivity, selectivitySelect } =
    useSelectivitySelect(TARGET, tagsData);

  const scopeOptions = useMemo(
    () => getScopeOptions(TARGET, me?.tenant?.displayName || me?.tenant?.id),
    [me]
  );

  const [validationEnabled, setValidationEnabled] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [country, setCountry] = useState("");
  const [scope, setScope] = useState(scopeOptions[0].id);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (institution) {
      setDisplayName(institution.displayName);
      setTags(getTagOptions(TARGET, institution.tags ?? []));
      setScope(institution.scope || scopeOptions[0].id);
      setCountry(institution.country || "");
      if (institution.selectivity) {
        const tags = getTagOptions(TARGET, [institution.selectivity], true);
        setSelectivity(tags[0] || null);
      } else {
        setSelectivity(null);
      }
    }
  }, [institution, scopeOptions, setSelectivity, setTags]);

  const [addInstitution] = useAddInstitution();
  const [updateInstitution] = useUpdateInstitution();

  const validate = useCallback(() => {
    const validation: Validation = {};
    if (!displayName) {
      validation.displayName = {
        status: "error",
        text: "You must provide a name for this institution.",
      };
    }
    if (!country) {
      validation.country = {
        status: "error",
        text: "You must specify the country this institution is located in.",
      };
    }
    return validation;
  }, [displayName, country]);

  const validation = useMemo(() => {
    if (validationEnabled) {
      return validate();
    }
    return {};
  }, [validate, validationEnabled]);

  const onCreate = useCallback(async () => {
    setValidationEnabled(true);
    const validation = validate();
    if (Object.keys(validation).length > 0) {
      return;
    }
    setLoading(true);

    /** Create any new tags the user might have added. */
    const tagIds = await getTagIds(tags, scope, TARGET);
    if (institution) {
      await updateInstitution({
        variables: {
          input: {
            id: institution.id,
            scope: institution.scope || Scope.USER,
            institution: {
              displayName,
              tagIds,
              selectivityTagId: selectivity?.value || null,
            },
          },
        },
      });
    } else {
      await addInstitution({
        variables: {
          input: {
            displayName,
            scope,
            tagIds,
            country,
            selectivityTagId: selectivity?.value,
            aliases: [],
          },
        },
      });
    }
    setLoading(false);
    onRequestClose();
  }, [
    institution,
    updateInstitution,
    addInstitution,
    onRequestClose,
    validate,
    displayName,
    scope,
    tags,
    country,
    selectivity,
  ]);

  return (
    <Sidesheet
      title={`${institution ? "Edit" : "Create"} Institution`}
      description="Once you create custom institutions, any tags you add to them will show up in the LinkedIn UI."
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      buttons={[
        <Button type="secondary" onClick={onRequestClose} key="cancel">
          Cancel
        </Button>,
        <Button
          icon={institution ? CheckCircleIcon : PlusCircleIcon}
          onClick={onCreate}
          loading={loading}
          key="create"
        >
          {institution ? "Save" : "Create"}
        </Button>,
      ]}
    >
      <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
        <Sidesheet.FormItem label="Institution Name">
          <Input
            value={displayName}
            setValue={setDisplayName}
            placeholder="What is the institution's name?"
            validation={validation.displayName}
          />
        </Sidesheet.FormItem>

        <Sidesheet.FormItem label="Country">
          <Input
            value={country}
            setValue={setCountry}
            placeholder="What country is this institution located in?"
            validation={validation.country}
          />
        </Sidesheet.FormItem>

        {!institution && (
          <Sidesheet.FormItem label="Scope">
            <RadioGroup
              options={scopeOptions}
              value={scope}
              onChange={setScope}
            />
          </Sidesheet.FormItem>
        )}

        <Sidesheet.FormItem label="Selectivity" htmlFor="Selectivity">
          {selectivitySelect}
        </Sidesheet.FormItem>

        <Sidesheet.FormItem label="Tags" htmlFor="tags">
          {tagsSelect}
        </Sidesheet.FormItem>
      </div>
    </Sidesheet>
  );
};

export default InstitutionsSidesheet;
