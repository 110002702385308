import SidebarHeader from "pages/Sidebar/SidebarHeader";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useInstitution, useMe } from "solostar-graphql";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { isExtensionOrPopup } from "utils/env";
import { Menu } from "@headlessui/react";
import { useNavigationHook } from "utils/navigationHook";

const getOptions = () => {
  const base = [
    {
      displayName: "Summary",
      emoji: "📔",
      description:
        "View an AI-generated summary of what this insitution is known for and the types of candidates it generally produces.",
      role: "AI ✨",
      href: "summary",
    },
    {
      displayName: "Top Programs",
      emoji: "📚",
      description:
        "View an AI-generated list of the educational programs this institution is known for.",
      role: "AI ✨",
      href: "programs",
    },
    {
      displayName: "Similar Top-Tier Institutions",
      emoji: "🏫",
      description:
        "A list of highly rated institutions that are based in the same country.",
      role: "AI ✨",
      href: "similar",
    },
    {
      displayName: "Notable Research Labs",
      emoji: "🚀",
      description:
        "A list of the more successful research labs based within this instutition.",
      role: "",
      href: "research",
    },
  ];
  return base;
};

export const Options: React.FC<{
  id?: string;
}> = (props) => {
  const { id } = props;
  useNavigationHook();

  const { data: me } = useMe();
  const { data: institution } = useInstitution({ id: id ?? "" }, { skip: !id });

  if (!institution || !me) {
    return null;
  }
  const options = getOptions();

  return (
    <div className="">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
      <div className="bg-white px-4 py-5 sm:px-6 lg:px-8">
        <div className="flex space-x-3 items-center">
          {!!institution.url && (
            <div className="w-12 h-12 bg-gray-200">
              <img
                className="bg-cover"
                alt="clearbit logo"
                src={`https://logo.clearbit.com/${institution.url}`}
              />
            </div>
          )}
          <div className="min-w-0 flex-1">
            <p className="font-semibold text-gray-900">
              {institution.displayName}
            </p>
          </div>
          <div className="flex flex-shrink-0 self-center">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-400 hover:text-gray-600">
                  <span className="sr-only">Open options</span>
                </Menu.Button>
              </div>
            </Menu>
          </div>
        </div>
      </div>
      <ul className="divide-y divide-gray-100">
        {options.map((opt) => (
          <li
            key={opt.displayName}
            className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 lg:px-8"
          >
            <div className="flex min-w-0 gap-x-4">
              <div className="h-12 w-12 flex-none rounded-full text-2xl flex items-center justify-center bg-gray-100">
                {opt.emoji}
              </div>
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  <Link to={opt.href}>
                    <span className="absolute inset-x-0 -top-px bottom-0" />
                    {opt.displayName}
                  </Link>
                </p>
                <p className="text-sm flex leading-5 text-gray-500">
                  {opt.description}
                </p>
              </div>
            </div>
            <div className="flex shrink-0 items-center gap-x-4">
              <div className="hidden sm:flex sm:flex-col sm:items-end">
                <p className="text-sm leading-6 text-gray-900">{opt.role}</p>
              </div>
              <ChevronRightIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const InstitutionsOptions: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  return <Options id={id} />;
};

export default InstitutionsOptions;
