import React from "react";
import { colors } from "solostar-lib";

interface IProps {
  color: string;
}

const Badge: React.FC<IProps> = (props) => {
  const { color } = props;
  const textColor = colors.getInvertedColor(color);
  return (
    <span
      className="inline-flex items-center h-fit px-1 py-0.5 rounded text-xs bg-gray-100 font-medium uppercase text-gray-800 mr-1 align-text-top"
      style={{ backgroundColor: color, color: textColor }}
    >
      {props.children}
    </span>
  );
};

export default Badge;
