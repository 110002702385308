import { gql, useMutation } from "@apollo/client";
import {
  DeleteInstitution,
  DeleteInstitutionVariables,
} from "../__generated__/DeleteInstitution";
import { DeleteAllInstitutions } from "../__generated__/DeleteAllInstitutions";

export const DELETE_INSTITUTION = gql`
  mutation DeleteInstitution($input: DeleteInstitutionInput!) {
    deleteInstitution(input: $input)
  }
`;

export const DELETE_ALL_INSTITUTIONS = gql`
  mutation DeleteAllInstitutions {
    deleteAllInstitutions
  }
`;

export const useDeleteInstitution = (variables?: DeleteInstitutionVariables) =>
  useMutation<DeleteInstitution, DeleteInstitutionVariables>(
    DELETE_INSTITUTION,
    {
      variables,
      refetchQueries: ["GetInstitutions"],
      awaitRefetchQueries: true,
    }
  );

export const useDeleteAllInstitutions = () =>
  useMutation<DeleteAllInstitutions>(DELETE_ALL_INSTITUTIONS, {
    refetchQueries: ["GetInstitutions"],
    awaitRefetchQueries: true,
  });
