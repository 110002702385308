import React, { useMemo, useState } from "react";
import Login from "pages/Login";

import AnalyticsChart, {
  AnalyticsFacetOption,
  FACET_KEYWORD_ALL,
  FACET_KEYWORD_NO_FILTER,
} from "components/AnalyticsChart";

import { Popover } from "@headlessui/react";
import { Datepicker } from "@tremor/react";
import { Dropdown, DropdownItem } from "@tremor/react";
import { subMonths } from "date-fns";
import { Toggle, ToggleItem } from "@tremor/react";
import { useDebounce } from "use-debounce";

import {
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/outline";

import {
  useMe,
  AnalyticsEvent,
  TimeSeriesGranularity,
  Scope,
  AnalyticsFacet,
} from "solostar-graphql";

type FilterDate = null | Date;
type FilterName = "granularity" | "tenant" | "scope";
type FilterOption = { label: string; value: string };
type Filter = {
  name: string;
  options: FilterOption[];
};

const FILTERS: Record<string, Filter> = {
  granularity: {
    name: "Granularity",
    options: [
      { value: TimeSeriesGranularity.DAY, label: "Day" },
      { value: TimeSeriesGranularity.WEEK, label: "Week" },
      { value: TimeSeriesGranularity.MONTH, label: "Month" },
      { value: TimeSeriesGranularity.QUARTER, label: "Quarter" },
    ],
  },
};

const FACET_OPTIONS_VIEW_EVENTS: AnalyticsFacetOption[] = [
  {
    key: AnalyticsFacet.IS_SOLOSTAR_PICK,
    value: FACET_KEYWORD_ALL,
    displayName: FACET_KEYWORD_NO_FILTER,
  },
  {
    key: AnalyticsFacet.IS_SOLOSTAR_PICK,
    value: FACET_KEYWORD_ALL,
    displayName: "SoloStar Picks",
  },
  {
    key: AnalyticsFacet.IS_URM_CANDIDATE,
    value: FACET_KEYWORD_ALL,
    displayName: "URM Candidates",
  },
  {
    key: AnalyticsFacet.TOP_COMPANIES,
    value: "1",
    displayName: "At Least 1 Top Company",
  },
  {
    key: AnalyticsFacet.TOP_COMPANIES,
    value: "3",
    displayName: "At Least 3 Top Companies",
  },
  {
    key: AnalyticsFacet.TOP_INSTITUTIONS,
    value: "1",
    displayName: "At Least 1 Top Institution",
  },
  {
    key: AnalyticsFacet.TOP_INSTITUTIONS,
    value: "3",
    displayName: "At Least 3 Top Institutions",
  },
  { key: AnalyticsFacet.COUNTRY, value: "United States" },
  { key: AnalyticsFacet.COUNTRY, value: "Canada" },
  { key: AnalyticsFacet.COUNTRY, value: "Korea" },
  { key: AnalyticsFacet.COUNTRY, value: "China" },
  { key: AnalyticsFacet.COUNTRY, value: "Australia" },
  { key: AnalyticsFacet.COUNTRY, value: "Argentina" },
  { key: AnalyticsFacet.COUNTRY, value: "Brazil" },
  { key: AnalyticsFacet.COUNTRY, value: "Israel" },
  { key: AnalyticsFacet.COUNTRY, value: "Italy" },
  { key: AnalyticsFacet.COUNTRY, value: "United Kingdom" },
  { key: AnalyticsFacet.COUNTRY, value: "Morocco" },
  { key: AnalyticsFacet.COUNTRY, value: "Czech Republic" },
];

const FACET_OPTIONS_SEARCHED_KEYWORDS: AnalyticsFacetOption[] = [
  {
    key: AnalyticsFacet.SEARCH_GROUP_CATEGORY,
    value: FACET_KEYWORD_ALL,
    displayName: FACET_KEYWORD_NO_FILTER,
  },
  {
    key: AnalyticsFacet.SEARCH_GROUP_CATEGORY,
    value: "Black",
    displayName: "Used Black Keywords",
  },
  {
    key: AnalyticsFacet.SEARCH_GROUP_CATEGORY,
    value: "Women",
    displayName: "Used Women Keywords",
  },
  {
    key: AnalyticsFacet.SEARCH_GROUP_CATEGORY,
    value: "LatinX",
    displayName: "Used LatinX Keywords",
  },
  {
    key: AnalyticsFacet.SEARCH_GROUP_CATEGORY,
    value: "LGBQTIA+",
    displayName: "Used LGBQTIA Keywords",
  },
];

const FACET_OPTIONS_SEARCHED_SIMILAR_PROFILES: AnalyticsFacetOption[] = [
  {
    key: AnalyticsFacet.SIMILAR_PROFILE_SEARCH_TYPE,
    value: FACET_KEYWORD_ALL,
    displayName: FACET_KEYWORD_NO_FILTER,
  },
  {
    key: AnalyticsFacet.SIMILAR_PROFILE_SEARCH_TYPE,
    value: "Similar",
    displayName: "Searched Similar Profiles",
  },
  {
    key: AnalyticsFacet.SIMILAR_PROFILE_SEARCH_TYPE,
    value: "NonTraditional",
    displayName: "Searched Non-Traditional Profiles",
  },
];

const Analytics: React.FC = () => {
  const { data, error, loading } = useMe();

  const [dateRange, setDateRange] = useState<[FilterDate, FilterDate]>([
    subMonths(new Date(), 3),
    new Date(),
  ]);
  const [filters, setFilters] = useState<Record<FilterName, string | null>>({
    tenant: "",
    granularity: TimeSeriesGranularity.WEEK,
    scope: "1",
  });

  const inputOriginal = useMemo(() => {
    const scope = filters.scope === "0" ? Scope.USER : Scope.TENANT;
    return {
      granularity: filters.granularity as TimeSeriesGranularity,
      scope,
      start: dateRange[0],
      end: dateRange[1],
      tenant: filters.tenant || undefined,
    };
  }, [filters, dateRange]);
  const [input] = useDebounce(inputOriginal, 1000);

  const isAdmin = data?.email?.endsWith("solostar.io") || false;

  /** If user is not logged in, show the Login component. */
  if (!loading && error) {
    return (
      <div>
        <Login />
      </div>
    );
  }

  if (!data) {
    /** TO DO: Replace with a <Loader /> */
    return null;
  }

  return (
    <div className="-m-8 -mt-12 flex flex-col">
      <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 bg-white z-10">
        <div className="min-w-0 flex-1">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
            Analytics
          </h1>
        </div>
        <div className="flex items-center">
          <div className="flex items-center mr-6">
            <Toggle
              defaultValue={filters.scope}
              handleSelect={(scope) => setFilters({ ...filters, scope })}
            >
              <ToggleItem value={"0"} text="Just Me" icon={UserCircleIcon} />
              <ToggleItem
                value={"1"}
                text="Company (Total)"
                icon={UserGroupIcon}
              />
              <ToggleItem
                value={"2"}
                text="Company (By User)"
                icon={UsersIcon}
              />
            </Toggle>
          </div>
          <div className="flex items-center mr-6">
            <Datepicker
              placeholder="Date Range"
              handleSelect={(start, end) => setDateRange([start, end])}
              enableRelativeDates={false}
              defaultStartDate={dateRange[0]}
              defaultEndDate={dateRange[1]}
              color="blue"
              maxWidth="max-w-none"
              marginTop="mt-0"
            />
          </div>
          {isAdmin && (
            <div className="flex items-center mr-6">
              <p className="mr-3 text-sm font-medium text-gray-700">Tenant</p>
              <input
                value={filters.tenant || ""}
                onChange={(e) =>
                  setFilters({ ...filters, tenant: e.target.value })
                }
                type="text"
                name="tenant"
                id="tenant"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Ex: doordash"
              />
            </div>
          )}
          <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8">
            {Object.keys(FILTERS).map((sectionId) => {
              const id = sectionId as FilterName;
              const section = FILTERS[id];
              const selected = filters[id]
                ? section.options.find((o) => o.value === filters[id])
                : undefined;
              return (
                <div className="flex items-center" key={id}>
                  <p className="mr-3 text-sm font-medium text-gray-700">
                    {section.name}
                  </p>
                  <Dropdown
                    placeholder="Select a granularity"
                    defaultValue={selected?.value}
                    handleSelect={(e) =>
                      setFilters({ ...filters, granularity: e })
                    }
                    maxWidth="max-w-none"
                    marginTop="mt-0"
                  >
                    {section.options.map((option) => (
                      <DropdownItem
                        key={option.value}
                        value={option.value}
                        text={option.label}
                      />
                    ))}
                  </Dropdown>
                </div>
              );
            })}
          </Popover.Group>
        </div>
      </div>
      <div className="p-12 overflow-scroll max-h-full">
        <AnalyticsChart
          title="Viewed Search Result"
          input={{ ...input, event: AnalyticsEvent.VIEWED_SEARCH_RESULT }}
          shouldAggregate={filters.scope === "1"}
          maxSeries={8}
          facetOptions={FACET_OPTIONS_VIEW_EVENTS}
        />
        <AnalyticsChart
          title="Viewed Profile"
          input={{ ...input, event: AnalyticsEvent.VIEWED_PROFILE }}
          shouldAggregate={filters.scope === "1"}
          maxSeries={5}
          facetOptions={FACET_OPTIONS_VIEW_EVENTS}
        />
        <AnalyticsChart
          title="Opened SoloStar Search Suggestions Sidebar"
          input={{
            ...input,
            event: AnalyticsEvent.OPENED_SIDEBAR_FROM_DIVERSIFY_BUTTON,
          }}
          shouldAggregate={filters.scope === "1"}
          maxSeries={5}
        />
        <AnalyticsChart
          title="Searched Using SoloStar Suggestion"
          input={{
            ...input,
            event: AnalyticsEvent.SEARCHED_KEYWORDS,
          }}
          shouldAggregate={filters.scope === "1"}
          maxSeries={5}
          facetOptions={FACET_OPTIONS_SEARCHED_KEYWORDS}
        />
        <AnalyticsChart
          title="Searched Using SoloStar's Similar Profiles"
          input={{
            ...input,
            event: AnalyticsEvent.SEARCHED_SIMILAR_PROFILES,
          }}
          shouldAggregate={filters.scope === "1"}
          maxSeries={5}
          facetOptions={FACET_OPTIONS_SEARCHED_SIMILAR_PROFILES}
        />
      </div>
    </div>
  );
};

export default Analytics;
