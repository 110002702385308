import React from "react";
import Login from "pages/Login";
import SidebarHeader from "pages/Sidebar/SidebarHeader";

import { useMe, logout } from "solostar-graphql";
import { Button } from "solostar-components";

const Popup: React.FC = () => {
  const { data, error, loading } = useMe();

  /** If user is not logged in, show the Login component. */
  if (!loading && error) {
    return (
      <div>
        <SidebarHeader />
        <Login />
      </div>
    );
  }

  if (!data) {
    /** TO DO: Replace with a <Loader /> */
    return null;
  }

  return (
    <div>
      <SidebarHeader />
      <div className="w-screen center">
        <div className="inline-block w-64 align-bottom rounded-lg m-8 p-6 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100">
              {data.photoUrl && (
                <img
                  className="object-cover w-16 h-16 min-w-16 min-w-fit mr-4 rounded-full bg-gray-400 shadow-md"
                  src={data.photoUrl}
                  alt={data.email || undefined}
                />
              )}
            </div>
            <div className="mt-4 text-center sm:mt-5">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Hi, {data?.displayName?.split(" ")[0]}!
              </h3>
              <h3 className="text-sm text-gray-600 mb-4 mt-1">{data?.email}</h3>
              <div className="mt-6">
                <p className="text-sm text-gray-500">
                  You're signed in! Feel free to{" "}
                  <a
                    className="underline text-blue-500"
                    href="mailto:support@solostar.io"
                  >
                    reach out if you need anything
                  </a>
                  .
                </p>
              </div>
              <div className="mt-24">
                <Button
                  type="secondary"
                  onClick={() => {
                    logout();
                  }}
                >
                  Sign Out
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
