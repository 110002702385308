import Loader from "components/Loader";
import React from "react";
import { useParams } from "react-router-dom";
import { useCompany, useStockPrice, useMe } from "solostar-graphql";
import { isExtensionOrPopup } from "utils/env";
import SidebarHeader from "./SidebarHeader";
import { getSearchId, useFakeLoading } from "./CompanyCompetitors";
import { getStockSymbolFromCompany } from "pages/AI/Companies/CompaniesOptions";
import classNames from "classnames";
import { useNavigationHook } from "utils/navigationHook";
import FakeStockChart from "components/FakeStockChart";
import GoBackButton, { EmptyStateGoBack } from "components/AIGoBackHeader";

const getFormattedDiff = (n1: number, n2: number) => {
  const change = Math.round((n1 / n2 - 1) * 100);

  if (change === 0) {
    return <span className="text-gray-400">-</span>;
  }
  return (
    <span
      className={classNames(change > 0 ? "text-green-600" : "text-red-600")}
    >
      {change > 0 ? "+" : ""}
      {change}%
    </span>
  );
};

export const StockComparison: React.FC<{
  companyId?: string;
  onSaveQuery?: () => void;
}> = (props) => {
  const { companyId } = props;
  useNavigationHook();
  const id = getSearchId(companyId);

  const { data: me, loading: l2 } = useMe();
  const { data: company, loading: l1 } = useCompany(
    { id: id?.id ?? "" },
    { skip: !id || id.type !== "lookup" }
  );

  const compSymbol = getStockSymbolFromCompany(company);
  const baseSymbol = me?.tenant?.stockSymbol ?? undefined;

  const { data: comp, loading: l3 } = useStockPrice(
    compSymbol ?? "",
    !compSymbol
  );

  const { data: base, loading: l4 } = useStockPrice(
    baseSymbol ?? "",
    !baseSymbol
  );

  const comp1YearAgo = comp?.[comp?.length - 1];
  const base1YearAgo = base?.[base?.length - 1];
  const fakeLoading = useFakeLoading();

  if (l1 || l2 || l3 || l4 || fakeLoading) {
    return (
      <div>
        {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
        <div className="w-screen h-screen flex flex-col items-center justify-center">
          <Loader />
          <div className="mb-2 mt-8 animate-pulse flex items-center">
            <img
              className="w-8 mr-1 object-contain animate-pulse"
              src="/logo192.png"
              alt="SoloStar Logo"
            />
            <span className="text-xl font-medium">SoloStar</span>
          </div>
          <p className="p-8 text-center">
            SoloStar AI is thinking 🤔...we're looking up the history of{" "}
            <b>${compSymbol}</b> stock that you can compare it to your{" "}
            <b>${baseSymbol}</b> stock prices.
          </p>
        </div>
      </div>
    );
  }
  if (!comp || !company || !base) {
    return (
      <EmptyStateGoBack
        id={companyId}
        title="Stock Comparison Not Found"
        description="Sorry, we couldn't find any feeders for this Company."
      />
    );
  }

  return (
    <div className="">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
      <div className="p-8">
        <GoBackButton id={companyId} />
        <p className="">
          Here's a comparison of the performance of <b>${compSymbol}</b> stock
          versus <b>${baseSymbol}</b> since{" "}
          {base1YearAgo?.label?.toLowerCase() ?? "a year ago"}.
        </p>

        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              ></th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                <div className="flex items-center">
                  <div className="w-6 h-6 bg-gray-200 mr-1 overflow-hidden">
                    <img
                      className="bg-cover"
                      alt="company logo"
                      src={`https://eodhd.com/img/logos/US/${baseSymbol}.png`}
                    />
                  </div>
                  <span>${baseSymbol}</span>
                </div>
              </th>
              <th
                scope="col"
                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                <div className="flex items-center">
                  <div className="w-6 h-6 bg-gray-200 mr-1 overflow-hidden">
                    <img
                      className="bg-cover"
                      alt="company logo"
                      src={`https://eodhd.com/img/logos/US/${compSymbol}.png`}
                    />
                  </div>
                  <span>${compSymbol}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {base.map((row, idx) => {
              return (
                <tr key={row.id}>
                  <td className="whitespace-nowrap py-2 px-2 text-xs text-gray-500 sm:pl-0">
                    {row.label}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    ${row.price}
                    <br />
                    {getFormattedDiff(row.price, base1YearAgo?.price ?? 0)}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                    ${comp?.[idx]?.price}
                    <br />
                    {getFormattedDiff(
                      comp?.[idx]?.price ?? 0,
                      comp1YearAgo?.price ?? 0
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div
        className="fixed bottom-0 w-full"
        style={{ width: "110%", marginLeft: "-5%" }}
      >
        <FakeStockChart />
      </div>
    </div>
  );
};

const CompanyStockComparison: React.FC = () => {
  const { companyId } = useParams<{ companyId?: string }>();
  return <StockComparison companyId={companyId} />;
};

export default CompanyStockComparison;
