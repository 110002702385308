import { gql, useQuery, QueryHookOptions } from "@apollo/client";
import { CANDIDATE_FRAGMENT } from "fragments/candidate";

import {
  GetCandidateSummary,
  GetCandidateSummaryVariables,
} from "../__generated__/GetCandidateSummary";

import {
  GetCandidate,
  GetCandidateVariables,
} from "../__generated__/GetCandidate";

import {
  GetCandidateInterestingNotes,
  GetCandidateInterestingNotesVariables,
} from "../__generated__/GetCandidateInterestingNotes";

export const GET_CANDIDATE_SUMMARY = gql`
  query GetCandidateSummary($id: String!) {
    getCandidateSummary(id: $id) {
      description
      accomplishments {
        title
        whyImportant
        reasons
      }
    }
  }
`;

export const GET_CANDIDATE_INTERESTING_NOTES = gql`
  query GetCandidateInterestingNotes($id: String!) {
    getCandidateInterestingNotes(id: $id) {
      description
      interestingNotes {
        title
        whyImportant
        reasons
      }
    }
  }
`;

export const GET_CANDIDATE = gql`
  ${CANDIDATE_FRAGMENT}
  query GetCandidate($id: String!) {
    getCandidate(id: $id) {
      ...CandidateFragment
    }
  }
`;

export const useCandidateSummary = (
  variables: GetCandidateSummaryVariables,
  options: QueryHookOptions<GetCandidateSummary, GetCandidateSummaryVariables>
) => {
  const res = useQuery<GetCandidateSummary, GetCandidateSummaryVariables>(
    GET_CANDIDATE_SUMMARY,
    {
      variables,
      ...options,
    }
  );
  return { ...res, data: res.data?.getCandidateSummary };
};

export const useCandidateInterestingNotes = (
  variables: GetCandidateInterestingNotesVariables,
  options: QueryHookOptions<
    GetCandidateInterestingNotes,
    GetCandidateInterestingNotesVariables
  >
) => {
  const res = useQuery<
    GetCandidateInterestingNotes,
    GetCandidateInterestingNotesVariables
  >(GET_CANDIDATE_INTERESTING_NOTES, {
    variables,
    ...options,
  });
  return { ...res, data: res.data?.getCandidateInterestingNotes };
};

export const useCandidate = (
  variables: GetCandidateVariables,
  options: QueryHookOptions<GetCandidate, GetCandidateVariables>
) => {
  const res = useQuery<GetCandidate, GetCandidateVariables>(GET_CANDIDATE, {
    variables,
    ...options,
  });
  return { ...res, data: res.data?.getCandidate };
};
