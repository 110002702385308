import { gql, useQuery, QueryHookOptions } from "@apollo/client";
import { INSTITUTION_FRAGMENT } from "fragments/candidate";

import {
  GetInstitution,
  GetInstitutionVariables,
} from "../__generated__/GetInstitution";

import {
  GetInstitutionSummary,
  GetInstitutionSummaryVariables,
} from "../__generated__/GetInstitutionSummary";

import {
  GetSimilarInstitutions,
  GetSimilarInstitutionsVariables,
} from "../__generated__/GetSimilarInstitutions";

export const GET_INSTITUTION = gql`
  ${INSTITUTION_FRAGMENT}
  query GetInstitution($id: String!) {
    getInstitution(id: $id) {
      ...InstitutionFragment
    }
  }
`;

export const GET_INSTITUTION_SUMMARY = gql`
  query GetInstitutionSummary($id: String!) {
    getInstitutionSummary(id: $id) {
      description
      programs {
        title
        whyImportant
      }
      researchLabs {
        title
        whyImportant
        url
      }
    }
  }
`;

export const GET_SIMILAR_INSTITUTIONS = gql`
  query GetSimilarInstitutions($id: String!) {
    getInstitutionsSimilar(id: $id) {
      id
      displayName
      country
      url
    }
  }
`;

export const useInstitution = (
  variables: GetInstitutionVariables,
  options?: QueryHookOptions<GetInstitution, GetInstitutionVariables>
) => {
  const res = useQuery<GetInstitution, GetInstitutionVariables>(
    GET_INSTITUTION,
    { variables, ...options }
  );
  return { ...res, data: res.data?.getInstitution };
};

export const useInstitutionSummary = (
  variables: GetInstitutionSummaryVariables,
  options?: QueryHookOptions<
    GetInstitutionSummary,
    GetInstitutionSummaryVariables
  >
) => {
  const res = useQuery<GetInstitutionSummary, GetInstitutionSummaryVariables>(
    GET_INSTITUTION_SUMMARY,
    { variables, ...options }
  );
  return { ...res, data: res.data?.getInstitutionSummary };
};

export const useSimilarInstitutions = (
  variables: GetSimilarInstitutionsVariables,
  options?: QueryHookOptions<
    GetSimilarInstitutions,
    GetSimilarInstitutionsVariables
  >
) => {
  const res = useQuery<GetSimilarInstitutions, GetSimilarInstitutionsVariables>(
    GET_SIMILAR_INSTITUTIONS,
    { variables, ...options }
  );
  return { ...res, data: res.data?.getInstitutionsSimilar };
};
