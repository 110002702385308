import { gql, useMutation } from "@apollo/client";
import {
  UpdatePerson,
  UpdatePersonVariables,
} from "../__generated__/UpdatePerson";
import { PERSON_FRAGMENT } from "../fragments/person";

export const UPDATE_PERSON = gql`
  ${PERSON_FRAGMENT}
  mutation UpdatePerson($input: UpdatePersonInput!) {
    updatePerson(input: $input) {
      ...PersonFragment
    }
  }
`;

export const useUpdatePerson = (variables?: UpdatePersonVariables) =>
  useMutation<UpdatePerson, UpdatePersonVariables>(UPDATE_PERSON, {
    variables,
    refetchQueries: ["GetPersons"],
    awaitRefetchQueries: true,
  });
